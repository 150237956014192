import axios from "axios";

const state = {
    equipos: null,
    mensaje: ""
};

const getters = {
    equipos: (state) => state.equipos,
};

const mutations = {
    setEquipos: (state, equipos) => {
        state.equipos = equipos;
    },
    setMensaje: (state, mensaje) => {
        state.mensaje = mensaje;
    },
};

const actions = {
    async consultar({ commit }) {
        try {
            let res = await axios.get(`/v1/actualizacion-equipo`);
            commit("setEquipos", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async cargarArchivo({commit}, data) {
        try {
            const headers = { 'Content-Type': 'multipart/form-data' };
            await axios.post(`/v1/actualizacion-equipo/upload`, data, { headers });
            commit("setMensaje", "Se ha cargado con éxito");
            return "success"
        } catch (error) {
            return "error";
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
