<template>
  <div
    class="modal fade"
    id="modalAprobarEvento"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAprobarEventoLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAprobarEventoLabel">
            <strong>{{ titulo }}</strong>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="method"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form
          @submit="submit"
          :validation-schema="formValidation"
          v-slot="{ errors }"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="fechaEntrega">Fecha entrega logística *</label>
                  <fieldset>
                    <Field
                      type="date"
                      name="fechaEntrega"
                      class="form-control"
                      placeholder="Fecha entrega logística"
                      v-model.trim="fechaEntrega"
                    />
                    <MensajeValidacion :mensaje="errors.fechaEntrega" />
                  </fieldset>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="horaEntrega">Hora entrega *</label>
                  <fieldset>
                    <Field
                      type="time"
                      name="horaEntrega"
                      class="form-control"
                      placeholder="Hora de entrega"
                      v-model.trim="horaEntrega"
                      required
                      id="horaEntrega"
                    />
                    <MensajeValidacion :mensaje="errors.horaEntrega" />
                  </fieldset>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="fechaRetiro">Fecha retiro logística *</label>
                  <fieldset>
                    <Field
                      type="date"
                      name="fechaRetiro"
                      class="form-control"
                      placeholder="Fecha retiro logística"
                      v-model.trim="fechaRetiro"
                    />
                    <MensajeValidacion :mensaje="errors.fechaRetiro" />
                  </fieldset>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="horaRetiro">Hora retiro *</label>
                  <fieldset>
                    <Field
                      type="time"
                      name="horaRetiro"
                      class="form-control"
                      placeholder="Hora de retiro"
                      v-model.trim="horaRetiro"
                      required
                      id="horaRetiro"
                    />
                    <MensajeValidacion :mensaje="errors.horaRetiro" />
                  </fieldset>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label>Proveedor *</label>
                  <fieldset>
                    <Field
                      as="select"
                      name="proveedor"
                      class="form-control"
                      placeholder="Proveedor"
                      v-model="proveedor"
                    >
                      <option value="" disabled>Seleccione un proveedor</option>
                      <option
                        v-for="item in proveedores"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.nombreProveedor }} - {{ item.direccion }}
                      </option>
                    </Field>
                    <MensajeValidacion :mensaje="errors.proveedor" />
                  </fieldset>
                </div>
              </div>
            </div>
            <ErrorMessage :mensaje="mensaje" v-if="error" />
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Aprobar</button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="btCerrar"
            >
              Cerrar
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage.vue";
export default {
  props: {
    cerrar: { type: Function },
    asignar: { type: Function },
    titulo: String,
  },
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      fechaEntrega: "required",
      fechaRetiro: "required",
      horaEntrega: "required",
      horaRetiro: "required",
      proveedor: "required",
    };
    return {
      fechaEntrega: "",
      fechaRetiro: "",
      horaEntrega: "",
      horaRetiro: "",
      proveedor: "",
      formValidation,
      error: false,
    };
  },
  mounted() {
    if (this.eventoSeleccionado) {
      this.fechaEntrega = this.eventoSeleccionado?.fechaEntrega;
      this.fechaRetiro = this.eventoSeleccionado?.fechaRetiro;
      this.horaEntrega = this.eventoSeleccionado?.horaEntrega;
      this.horaRetiro = this.eventoSeleccionado?.horaRetiro;
    }
    this.consultarProveedores();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarProveedores() {
      try {
        await this.$store.dispatch("proveedores/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta de proveedores");
      }
    },
    submit() {
      document.getElementById("btCerrar").click();
      this.asignar({
        idEvento: this.idEventoSeleccionado,
        fechas: {
          fechaEntrega: this.fechaEntrega,
          fechaRetiro: this.fechaRetiro,
          horaEntrega: this.horaEntrega,
          horaRetiro: this.horaRetiro,
          proveedor: this.proveedor
        }        
      });
    },
  },
  computed: {
    idEventoSeleccionado() {
      return this.$store.getters["eventos/eventoSeleccionado"]
        ? this.$store.getters["eventos/eventoSeleccionado"]?.id
        : null;
    },
    eventoSeleccionado() {
      return this.$store.getters["eventos/eventoSeleccionado"]
        ? this.$store.getters["eventos/eventoSeleccionado"]
        : null;
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
        ? this.$store.getters["proveedores/proveedores"]
        : [];
    }
  },
  watch: {
    idEventoSeleccionado(prevState, newState) {
      if (prevState !== newState) {
        this.fechaEntrega = this.eventoSeleccionado?.fechaEntrega;
        this.fechaRetiro = this.eventoSeleccionado?.fechaRetiro;
        this.horaEntrega = this.eventoSeleccionado?.horaEntrega;
        this.horaRetiro = this.eventoSeleccionado?.horaRetiro;
      }
    },
  },
};
</script>
