<template>
  <GestionEquiposProveedor />
</template>
<script>
import GestionEquiposProveedor from "./components/GestionEquiposProveedor.vue";
export default {
  name: "EquiposProveedor",
  components: { GestionEquiposProveedor }
};
</script>
