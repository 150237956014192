<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Bodegas y Gerencias</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-0">
                <label>Bodega *</label>
                <fieldset>
                  <select
                      name="bodega"
                      class="form-control mb-2 mb-md-0"
                      v-model="bodega"
                      @change="consultarGerenciasXBodega"
                  >
                    <option value="" disabled>Seleccione una bodega</option>
                    <option
                        v-for="bodega in bodegas"
                        :key="bodega.id"
                        :value="bodega.id"
                    >
                      {{ bodega.nombre }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
            <div class="col-md-6">
              <button @click="agregar" class="btn btn-primary btn-general float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%"></th>
                <th>Gerencia General</th>
                <th>Gerencia Ventas</th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="item in bodegaGerencias" :key="item.id">
                <td>
                  <a
                      href="javascript:void(0)"
                      @click="editar(item)"
                  ><i class="ti-pencil btn-icon-append"></i></a
                  >&nbsp;
                  <a
                      href="javascript:void(0)"
                      @click="eliminar(item.id)"
                  ><i class="ti-trash btn-icon-append"></i
                  ></a>
                </td>
                <td>{{ item.gerenciaGeneral }}</td>
                <td>{{ item.gerenciaVentas }}</td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "GestionBodegaGerencia",
  data() {
    return {
      bodega: ""
    };
  },
  mounted() {
    this.bodega = this.bodegaSeleccionada?.id || "";
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarBodegas() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      } finally {
        this.loader.hide();
      }
    },
    async consultarGerenciasXBodega() {
      this.loaderSpinner();
      try {
        if (this.bodega) {
          await this.$store.dispatch("bodegaGerencia/consultarGerenciaXBodega", {idBodega: this.bodega});
          const bodegaSeleccionada = this.bodegas.find(item => item.id === this.bodega);
          await this.$store.commit("bodegas/setBodegaSeleccionada", bodegaSeleccionada);
        }

      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("bodegaGerencia/setBodegaGerenciaSeleccionada", null);
      this.$router.push({name: "BodegaGerenciaForm"});
    },
    async editar(bodega) {
      await this.$store.commit("bodegaGerencia/setBodegaGerenciaSeleccionada", bodega);
      this.$router.push({name: "BodegaGerenciaForm"});
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("bodegaGerencia/eliminar", {id: id});
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
            this.consultarGerenciasXBodega();
          }
        }
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
    bodegaGerencias() {
      return this.$store.getters["bodegaGerencia/bodegasGerencias"]
          ? this.$store.getters["bodegaGerencia/bodegasGerencias"]
          : [];
    },
    bodegaSeleccionada() {
      return this.$store.getters["bodegas/bodegaSeleccionada"]
          ? this.$store.getters["bodegas/bodegaSeleccionada"]
          : null;
    },
  },
};
</script>
<style></style>
