<template>
  <div
    class="modal fade"
    id="modalAtenderReparacion"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAtenderReparacionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAgregarActividadLabel">
            <strong>Atender Reparación</strong>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Observación</label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="observacion"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-general" data-dismiss="modal" @click="finalizar({observacion})" :disabled="!observacion">
            Finalizar Reparación
          </button>
          <button type="button" class="btn btn-secondary btn-general-off" data-dismiss="modal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AtenderReparacion",
  props: {
    finalizar: { type: Function },
  },
  data() {
    return { observacion: "" };
  },
  computed: {
    reparacionSeleccionada() {
      return this.$store.getters["reparaciones/reparacionSeleccionada"]
        ? this.$store.getters["reparaciones/reparacionSeleccionada"]
        : [];
    }
  },
  watch:{
    reparacionSeleccionada(newReparacion,oldReparacion){
      if(newReparacion!==oldReparacion){
        this.observacion = "";
      }
    }
  }
};
</script>
