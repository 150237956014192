import axios from "axios";

const state = {
    filtros: [],
    filtroSeleccionado: null
};

const getters = {
    filtros: (state) => state.filtros,
    filtroSeleccionado: (state) => state.filtroSeleccionado
};

const mutations = {
    setFiltros: (state, filtros) => {
        state.filtros = filtros;
    },
    setFiltroSeleccionado: (state, filtroSeleccionado) => {
        state.filtroSeleccionado = filtroSeleccionado;
    }
};

const actions = {

    async consultarPorEntidad({ commit }, data) {
        try {
            let res = await axios.get(`/v1/filtros/${data?.entidad}/entidad`);
            commit("setMenuByRole", res?.data?.result);
        } catch (error) {
            commit("setMenuByRole", []);
            return error;
        }
    },
    async crear(data) {
        try {
            let res = await axios.post(`/v1/filtros`, data);
            return res.data
        } catch (error) {
            return error;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
