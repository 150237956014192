<template>
  <div class="col-md-12 col-sm-12 stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Análisis por solicitud</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>

        <div name="f1" id="formElement">          
          <div class="white-content-general mb-4">
            <div class="col-md-6">
              <div class="form-check mt-0">
                <input
                  class="form-check-input"
                  type="radio"
                  name="tipoBusqueda"
                  id="exampleRadios1"
                  value="SOLICITUD"
                  v-model="tipoBusqueda"
                />
                <label class="form-check-label" for="exampleRadios1">
                  Buscar por solicitud
                </label>
              </div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="valorBusqueda"
                  aria-label="Text input with segmented dropdown button"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-general btn-buscar"
                    @click="buscar"
                  >
                  <span class="icon-search"></span> Buscar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="white-content-table">
            <div class="row">
              <div class="card col-md-6">
                <div class="card-body">
                  <h5 class="card-title txt-celeste">Datos solicitud</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Id: {{ analisis?.solicitud?.id }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Ticket: {{ analisis?.solicitud?.numeroTicket }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Cliente: {{ analisis?.solicitud?.codigoCliente }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Tipo: {{ analisis?.solicitud?.tipoSolicitud }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Fecha: {{ analisis?.solicitud?.fechaSolicitud }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Días transcurridos:
                    {{ analisis?.solicitud?.diasTranscurridosLabolables }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Nivel de riesgo: {{ analisis?.solicitud?.prioridad }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Gerencia general: {{ analisis?.solicitud?.gerenciaGeneral }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Gerencia ventas: {{ analisis?.solicitud?.gerenciaVentas }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    ECB: {{ analisis?.solicitud?.ecb }}
                  </h6>
                </div>
              </div>
              <div class="card col-md-6">
                <div class="card-body">
                  <h5 class="card-title txt-celeste">Datos análisis</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Capacidad mediana: {{ analisis?.capacidadEffMediana }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Capacidad promedio: {{ analisis?.capacidadEffPromedio }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Crecimiento: {{ analisis?.screcimiento }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Mediana últimos seis meses:
                    {{ analisis?.medianaUltimosTresMeses }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Nivel de riesgo: {{ analisis?.prioridad }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Promedio últimos tres meses:
                    {{ analisis?.promedioUltimosTresMeses }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Compra últimos meses: {{ analisis?.ultimosMesesCompra }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Compra ácida últimos meses:
                    {{ analisis?.ultimosMesesCompraAcida }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Cantidad equipos:
                    {{ analisis?.cantidadEquipos }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Días vencidos:
                    {{ analisis?.diaVencido }}
                  </h6>
                  <h6 class="card-subtitle mb-2 text-muted">
                    Monto vencido:
                    {{ analisis?.montoVencido }}
                  </h6>
                </div>
              </div>
            </div>
          </div>

        </div>
        <ModalMessages :mensaje="mensaje" titulo="Análisis" />
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
export default {
  name: "GestionAnalisis",
  components: { ModalMessages },
  data() {
    return {
      archivo: null,
      valorBusqueda: "",
      observacion: "",
      tipoBusqueda: "",
      mensaje: [],
    };
  },
  created() {
    this.$store.commit("analisis/setAnalisis", []);
  },
  mounted() {
    this.tipoBusqueda = "SOLICITUD";
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async buscar() {
      try {
        this.loaderSpinner();
        if (this.tipoBusqueda && this.tipoBusqueda === "SOLICITUD") {
          const resp = await this.$store.dispatch(
            "analisis/consultarXSolicitud",
            {
              idSolicitud: this.valorBusqueda,
            }
          );
          if (!resp) {
            this.mensaje = ["No se recuperó información con ese número de solicitud"];
            document.getElementById("modalButton").click();
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    analisis() {
      return this.$store.getters["analisis/analisis"]
        ? this.$store.getters["analisis/analisis"]
        : {};
    },
  },
};
</script>
<style></style>
