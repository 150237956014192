import axios from "axios";

const state = {
  objetivos: null,
  mensaje: ""
};

const getters = {
  objetivos: (state) => state.objetivos,
};

const mutations = {
  setObjetivos: (state, objetivos) => {
    state.objetivos = objetivos;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  inicializar: (state) => {
    (state.objetivos = null);
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/base-objetivos-visita-teo`);
      commit("setObjetivos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
