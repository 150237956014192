<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Gestión de Archivos Cargados
            </h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0">
              
              </p>
            </div>
            <div class="col-md-8">
              <button class="btn btn-primary btn-general float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>
        
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>Nombre Archivo</th>
                  <th>Fecha</th>
                  <th>Hora</th>
                  <th>Usuario</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in archivos" :key="item.id">
                  <td>
                    <a href="javascript:void(0)" @click="eliminar(item)"
                      ><i class="ti-trash"></i
                    ></a>
                  </td>
                  <td>{{ item.id }}</td>
                  <td>{{ item.nombreArchivo }}</td>
                  <td>{{ item.fecha }}</td>
                  <td>{{ item.hora }}</td>
                  <td>{{ item.usuario }}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <div class="d-flex justify-content-center">
              <nav aria-label="Page navigation example" v-if="paginas">
                <ul class="pagination">
                  <li class="page-item" :disabled="pagina === 1">
                    <a class="page-link" @click="setPreviewPage">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li
                    v-for="page in paginas"
                    :key="page"
                    class="page-item"
                    :class="{ active: pagina === page }"
                  >
                    <a class="page-link" @click="setPage(page)">{{ page }}</a>
                  </li>
                  <li class="page-item" :disabled="pagina === paginas">
                    <a class="page-link" @click="setNextPage">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Modal
          mensaje="Se eliminará el archivo, ¿Está seguro?"
          :method="procesarEliminar"
        />
        <ModalMessages :mensaje="mensajeProceso" titulo="Eliminar archivo" />
        <button
          type="button"
          v-show="false"
          id="exampleModalButton"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          data-backdrop="static"
          data-keyboard="false"
        >
          Modal messages
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "../../../components/Modal.vue";
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "GestionArchivosSolicitudes",
  components: { Modal, ModalMessages },
  data() {
    return {
      pagina: 1,
      mensaje: "",
      id: null,
      valorBusqueda: "",
      tipoFiltro: "",
      mensajeProceso: []
    };
  },
  mounted() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      this.loaderSpinner();
      this.valorBusqueda = "";
      try {
        const data = {
          pagina: pagina || 1,
        };
        await this.$store.dispatch(
          "archivosSolicitudes/consultarSolicitudesXPagina",
          data
        );
        await this.$store.dispatch("archivosSolicitudes/consultarTotalPaginas");
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
    eliminar(archivo) {
      this.$store.commit("archivosSolicitudes/setArchivoSeleccionado", archivo)
      document.getElementById("exampleModalButton").click();
    },
    async procesarEliminar() {
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("archivosSolicitudes/eliminarArchivo", {idArchivo: this.archivoSeleccionado.id});
        this.mensajeProceso =[resp];
        document.getElementById("modalButton").click();
      } catch (error) {
        alert("No se pudo eliminar");
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    archivos() {
      return this.$store.getters["archivosSolicitudes/archivos"]
        ? this.$store.getters["archivosSolicitudes/archivos"]
        : [];
    },
    archivoSeleccionado() {
      return this.$store.getters["archivosSolicitudes/archivoSeleccionado"]
        ? this.$store.getters["archivosSolicitudes/archivoSeleccionado"]
        : [];
    },
    totalPaginas() {
      return this.$store.getters["archivosSolicitudes/totalPaginas"]
        ? this.$store.getters["archivosSolicitudes/totalPaginas"]
        : [];
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
