import axios from "axios";

const state = {
  solicitudes: null,
  mensaje: null,
  minimo: 0,
  maximo: 0,
  totalPaginas: null,
  totalRegistros: null,
};

const getters = {
  solicitudes: (state) => state.solicitudes,
  mensaje: (state) => state.mensaje,
  minimo: (state) => state.minimo,
  maximo: (state) => state.maximo,
  totalPaginas: (state) => state.totalPaginas,
  totalRegistros: (state) => state.totalRegistros,
};

const mutations = {
  setSolicitudes: (state, solicitudes) => {
    state.solicitudes = solicitudes;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  setMinimo: (state, minimo) => {
    state.minimo = minimo;
  },
  setMaximo: (state, maximo) => {
    state.maximo = maximo;
  },
  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
  setTotalRegistros: (state, data) => {
    state.totalRegistros = data;
  },
  inicializar: (state) => {
    state.solicitudes = null,
    state.mensaje = null,
    state.totalPaginas = null,
    state.totalRegistros = null
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/solicitudes`);
      commit("setSolicitudes", res?.data?.result?.solicitudes);
    } catch (error) {
      return error;
    }
  },
  async consultarXPagina({ commit }, data) {
    try {
      let res = await axios.get(`/v1/solicitudes/${data.pagina}/pagina`);
      commit("setSolicitudes", res?.data?.result?.solicitudes);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginas({ commit }) {
    try {
      const resp = await axios.get(`/v1/solicitudes/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      commit("setTotalRegistros", resp?.data?.totalRegistros);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async cargarArchivo({commit}, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/solicitudes/upload`, data, { headers });
      commit("setMensaje", "Se ha cargado con éxito el archivo");
      return "success"
    } catch (error) {
      return "error";
    }
  },
  async filtrar({ commit }, data) {
    try {
      const res = await axios.post(`v2/solicitudes/filtros`, data);
      commit("setSolicitudes", res?.data?.result?.solicitudes);
      commit("setMinimo", res?.data?.result?.mediana?.minimo);
      commit("setMaximo", res?.data?.result?.mediana?.maximo);
    } catch (error) {
      return "No se pudo filtrar los requerimientos";
    }
  },
  async procesar({ commit }, data) {
    try {
      const solicitudes = await axios.post(`v1/solicitudes/asignacion`, data);
      const res = await axios.get(`/v1/solicitudes`);
      commit("setSolicitudes", res?.data?.result?.solicitudes);
      return solicitudes?.data || "";
    } catch (error) {
      return "No se pudo procesar la solicitud";
    }
  },
  async cerrar({ commit }, data) {
    try {
      await axios.post(`v1/solicitudes/cerrar`, data);
      commit("setMensaje", "");
    } catch (error) {
      return "No se pudo procesar concluir con el proceso";
    }
  },


  async procesarRechazadoManual({ commit }, data) {
    try {
      const solicitudes = await axios.post(`v1/solicitudes/cerrar`, data);
      const res = await axios.get(`/v1/solicitudes`);
      commit("setSolicitudes", res?.data?.result?.solicitudes);
      return solicitudes?.data || "";
    } catch (error) {
      return "No se pudo procesar la solicitud";
    }
  },

  async asignarIndividual({ commit }, data) {
    try {
      const asignacion = await axios.get('v1/solicitudes/'+data.id+'/asignacion');
      const res = await axios.get(`/v1/solicitudes`);
      commit("setSolicitudes", res?.data?.result);
      return asignacion?.data || "";
    } catch (error) {
      return "No se pudo procesar la solicitud";
    }
  },
  async crearSolicitud({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/solicitudes/crear`, data, { headers });
      commit("setMensaje", "Se creó nueva solicitud con éxito");
    } catch (error) {
      return error;
    }
  },
  async consultarSolicitudesXFiltros({ commit }, data) {
    try {
      let resp = await axios.post(`/v1/solicitudes-proveedor/filtros`, data);
      commit("setSolicitudes", resp?.data?.result?.solicitudesProveedor);
      commit("setTotalPaginas", resp?.data?.result?.paginacion?.totalPaginas || 1);
      commit("setTotalRegistros", resp?.data?.result?.paginacion?.totalRegistros || 0);
    } catch (error) {
      return error;
    }
  },
  async exportar({ commit }) {
    try {
      let resp = await axios.get(`/v1/exportar/requerimientos`,{ responseType: "arraybuffer" });
      if(resp?.data){
        commit("setMensaje", "Descarga exitosa");
        return resp?.data
      }else{
        commit("setMensaje", "Descarga fallida");
        return null;
      }
    } catch (error) {
      return error;
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
