<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Equipos Proveedor</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              
              <div class="container overflow-auto">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="placa"
                        value="PLACA"
                        v-model="tipoFiltro"
                        @click="mostrarBusquedaInput"
                      />
                      <label class="form-check-label radio-margin" for="placa">
                        Placa
                      </label>

                      <input
                        class="form-check-input"
                        type="radio"
                        id="serie"
                        value="SERIE"
                        v-model="tipoFiltro"
                        @click="mostrarBusquedaInput"
                      />
                      <label class="form-check-label radio-margin" for="serie">
                        Serie
                      </label>
                      <div class="form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="bodegas"
                          value="BODEGA"
                          v-model="tipoFiltro"
                          @click="mostrarBodegas"
                        />
                        <label class="form-check-label radio-margin" for="bodegas">
                          Bodega
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="estados"
                          value="ESTADO"
                          v-model="tipoFiltro"
                          @click="mostrarEstados"
                        />
                        <label class="form-check-label radio-margin" for="estados">
                          Estado
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="todos"
                          value="TODOS"
                          v-model="tipoFiltro"
                          @click="consultarTodos()"
                        />
                        <label class="form-check-label radio-margin" for="todos">
                          Todos
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="input-group col-md-12 mt-2 no-padding mb-2 mb-md-0"
                v-if="habilitarInputBusqueda"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="valorBusqueda"
                  aria-label="Text input with segmented dropdown button"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-general"
                    @click="buscarEquipo"
                    :disabled="valorBusqueda.length === 0 || tipoFiltro === 'TODOS'"
                  >
                  <span class="icon-search"></span> Buscar
                  </button>
                </div>
              </div>
              <div class="row mt-2" v-if="habilitarBodegas">
                <div class="col-md-12">
                  <div class="form-group mb-2 mb-md-0">
                    <fieldset>
                      <select
                        name="bodega"
                        class="form-control"
                        v-model="bodega"
                        @change="buscarEquipo"
                      >
                        <option value="" disabled>Seleccione una bodega</option>
                        <option
                          v-for="bodega in bodegas"
                          :key="bodega.id"
                          :value="bodega.id"
                        >
                          {{ bodega.nombre }}
                        </option>
                      </select>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div class="row mt-2" v-if="habilitarEstados">
                <div class="col-md-12">
                  <div class="form-group mb-2 mb-md-0">
                    <fieldset>
                      <select
                        name="estado"
                        class="form-control"
                        v-model="estadoEquipo"
                        @change="buscarEquipo"
                      >
                        <option value="" disabled>Seleccione un estado</option>
                        <option
                          v-for="estado in estados"
                          :key="estado"
                          :value="estado"
                        >
                          {{ estado }}
                        </option>
                      </select>
                    </fieldset>
                  </div>
                </div>
              </div>


            </div>
            <div class="col-md-6">
              <button @click="exportExcel" class="btn btn-primary btn-general float-right">
                <span class="icon-download"></span> Exportar a excel
              </button>
            </div>
          </div>
        </div>

        
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped mb-4">
              <thead>
                <tr>
                  <th></th>
                  <th>Ubicación</th>
                  <th></th>
                  <th>Taller</th>
                  <th>Placa</th>
                  <th>Serie</th>
                  <th>Marca</th>
                  <th>Modelo</th>
                  <th>Logo</th>
                  <th>Año fabricación</th>
                  <th>Tipo</th>
                  <th>Fabricante Genesis</th>
                  <th>Status Neveras</th>
                  <th>Valor Libros</th>
                  <th>Valor Comercial</th>
                  <th>Status Libros</th>
                  <th>Año Adquisición</th>
                  <th>Capacidad Botellas</th>
                  <th>Capacidad Cajas</th>
                  <th>Capacidad Pies</th>
                  <th>Activo Fijo</th>
                  <th>Número Equipo</th>
                  <th>VH</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in equiposFiltrados" :key="item.id">
                  <td>
                    <a href="javascript:void(0)" @click="editar(item)"
                      ><i class="ti-pencil"></i
                    ></a>
                  </td>
                  <td>{{ item.ubicacion }}</td>
                  <td class="">
                    <div
                      class="badge"
                      :class="
                        item.estadoEquipo === 'DISPONIBLE'
                          ? 'badge-success'
                          : 'badge-secondary'
                      "
                    >
                      {{ item.estadoEquipo }}
                    </div>
                  </td>
                  <td>{{ item.bodegaDescripcion }}</td>
                  <td style="font-weight: bold">
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Visualizar detalles del equipo"
                      href="#"
                      >{{ item.placa }}</a
                    >
                  </td>
                  <td>{{ item.serie }}</td>
                  <td>{{ item.marca }}</td>
                  <td>{{ item.modelo }}</td>
                  <td>{{ item.logo }}</td>
                  <td>{{ item.anioFabricacion }}</td>
                  <td>{{ item.tipoEquipo }}</td>
                  <td>{{ item.fabricanteGenesis }}</td>
                  <td>{{ item.statusNeveras }}</td>
                  <td>{{ item.valorLibros }}</td>
                  <td>{{ item.valorComercial }}</td>
                  <td>{{ item.statusLibros }}</td>
                  <td>{{ item.anioAdquisicion }}</td>
                  <td>{{ item.capacidadBotellas }}</td>
                  <td>{{ item.capacidadCajas }}</td>
                  <td>{{ item.capacidadPies }}</td>
                  <td>{{ item.activoFijo }}</td>
                  <td>{{ item.numeroEquipo }}</td>
                  <td>{{ item.vh }}</td>
                </tr>
              </tbody>
            </table>
            
            <paginacion :updatePage="updatePage"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Paginacion from '../../../components/Paginacion.vue';

export default {
  name: "GestionEquiposProveedor",
  components: {Paginacion},
  data() {
    return {
      valorBusqueda: "",
      tipoFiltro: "",
      equiposFiltrados: [],
      habilitarInputBusqueda: false,
      habilitarBodegas: false,
      bodega: "",
      habilitarEstados: false,
      estadoEquipo: "",
      estados: [
        "DISPONIBLE",
        "REPARACION",
        "ENCI",
        "BAJA",
        "RESERVADO",
        "GARANTIA",
      ],
      equiposOriginal: [],
      pagina: 1,
    };
  },
  mounted() {
    this.consultar(1);
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      this.habilitarBodegas = false;
      this.habilitarInputBusqueda = false;
      this.habilitarEstados = false;
      this.valorBusqueda = "";
      this.tipoFiltro = "";
      try {
        this.loaderSpinner();
        await this.$store.dispatch(
          "equiposProveedor/consultarEquiposProveedorPaginado",
          data
        );
        await this.$store.dispatch(
          "equiposProveedor/consultarEquiposProveedorTotalPaginas"
        );
        this.pagina = pagina;
        this.equiposFiltrados = this.$store.getters["equiposProveedor/equiposProveedor"];
        this.equiposOriginal = this.$store.getters["equiposProveedor/equiposProveedor"];
        //const resp = await this.$store.dispatch("equiposProveedor/consultar");
        //this.equiposFiltrados = resp;
        //this.equiposOriginal = resp;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async consultarBodegas() {
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      }
    },
    async editar(equipo) {
      await this.$store.commit("equipos/setEquipoSeleccionado", equipo);
      this.$router.push({ name: "EditEquipoProveedor" });
    },
    exportExcel() {
      saveExcel({
        data: this.equipos,
        fileName: "data",
        columns: [
          { field: "ubicacion", title: "Ubicación" },
          { field: "bodegaDescripcion", title: "Taller" },
          { field: "placa", title: "Placa" },
          { field: "serie", title: "Serie" },
          { field: "marca", title: "Marca" },
          { field: "modelo", title: "Modelo" },
          { field: "logo", title: "Logo" },
          { field: "anioFabricacion", title: "Año fabricación" },
          { field: "tipoEquipo", title: "Tipo" },
          { field: "fabricanteGenesis", title: "Fabricante Génesis" },
          { field: "statusNeveras", title: "Status Neveras" },
          { field: "valorLibros", title: "Valor Libros" },
          { field: "valorComercial", title: "Valor Comercial" },
          { field: "statusLibros", title: "Status Libros" },
          { field: "anioAdquisicion", title: "Año Adquisición" },
          { field: "capacidadBotellas", title: "Capacidad Botellas" },
          { field: "capacidadCajas", title: "Capacidad Cajas" },
          { field: "capacidadPies", title: "Capacidad Pies" },
          { field: "activoFijo", title: "Activo Fijo" },
          { field: "numeroEquipo", title: "Número Equipo" },
          { field: "vh", title: "VH" },
        ],
      });
    },
    async buscarEquipo() {
      this.loaderSpinner();
      this.equiposFiltrados = this.equiposOriginal;
      try {
        if (this.tipoFiltro && this.tipoFiltro === "SERIE") {
          const equiposXSerie = this.equiposFiltrados?.filter(
            (item) => item?.serie === this.valorBusqueda
          );
          this.equiposFiltrados = equiposXSerie;
        }
        if (this.tipoFiltro && this.tipoFiltro === "PLACA") {
          const equiposXPlaca = this.equiposFiltrados?.filter(
            (item) => item?.placa === this.valorBusqueda
          );
          this.equiposFiltrados = equiposXPlaca;
        }
        if (this.tipoFiltro && this.tipoFiltro === "BODEGA") {
          const equiposXBodega = this.equiposFiltrados?.filter(
            (item) => item?.bodega === this.bodega
          );
          this.equiposFiltrados = equiposXBodega;
        }
        if (this.tipoFiltro && this.tipoFiltro === "ESTADO") {
          const equiposXEstado = this.equiposFiltrados?.filter(
            (item) => item?.estadoEquipo === this.estadoEquipo
          );
          this.equiposFiltrados = equiposXEstado;
        }
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    consultarTodos() {
      this.habilitarBodegas = false;
      this.habilitarInputBusqueda = false;
      this.habilitarEstados = false;
      this.valorBusqueda = "";
      this.equiposFiltrados = this.equipos;
    },
    mostrarBusquedaInput() {
      this.habilitarBodegas = false;
      this.habilitarInputBusqueda = true;
      this.habilitarEstados = false;
    },
    mostrarBodegas() {
      this.habilitarBodegas = true;
      this.habilitarInputBusqueda = false;
      this.habilitarEstados = false;
    },
    mostrarEstados() {
      this.habilitarBodegas = false;
      this.habilitarInputBusqueda = false;
      this.habilitarEstados = true;
    },
    updatePage(page){
      this.pagina = page;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    equipos() {
      return this.$store.getters["equiposProveedor/equiposProveedor"]
        ? this.$store.getters["equiposProveedor/equiposProveedor"]
        : [];
    },
    rol() {
      return this.$store.state.auth.rol;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
        ? this.$store.getters["bodegas/bodegas"]
        : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
<style></style>
