<template>
  <div
    class="modal fade"
    id="modalEditUbicacion"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalEditUbicacionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEditUbicacionLabel">
            <strong>{{ titulo }}</strong>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="method"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="card" style="width: 18rem">
                  <div class="card-body">
                    <h5 class="card-title">{{ equipoSeleccionado?.marca }}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Placa: {{ equipoSeleccionado?.placa }}
                    </h6>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Serie: {{ equipoSeleccionado?.serie }}
                    </h6>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Modelo: {{ equipoSeleccionado?.modelo }}
                    </h6>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Capacidad (pies): {{ equipoSeleccionado?.capacidadPies }}
                    </h6>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Tipo: {{ equipoSeleccionado?.tipoEquipo }}
                    </h6>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Ubicación actual: {{ equipoSeleccionado?.ubicacion }}
                    </h6>
                  </div>
                </div>
                <p>Seleccione la nueva ubicación física del equipo</p>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="cliente"
                        value="ENCI"
                        v-model="tipoUbicacion"
                        @click="seleccionarTipoUbicacion"
                      />
                      <label
                        class="form-check-label radio-margin"
                        for="cliente"
                      >
                        Cliente
                      </label>

                      <input
                        class="form-check-input"
                        type="radio"
                        id="bodega"
                        value="BODEGA"
                        v-model="tipoUbicacion"
                        @click="seleccionarTipoUbicacion"
                      />
                      <label class="form-check-label radio-margin" for="bodega">
                        Bodega
                      </label>
                    </div>
                  </div>
                </div>
                <br />
                <div class="form-group" v-if="mostrarCliente">
                  <label>Ingrese el Código</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="codigoCliente"
                  />
                </div>
                <br />
                <div class="form-group" v-if="mostrarBodega">
                  <label>Seleccione una Bodega</label>
                  <select class="form-control" v-model="bodega">
                    <option value="" disabled></option>
                    <option
                      v-for="item in bodegas"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.nombre }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Seleccione la ubicación</label>
                  <select class="form-control" v-model="estadoUbicacion">
                    <option value="" disabled></option>
                    <option value="DISPONIBLE" v-if="tipoUbicacion==='BODEGA'">DISPONIBLE</option>
                    <option value="RESERVADO" v-if="tipoUbicacion==='BODEGA'">RESERVADO</option>
                    <option value="BAJA" v-if="tipoUbicacion==='BODEGA'">BAJA</option>
                    <option value="PERDIDO" v-if="tipoUbicacion==='ENCI'">PERDIDO</option>
                    <option value="REPARACION" v-if="tipoUbicacion==='BODEGA'">REPARACION</option>
                    <option value="EVENTO" v-if="tipoUbicacion==='BODEGA'">EVENTO</option>
                    <option value="PRESTAMO" v-if="tipoUbicacion==='BODEGA'">PRESTAMO</option>
                    <option value="ENCI" v-if="tipoUbicacion==='ENCI'">ENCI</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary btn-general"
            data-dismiss="modal"
            @click="
              actualizar({
                tipoUbicacion,
                codigoCliente,
                bodega,
                estadoUbicacion,
                id: equipoSeleccionado.id,
              })
            "
          >
          <i class="ti-pencil"></i> Actualizar
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-general-off"
            data-dismiss="modal"
            @click="cerrar"
          >
          <i class="ti-close"></i> Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cerrar: { type: Function },
    actualizar: { type: Function },
    titulo: String,
  },
  data() {
    return {
      tipoUbicacion: null,
      codigoCliente: "",
      bodega: 0,
      ubicacion: "",
      mostrarCliente: false,
      mostrarBodega: false,
      estadoUbicacion: ""
    };
  },
  mounted() {
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarBodegas() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    seleccionarTipoUbicacion(e) {
      if (e.target.value === "ENCI") {
        this.mostrarCliente = true;
        this.mostrarBodega = false;
        this.bodega = 0;
        return;
      }
      if (e.target.value === "BODEGA") {
        this.mostrarCliente = false;
        this.mostrarBodega = true;
        this.codigoCliente = "";
        return;
      }
    },
  },
  computed: {
    equipoSeleccionado() {
      return this.$store.getters["equipos/equipoSeleccionado"]
        ? this.$store.getters["equipos/equipoSeleccionado"]
        : null;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
        ? this.$store.getters["bodegas/bodegas"]
        : [];
    },
  },
  watch: {
    equipoSeleccionado(equipoAnterior, equipoNuevo) {
      if (equipoAnterior !== equipoNuevo) {
        this.tipoUbicacion = null;
        this.codigoCliente = "";
        this.bodega = 0;
        this.ubicacion = "";
        this.mostrarCliente = false;
        this.mostrarBodega = false;
        this.estadoUbicacion = "";
      }
    },
  },
};
</script>
