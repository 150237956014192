<template>
  <GestionSolicitudesProveedor />
</template>
<script>
import GestionSolicitudesProveedor from "./components/GestionSolicitudesProveedor.vue";
export default {
  name: "SolicitudesProveedor",
  components: { GestionSolicitudesProveedor }
};
</script>
