<template>
  <GestionRegularizaciones />
</template>
<script>
import GestionRegularizaciones from "./componentes/GestionRegularizaciones";
export default {
  name: "Regularizaciones",
  components: { GestionRegularizaciones }
};
</script>
