import axios from "axios";

const state = {
  totales: null,
};

const getters = {
  totalEventos: (state) => state.totales?.totalEventos ?? 0,
  totalRequerimientos: (state) => state.totales?.totalRequerimientos ?? 0,
};

const mutations = {
  setTotales: (state, totales) => {
    state.totales = totales;
  },
};

const actions = {
  async consultarTotales ({ commit }) {
    try {
      let res = await axios.get(`/v1/ruta/home`);
      commit("setTotales", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
