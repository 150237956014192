<template>
  <div style="padding: 5%">
    <h5>Notas Aclaratorias</h5>
    <div class="container-notas">
      <ul>
        <li>
          Si deja de comprar un mes, reinicia el conteo de últimos meses
        </li>
        <li>
          Si compra volumen menor a 1.2 hL reinicia el conteo de últimos meses
        </li>
        <li>
          Si no paga una deuda a tiempo sale de la lista de espera
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

</script>
