<template>
  <GestionSolicitudes />
</template>
<script>
import GestionSolicitudes from "./components/GestionSolicitudes";
export default {
  name: "Solicitudes",
  components: { GestionSolicitudes }
};
</script>
