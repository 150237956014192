<template>
  <div
    class="modal fade"
    id="modalAdjuntos"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAdjuntosLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAdjuntosLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <ul>
                  <li v-for="(item, $index) in listaAdjuntos" :key="$index">
                    <a
                      href="javascript:void(0)"
                      @click="descargarAdjunto(item)"
                      >{{ item?.nombreArchivo }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-general-off"
              data-dismiss="modal"
            >
            <i class="ti-close"></i> Cerrar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titulo: String,
    listaAdjuntos: [Object],
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async descargarAdjunto(adjunto) {      
      const nombreArchivo = adjunto?.nombreArchivo;
      const extension = nombreArchivo?.split('.')[1];

      try {
        const resp = await this.$store.dispatch("adjuntos/descargarAdjunto", {
          idSolicitudProveedorArchivo: adjunto?.id,
        });
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `archivo.${extension}`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {},
};
</script>
