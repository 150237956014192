import axios from "axios";

const state = {
  analisis: null,
  mensaje: "",
  analisisMultiple: []
};

const getters = {
  analisis: (state) => state.analisis,
  analisisMultiple: (state) => state.analisisMultiple,
};

const mutations = {
  setAnalisis: (state, analisis) => {
    state.analisis = analisis;
  },
  setAnalisisMultiple: (state, analisisMultiple) => {
    state.analisisMultiple = analisisMultiple;
  },
  inicializar: (state) => {
    state.analisis = null;
    state.analisisMultiple = [];
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  }
};

const actions = {
  async consultarXSolicitud({ commit }, data) {
    try {
      let res = await axios.get(`/v1/analisis/${data.idSolicitud}/solicitud`);
      commit("setAnalisis", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async calcularAnalisis({ commit }, data) {
    try {      
      const res = await axios.post(`/v1/analisis/bajo-demanda`, data.codigoCliente);
      commit("setAnalisisMultiple", res?.data?.result);
    } catch (error) {
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
