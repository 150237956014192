<template>


  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
      <tr>
        <th>Año</th>
        <th>Mes</th>
        <th>Volumen</th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, $index) in data" :key="$index">
        <td>{{ item?.anio }}</td>
        <td>{{ item?.mes }}</td>
        <td>{{ item?.volumen }}</td>

      </tr>
      </tbody>
    </table>
  </div>


</template>
<script>
export default {
  props: {
    cerrar: {type: Function},
    data: Array,
  },
  data() {
    return {};
  },
};
</script>
