<template>
    <div class="mb-3">
        <button
            class="btn btn-block btn-secondary btn font-weight-medium auth-form-btn"
            @click.prevent="loginWithSSO">
            Iniciar Sesión con SSO
        </button>
    </div>        
</template>
<script>

  export default {
    name: "LoginSso",
    components: {},
    data() {
      return {

      };
    },
    methods: {
        async loginWithSSO(){
            const resp = await this.$store.dispatch("auth/sso"); 
            const { error, message } = resp;
            if (error) {
                this.error = true;
                this.$router.push({ name: "Login" });
                this.$emit('send-to-parent', message);
                return;
            }
            
            await this.choosePage(resp?.data?.result?.rol);
        },
        async choosePage(role){     
            switch (role) {
                case "ROLE_ADMIN":
                    this.$router.replace({ name: "Equipos" });
                    break;
                case "ROLE_BODEGA":
                    this.$router.replace({ name: "SolicitudesProveedor" });
                    break;
                case "ROLE_TECNICO":
                    await this.$store.dispatch("usuariosBodega/consultarBodegaTecnico");
                    this.$router.replace({ name: "DashboardTecnico" });
                    break;
                case "ROLE_TRANSPORTE":
                    await this.$store.dispatch("usuariosBodega/consultar");
                    this.$router.replace({ name: "SolicitudesTransporte" });
                    break;
                case "ROLE_ANALISTA_PRINCIPAL":
                    this.$router.replace({ name: "Equipos" });
                    break;
                case "ROLE_SUPERVISOR_TECNICO":
                    this.$router.replace({ name: "Equipos" });
                    break;
                case "ROLE_CXC":
                    this.$router.replace({ name: "AnalisisMultiple" });
                    break;
                case "ROLE_EVENTO":
                    this.$router.replace({ name: "EventosUsuario" });
                    break;
                default:
                    this.$router.push({ name: "Login" });
                    break;
            }
        }
    },
    computed: {

    },mounted() {

    }
  };
  </script>
  