<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de menú por rol</h3>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-0">
                <label>Rol *</label>
                <fieldset>
                  <select
                    name="rol"
                    class="form-control mb-2 mb-md-0"
                    v-model="rol"
                    @change="searchMenusByRole"
                  >
                    <option value="" disabled>Seleccione un rol</option>
                    <option
                      v-for="rol in roles"
                      :key="rol.id"
                      :value="rol.descripcion"
                    >
                      {{ rol.descripcion }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>
          <br />
          <div class="row" v-if="rol">
            <div class="col-md-6">
              <h4>Opciones disponibles</h4>
              <hr />
              <div
                class="d-flex justify-content-between mb-2"
                v-for="menu in menus"
                :key="menu.name"
              >
                <div>{{ menu.name }}</div>
                <div>
                  <button class="btn btn-primary btn-sm" @click="agregar(menu)">
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4>Opciones asignadas</h4>
              <hr />
              <div
                class="d-flex justify-content-between mb-2"
                v-for="menu in menuByRole"
                :key="menu.name"
              >
                <div>{{ menu.name }}</div>
                <div>
                  <button
                    class="btn btn-secondary btn-sm"
                    @click="eliminar(menu)"
                  >
                    -
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OpcionesMenuRol",
  data() {
    return {
      rol: "",
    };
  },
  created() {
    this.$store.commit("menu/setMenuByRole", null);
  },
  mounted() {
    this.searchRoles();
    this.searchMenus();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async searchRoles() {
      this.loaderSpinner();
      try {
        await this.$store.commit("roles/setRolSeleccionado", null);
        await this.$store.dispatch("roles/consultarRoles");
      } catch (error) {
        alert("No se pudo realizar la consulta de los roles");
      } finally {
        this.loader.hide();
      }
    },
    async searchMenus() {
      try {
        await this.$store.dispatch("menu/searchAllMenus");
      } catch (error) {
        alert("No se pudo realizar la consulta de los menús");
      }
    },
    async searchMenusByRole() {
      this.loaderSpinner();
      try {
        if (this.rol) {
          await this.$store.dispatch("menu/searchMenuByRole", {
            rol: this.rol,
          });
        }
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar(menu) {
      try {
        await this.$store.dispatch("menu/asignar", {
          rol: this.rol,
          menu: menu?.id,
          orden: menu?.orden,
        });
        await this.searchMenusByRole();
      } catch (error) {
        alert("No se pudo asignar el menú al rol");
      }
    },
    async eliminar(menu) {
      try {
        await this.$store.dispatch("menu/desasignar", {
          id: menu?.id,
          rol: this.rol
        });
        await this.searchMenusByRole();
      } catch (error) {
        alert("No se pudo asignar el menú al rol");
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    roles() {
      return this.$store.getters["roles/roles"]
        ? this.$store.getters["roles/roles"]
        : [];
    },
    roleSeleccionado() {
      return this.$store.getters["roles/rolSeleccionado"]
        ? this.$store.getters["roles/rolSeleccionado"]
        : [];
    },
    menus() {
      return this.$store.getters["menu/menus"]
        ? this.$store.getters["menu/menus"]
        : [];
    },
    menuByRole() {
      return this.$store.getters["menu/menuByRole"]
        ? this.$store.getters["menu/menuByRole"]
        : [];
    },
  },
};
</script>
<style></style>
