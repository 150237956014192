import axios from "axios";
import store from "../index";

const state = {
  bajas: null,
  mensaje: ""
};

const getters = {
  bajas: (state) => state.bajas,
};

const mutations = {
  setBajas: (state, bajas) => {
    state.bajas = bajas;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
};

const actions = {
  async consultarBajasContablesPaginado({ commit }, data) {
    try {
      let res = await axios.get(`/v1/bajas-contable/pagina/${data.pagina}`);
      commit("setBajas", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarBajasContablesTotalPaginas() {
    try {
      const resp = await axios.get(`/v1/bajas-contables/total`);
      store.dispatch("pagination/updateTotales", {
        totalPaginas: resp?.data?.totalPaginas,
        totalRegistros: resp?.data?.totalRegistros
      })
    } catch (error) {
      return error;
    }
  },
  async cargarArchivo({commit}, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/bajas/upload`, data, { headers });
      commit("setMensaje", "Se ha cargado con éxito");
      return "success"
    } catch (error) {
      return "error";
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
