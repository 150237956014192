import axios from "axios";

const state = {
  movimientos: null,
  mensaje: "",
};

const getters = {
  movimientos: (state) => state.movimientos,
};

const mutations = {
  setMovimientos: (state, movimientos) => {
    state.movimientos = movimientos;
  },
  inicializar: (state) => {
    state.movimientos = null;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
};

const actions = {
  async consultarMovimientos({ commit }, data) {
    try {
      let res = await axios.get(`/v1/movimientos/${data.idEquipoFrio}/equipo`);
      commit("setMovimientos", res?.data?.result);
    } catch (error) {
      commit("setMovimientos", []);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
