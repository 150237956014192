import axios from "axios";

const state = {
    solicitudesProveedorAtendidas: null,
    solicitudProveedorAtendidaSeleccionada: null
};

const getters = {
    solicitudesProveedorAtendidas: (state) => state.solicitudesProveedorAtendidas,
    solicitudProveedorAtendidaSeleccionada: (state) => state.solicitudProveedorAtendidaSeleccionada,

};

const mutations = {
    setSolicitudesProveedorAtendidas: (state, solicitudes) => {
        state.solicitudesProveedorAtendidas = solicitudes;
    },
    setSolicitudProveedorAtendidaSeleccionada: (state, solicitudProveedorSeleccionada) => {
        state.solicitudProveedorAtendidaSeleccionada = solicitudProveedorSeleccionada;
    }
}

const actions = {
    async consultarXFechas({commit}, data) {
        try {
            let res = await axios.get(`/v1/solicitudes-atendidas/proveedor/${data.fechaInicio}/${data.fechaFin}`);
            commit("setSolicitudesProveedorAtendidas", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async consultarXFechasBodega({commit}, data) {
        try {
            let res = await axios.get(`/v1/solicitudes-atendidas/bodega/${data.fechaInicio}/${data.fechaFin}`);
            commit("setSolicitudesProveedorAtendidas", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async consultarXFechasAdmin({commit}, data) {
        try {
            let res = await axios.get(`/v1/solicitudes-atendidas/${data.fechaInicio}/${data.fechaFin}`);
            commit("setSolicitudesProveedorAtendidas", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
