<template>
  <div
    class="modal fade"
    id="modalAgregarActividad"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAgregarActividadLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAgregarActividadLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Tipo</label>
              <select class="form-control" v-model="tipo" @change="seleccionarTipo">
                <option value="" disabled>Seleccione un tipo</option>
                <option value="RETIRO">RETIRO</option>
                <option value="SOLICITUD">SOLICITUD</option>
                <option value="REPARACION CON CORRECTIVO MAYOR">
                  REPARACIÓN CON CORRECTIVO MAYOR
                </option>
                <option value="REPARACION CON CORRECTIVO MENOR">
                  REPARACIÓN CON CORRECTIVO MENOR
                </option>
              </select>
            </div>
            <div class="form-check" v-if="tipoTrabajo.length">
              <input
                class="form-check-input ml-1"
                type="radio"
                id="tipoCorrectivo"
                checked
              />
              <label class="form-check-label" for="tipoCorrectivo">
                {{ tipoTrabajo }}
              </label>
            </div>
            <div>
              <div v-if="tipo !== 'SOLICITUD'">
                <p>Buscar Equipo</p>
                <BusquedaXPlaca
                  :seleccionarEquipo="seleccionarEquipo"
                  origen="MANTENIMIENTO"
                />
                <p v-if="equipos.length > 0">Equipos en el cliente</p>
                <div class="form-check" v-for="item in equipos" :key="item.id">
                  <input
                    class="form-check-input ml-1"
                    type="checkbox"
                    :id="item.id"
                    :value="item.id"
                    v-model="idEquipos"
                    @click="seleccionarFila"
                  />
                  <label class="form-check-label" :for="item.id">
                    <strong>Placa:</strong>
                    {{ item.placa }}
                    <strong>Serie:</strong>
                    {{ item.serie }}
                    <strong>Capacidad (cajas):</strong>
                    {{ item.capacidadCajas }}
                    <strong>Capacidad (pies):</strong>
                    {{ item.capacidadPies }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Observación</label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="observacion"
                ></textarea>
              </div>

              <div class="mb-3">
                <label for="formFile" class="form-label">Cargar archivo</label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  ref="file"
                  @change="uploadFile"
                />
              </div>
              <div class="mb-3" v-if="archivosArray.length > 0">
                <label for="formFile" class="form-label"
                  >Lista de archivos</label
                >
                <ul>
                  <li v-for="(archivo, $index) in archivosArray" :key="$index">
                    {{ archivo?.name }}
                    <a
                      href="javascript:void(0)"
                      class="text-danger"
                      @click="eliminarArchivo(archivo)"
                      ><i class="ti-close"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-general"
              data-dismiss="modal"
              @click="procesar"
              :disabled="!tipo || (tipo!=='SOLICITUD' && idEquipos.length === 0) || !archivo || observacion.length === 0"
            >
            <i class="ti-plus"></i> Agregar
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-general-off"
              data-dismiss="modal"
              @click="cerrar"
            >
            <i class="ti-close"></i> Cerrar
            </button>
          </div>
          <button
            type="button"
            v-show="false"
            id="btProcesar"
            class="btn btn-primary btn-general"
            data-dismiss="modal"
            @click="
              agregar({
                tipo,
                tipoTrabajo,
                observacion,
                idEquipos,
                archivosArray,
                solicitud:
                  origen === 'BODEGA'
                    ? solicitudProveedorSeleccionada.solicitud.id
                    : rutaSeleccionada.solicitudProveedor.solicitud.id,
              })
            "
          >
            Procesar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BusquedaXPlaca from "./BusquedaXPlaca.vue";

export default {
  components: { BusquedaXPlaca },
  props: {
    cerrar: { type: Function },
    agregar: { type: Function },
    titulo: String,
    origen: String,
  },
  data() {
    return {
      codigoCliente: "",
      tipo: "",
      observacion: "",
      idEquipos: [],
      tipoTrabajo: "",
      archivo: null,
      archivosArray: [],
    };
  },
  mounted() {
    this.consultarEquiposCliente();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarEquiposCliente() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("equipos/consultarEquipoXCliente", {
          cliente:
            this.origen === "BODEGA"
              ? this.solicitudProveedorSeleccionada?.solicitud?.codigoCliente
              : this.rutaSeleccionada?.solicitudProveedor?.solicitud
                  ?.codigoCliente,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarTipo() {
      if(this.tipo === 'SOLICITUD'){
        this.idEquipos = [];
      }
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
      this.archivosArray.push(this?.$refs?.file?.files[0]);
    },
    eliminarArchivo(archivo) {
      const archivosFiltrados = this.archivosArray?.filter(
        (item) => item !== archivo
      );
      this.archivosArray = archivosFiltrados;
    },
    seleccionarEquipo(datos) {
      this.idEquipos?.map(
        (item) => (window.document.getElementById(item).checked = false)
      );
      this.idEquipos = [];
      if (datos.tipo === "ADD") {
        this.idEquipos.push(datos.valor);
      }
      if (datos.tipo === "DELETE") {
        const idEquiposFiltrados = this.idEquipos.filter(
          (item) => item !== datos.valor
        );
        this.idEquipos = idEquiposFiltrados;
      }
    },
    inicializarCampos() {
      this.tipo = "";
      this.observacion = "";
      this.idEquipos = [];
      this.tipoTrabajo = "";
    } /* ,
    seleccionarTipo(e) {
      if (e.target.value === "RETIRO") {
        this.tipoTrabajo = "CORRECTIVO MAYOR";
        return;
      }
      if (e.target.value === "REPARACION") {
        this.tipoTrabajo = "CORRECTIVO MENOR";
        return;
      }
      if (e.target.value === "SOLICITUD") {
        this.tipoTrabajo = "";
      }
    } */,
    seleccionarFila(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;

      if (checked) {
        this.seleccionarEquipo({ tipo: "ADD", valor: parseInt(valor) });
      } else {
        this.seleccionarEquipo({ tipo: "DELETE", valor: parseInt(valor) });
      }
    },
    procesar() {
      document.getElementById("btProcesar").click();
      this.inicializarCampos();
    },
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
        "solicitudesProveedor/solicitudProveedorSeleccionada"
      ]
        ? this.$store.getters[
            "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
        ? this.$store.getters["equipos/equipos"]
        : {};
    },
    rutaSeleccionada() {
      return this.$store.getters["ruta/rutaSeleccionada"]
        ? this.$store.getters["ruta/rutaSeleccionada"]
        : [];
    },
  },
};
</script>
