import axios from "axios";

const state = {
  archivos: null,
  archivoSeleccionado: null,
  totalPaginas: null,
};

const getters = {
  archivos: (state) => state.archivos,
  archivoSeleccionado: (state) => state.archivoSeleccionado,
  totalPaginas: (state) => state.totalPaginas,
};

const mutations = {
  setArchivos: (state, archivos) => {
    state.archivos = archivos;
  },
  setArchivoSeleccionado: (state, archivoSeleccionado) => {
    state.archivoSeleccionado = archivoSeleccionado;
  },

  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
};

const actions = {
  async consultarSolicitudesXPagina({ commit }, data) {
    try {
      let res = await axios.get(`/v1/solicitudes/upload/${data.pagina}/pagina`);
      commit("setArchivos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginas({ commit }) {
    try {
      const resp = await axios.get(`/v1/solicitudes/upload/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async eliminarArchivo({ commit }, data) {
    try {
      await axios.delete(`v1/solicitudes/${data.idArchivo}/upload`);
      let res = await axios.get(`/v1/solicitudes/upload/1/pagina`);
      commit("setArchivos", res?.data?.result);
      const resp = await axios.get(`/v1/solicitudes/upload/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      return "Se ha eliminado con éxito";
    } catch (error) {
      return error?.response?.data?.errors?.[0] || "No se pudo eliminar el archivo";
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
