<template>
  <div class="error">
    <small>{{ mensaje }}</small>
  </div>
</template>
<script>
export default {
  props: ["mensaje"],
};
</script>
<style>
.error {
  color: #e91010;
  font-weight: normal !important;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 10px;
}
</style>
