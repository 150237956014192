<template>
  <div v-if="mostrar" class="alert alert-info alert-dismissible fade show" role="alert">
    <strong>{{ titulo }}</strong> {{ mensaje }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>
<script>
export default {
  props: [
    'titulo',
    'mensaje',
  ],
  data() {
    return {
      mostrar: true
    }
  }
}
</script>
