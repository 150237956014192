<template>
  <div class="container-scroller bg-cool">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="brand-logo">
              <img
                  src="../../../assets/images/imgs/logo-fondo-marino.png"
                  alt="logo"
                  class="d-block"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>
<script>
import Cookies from 'js-cookie';
export default {
  name: "Redirect",
  components: {},
  data() {
    return {
      cookieName: 'origin',
      cookieValue: process.env.VUE_APP_ORIGIN_COOKIE_MAIN_DEV
    };
  },
  methods: {
    setCookie() {
      Cookies.set(this.cookieName, this.cookieValue,{secure:true});
      location.replace('/')
    }
  },
  mounted() {
    this.setCookie();
  }
};
</script>
