<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Resultados de la encuesta</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>

        <div class="white-content-general mb-4">
          
          <div class="row">
            <div class="col-md-3">
              <input type="date" class="form-control" v-model="fechaInicio" />
            </div>
            <div class="col-md-3 mb-2">
              <input type="date" class="form-control" v-model="fechaFin" />
            </div>
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-secondary btn-general"
                @click="consultarResultadosEncuesta"
                :disabled="fechaInicio && fechaFin ? false : true"
              >
              <span class="icon-search"></span> Consultar
              </button>
            </div>
            <div class="col-md-3" v-if="rol === 'ROLE_ADMIN'">
              <button @click="exportExcel" class="btn btn-primary float-right">
                <span class="icon-download"></span> Exportar Excel
              </button>        
            </div>
          </div>
            
        </div>

        <div class="white-content-table">
          <div class="row">
            <div
              class="card col-lg-12 col-sm-12 mb-4"
              v-for="item in detalleResultadoEncuesta"
              :key="item.id"
            >
              <h5 class="card-header">
                <div class="d-flex justify-content-between">
                  <div>Código de cliente: {{ item.codigoCliente }}</div>
                  <div>{{ item.fecha }} - {{ item.hora }}</div>
                </div>
              </h5>
              <div class="card-body">
                <h6 class="card-subtitle">¿Su requerimiento fue atendido?</h6>
                <p class="card-title">{{ item.atendioRequerimiento }}</p>
                <h6 class="card-subtitle">¿El técnico le explicó cómo debe manejar adecuadamente el equipo de frío?</h6>
                <p class="card-title">{{ item.explicoManejo }}</p>
                <h6 class="card-subtitle">¿El técnico le explicó qué tipo de conexiones eléctricas debería tener en su local?</h6>
                <p class="card-title">{{ item.explicoConexion }}</p>
                <h6 class="card-subtitle">
                  ¿Cómo calificaría el servicio brindado por el técnico?
                </h6>
                <h5 class="card-title">{{ item.nivelSatisfaccion }}</h5>
                <h6 class="card-subtitle">{{ item.pregunta }}</h6>
                <p
                  class="card-text m-2"
                  v-for="(respuesta, index) in item.respuestas"
                  :key="index"
                >
                  {{ respuesta.respuesta }}
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";

export default {
  data() {
    return {
      fechaInicio: "",
      fechaFin: "",
    };
  },
  created() {
    this.$store.commit("encuesta/setResultadoEncuesta", []);
    this.$store.commit("encuesta/setDetalleResultadoEncuesta", []);
  },
  methods: {
    exportExcel() {
      saveExcel({
        data: this.detalleResultadoEncuesta,
        fileName: "data",
        columns: [
          { field: "id", title: "Identificador encuesta" },
          { field: "fecha", title: "Fecha encuesta" },
          { field: "hora", title: "Hora encuesta" },
          { field: "codigoCliente", title: "Código de cliente" },
          { field: "atendioRequerimiento", title: "¿Su requerimiento fue atendido?" },
          { field: "explicoManejo", title: "¿El técnico le explicó cómo debe manejar adecuadamente el equipo de frío?" },
          { field: "explicoConexion", title: "¿El técnico le explicó qué tipo de conexiones eléctricas debería tener en su local?" },
          {
            field: "nivelSatisfaccion",
            title:
              "¿Cómo calificaría el servicio brindado por el técnico?",
          },
          {
            field: "respuestasString",
            title: "¿Qué es lo que consideras que debemos mejorar?",
          },
          { field: "comentarios", title: "Comentarios" },
        ],
      });
    },
    async consultarResultadosEncuesta() {
      try {
        this.ranking = [];
        this.loaderSpinner();
        const data = {
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        };
        await this.$store.dispatch("encuesta/consultarDetalleResultadoEncuesta",data);
        //await this.$store.dispatch("encuesta/consultarResultadoEncuesta", data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
  },
  computed: {
    resultadoEncuesta() {
      return this.$store.state.encuesta.resultadoEncuesta;
    },
    detalleResultadoEncuesta() {
      return this.$store.state.encuesta.detalleResultadoEncuesta;
    },
  },
};
</script>
<style>
.btn-rechazar {
  margin-left: 10px;
}
</style>
