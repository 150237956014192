<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Usuarios</h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>
        
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0">
              
              </p>
            </div>
            <div class="col-md-8">
              <button @click="agregar" class="btn btn-primary btn-general float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-table">
          <div class="table-responsive" @change="consultar">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th>Nombre</th>
                  <th>Usuario</th>
                  <th>Rol</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in usuarios" :key="item.id" @change="consultar">
                  <td>
                    <a href="javascript:void(0)" @click="editar(item)"
                      ><i class="ti-pencil btn-icon-append"></i></a
                    >&nbsp;
                    <a href="javascript:void(0)" @click="eliminar(item.id)"
                      ><i class="ti-trash txt-rojo btn-icon-append"></i
                    ></a>&nbsp;
                    <a href="javascript:void(0)" @click="resetPassword(item.usuario)"
                      ><i class="ti-lock btn-icon-append"></i
                    ></a>
                  </td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.usuario }}</td>
                  <td>{{ item.rol }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionUsuarios",
  data() {
    return {};
  },
  created() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("usuarios/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("usuarios/setUsuarioSeleccionado", null);
      this.$router.push({ name: "UsuarioForm" });
    },
    async editar(usuario) {
      await this.$store.commit("usuarios/setUsuarioSeleccionado", usuario);
      this.$router.push({ name: "UsuarioForm" });
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        //icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("usuarios/eliminar", { id: id });
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
    async resetPassword(usuario) {
      this.$swal({
        text: "¿Está seguro de resetear la clave del usuario?",
        //icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("usuarios/resetPassword", { usuario });
            this.$swal({
              text: "Se ha reseteado la clave del usuario con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    }
  },
  computed: {
    usuarios() {
      return this.$store.getters["usuarios/usuarios"]
        ? this.$store.getters["usuarios/usuarios"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
  },
};
</script>
