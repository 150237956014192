import axios from "axios";
import store from "../index";

const state = {
  equiposProveedor: null,
  equipoProveedorSeleccionado: null,
};

const getters = {
  equiposProveedor: (state) => state.equiposProveedor,
  equipoProveedorSeleccionado: (state) => state.solicitudProveedorSeleccionada,
};

const mutations = {
  setEquiposProveedor: (state, equiposProveedor) => {
    state.equiposProveedor = equiposProveedor;
  },
  setSEquiposProveedorSeleccionado: (state, equiposProveedorSeleccionado) => {
    state.equiposProveedorSeleccionado = equiposProveedorSeleccionado;
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/equipos/proveedor`);
      commit("setEquiposProveedor", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async consultarEquiposProveedorPaginado({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/proveedor/pagina/${data.pagina}`);
      commit("setEquiposProveedor", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarEquiposProveedorTotalPaginas() {
    try {
      const resp = await axios.get(`/v1/equipos/proveedor/total`);
      store.dispatch("pagination/updateTotales", {
        totalPaginas: resp?.data?.totalPaginas,
        totalRegistros: resp?.data?.totalRegistros
      })
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
