import axios from "axios";

const state = {
  actividades: null,
  actividadSeleccionada: null,
  mensaje: ""
};

const getters = {
  actividades: (state) => state.actividades,
  actividadSeleccionada: (state) => state.actividadSeleccionada,
};

const mutations = {
  setActividades: (state, actividades) => {
    state.actividades = actividades;
  },
  setActividadSeleccionada: (state, actividadSeleccionada) => {
    state.actividadSeleccionada = actividadSeleccionada;
  },
  inicializar: (state) => {
    state.actividades = null,
    state.actividadSeleccionada = null
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  }
};

const actions = {
  async consultarXSolicitud({ commit }, data) {
    try {
      let res = await axios.get(`/v1/actividad/${data.idSolicitud}/solicitud`);
      commit("setActividades", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async crearActividad({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`v1/actividad`, data?.data, { headers });
      let res = await axios.get(`/v1/actividad/${data.idSolicitud}/solicitud`);
      commit("setActividades", res?.data?.result);
      return ['OK', 'Se ha creado la actividad con éxito']
    } catch (error) {
      return ['ERROR', error?.response?.data?.errors?.[0] || "No se pudo registrar la actividad"];
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
