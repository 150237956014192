<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Cargar Clientes a Lista de Espera</h3>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-6">
              <p>Seleccione el archivo</p>
              <div class="input-group mb-3">
                <input type="file" class="custom-file-input form-control" id="formFile" ref="file"
                       @change="uploadFile">
                <label class="custom-file-label" for="inputGroupFile01">Archivo</label>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <button type="button" class="btn btn-general-off btn-secondary mr-3" data-bs-dismiss="modal"
                      @click="cerrar">
                <i class="ti-close"></i> Cerrar
              </button>

              <button type="button" class="btn btn-general btn-primary " @click="cargarArchivo"
                      :disabled="archivo ? false : true">
                <span class="icon-upload"></span> Cargar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalMessages :mensaje="mensajeProceso" :titulo="titulo" :method="cerrar"/>

  </div>
</template>
<script>

import ModalMessages from "@/components/ModalMessages.vue";

export default {
  name: "WaitingListForm",
  components: {ModalMessages},
  data() {
    return {
      codigoCliente: null,
      motivo: null,
      archivo: null,
      mensajeProceso: [],
      titulo:''
    };
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    async cargarArchivo() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        const resp = await this.$store.dispatch(
            "waiting/cargarArchivo",
            formData
        );
        if (resp.status === "error") {
          this.error = true;
          this.loader.hide();
          this.titulo="Error de carga de archivo"
          this.mensajeProceso = [
            resp.error.errors[0]
          ]
          document.getElementById("modalButton").click();
          return;
        }
        this.displayModal = false;
        this.loader.hide();
        this.titulo = "Cargar archivo";
        this.mensajeProceso.push('Correctos: ' + resp.data.correctos);
        this.mensajeProceso.push('Fallidos: ' + resp.data.fallidos);
        document.getElementById("modalButton").click();

      } catch (error) {
        this.error = true;
        this.loader.hide();
      }
    },

    cerrar() {
      this.$router.go(-1);

    },
  },
  computed: {},
};
</script>
