import axios from "axios";

const state = {
  gerenciasGenerales: null,
  gerenciasVentas: null,
  cliente: null,
};

const getters = {
  gerenciasGenerales: (state) => state.gerenciasGenerales,
  gerenciasVentas: (state) => state.gerenciasVentas,
  cliente: (state) => state.cliente,
};

const mutations = {
  setGerenciasGenerales: (state, gerenciasGenerales) => {
    state.gerenciasGenerales = gerenciasGenerales;
  },
  setGerenciasVentas: (state, gerenciasVentas) => {
    state.gerenciasVentas = gerenciasVentas;
  },
  setCliente: (state, cliente) => {
    state.cliente = cliente;
  },
  inicializar: (state) => {
    state.gerenciasGenerales = null;
    state.gerenciasVentas = null;
    state.cliente = null;
  },
};

const actions = {
  async consultarGerenciasGenerales({ commit }) {
    try {
      let res = await axios.get(`/v1/catalogos/gerencia-general`);
      commit("setGerenciasGenerales", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async consultarGerenciasVentas({ commit }, data) {
    try {
      let res = await axios.post(
        `/v1/catalogos/gerencia-ventas/gerencia-general`, {gerenciaGeneral: data.gerenciaGeneral}
      );
      commit("setGerenciasVentas", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarRuteroXCliente({ commit }, data) {
    try {
      let res = await axios.get(`/v1/rutero/${data.codigoCliente}`);
      commit("setCliente", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
