import axios from "axios";

const state = {
  solicitudesProveedor: null,
  solicitudProveedorSeleccionada: null,
  coordenadas: [],
  puntosSeleccionados: [],
  mensaje: ""
};

const getters = {
  solicitudesProveedor: (state) => state.solicitudesProveedor,
  solicitudProveedorSeleccionada: (state) => state.solicitudProveedorSeleccionada,
  coordenadas: (state) => state.coordenadas,
  puntosSeleccionados: (state) => state.puntosSeleccionados,
};

const mutations = {
  setSolicitudes: (state, solicitudes) => {
    state.solicitudesProveedor = solicitudes;
  },
  setSolicitudProveedorSeleccionada: (state, solicitudProveedorSeleccionada) => {
    state.solicitudProveedorSeleccionada = solicitudProveedorSeleccionada;
  },
  setCoordenadas: (state, coordenadas) => {
    state.coordenadas = coordenadas;
  },
  setPuntosSeleccionados: (state, punto) => {
    state.puntosSeleccionados = [...state.puntosSeleccionados, punto];
  },
  updatePuntosSeleccionados: (state, puntos) => {
    state.puntosSeleccionados = [...puntos];
  },
  deletePuntoSeleccionado: (state, punto) => {
    const puntosActualizados = state.puntosSeleccionados.filter(
      (item) => item !== punto
    );
    state.puntosSeleccionados = [...puntosActualizados];
  },
  inicializar: (state) => {
    state.solicitudesProveedor = null
  },
  inicializarPuntosSeleccionados: (state) => {
    state.puntosSeleccionados = []
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  }
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/solicitudes-asignadas`);
      commit("setSolicitudes", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async procesarRetiro({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`v1/solicitudes-asignadas/procesar-retiro`, data?.data, { headers });
      commit("setMensaje", "Retiro exitoso");
      return "Proceso de retiro exitoso";
      /* const res = await axios.get(`/v1/solicitudes-asignadas`);
      commit("setSolicitudes", res?.data?.result);
      return res?.data?.result; */
    } catch (error) {
      return "No se pudo procesar el requerimiento";
    }
  },
  async procesarInstalacion({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`v1/solicitudes-asignadas/procesar-instalacion`, data?.data, { headers });
      commit("setMensaje", "Instalación exitosa");
      return "Proceso de instalación exitoso";
      /* const res = await axios.get(`/v1/solicitudes-asignadas`);
      commit("setSolicitudes", res?.data?.result);
      return res?.data?.result; */
    } catch (error) {
      return error?.response?.data?.errors?.[0] || "No se pudo procesar el requerimiento";
    }
  },
  async procesarMantenimiento({ commit }, data) {
    try {
      //const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`v1/solicitudes-asignadas/procesar-mantenimiento`, data?.data);
      commit("setMensaje", "Mantenimiento exitoso");
      return "Proceso de mantenimiento exitoso";
    } catch (error) {
      return "No se pudo procesar el requerimiento";
    }
  },
  async reasignarRequerimiento({ commit }, data) {
    try {
      await axios.post(`v1/reasignaciones`, data?.data);
      commit("setMensaje", "Reasignamiento exitoso");
      return "Proceso de Reasignamiento exitoso";
    } catch (error) {
      return "ERROR";
    }
  },
  async procesarCambio({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`v1/solicitudes-asignadas/procesar-cambio`, data?.data, { headers });
      commit("setMensaje", "Cambio exitoso");
      return "Proceso de cambio exitoso";
    } catch (error) {
      return error?.response?.data?.errors?.[0] || "No se pudo procesar el requerimiento";
    }
  }

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
