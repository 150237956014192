<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión Waiting List</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-12">
              <a
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#modalFiltros"
                data-backdrop="static"
                data-keyboard="false"
                class="btn btn-primary btn-general float-right mb-2 mb-md-0"
              >
                <i class="ti-filter menu-icon"></i> Filtrar</a
              >

              <button
                @click="descargar"
                class="btn btn-general btn-primary float-right mr-2"
            >
              <span class="icon-download"></span> Descargar
            </button>
              <button
                @click="agregar"
                class="btn btn-general btn-primary float-right mr-2"
              >
                <span class="icon-upload"></span> Cargar Archivo
              </button>
            <div class="dropdown">
              <button
                  class="btn btn-primary btn-general dropdown-toggle float-right mr-2"
                  type="button"
                  id="tipo"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                <i class="ti-check"></i> ACCIÓN
              </button>
              <div class="dropdown-menu" aria-labelledby="tipo">
                <a class="dropdown-item" href="#" @click="seleccionarAccion('APROBAR')"
                >APROBAR</a
                >
                <a class="dropdown-item" href="#" @click="seleccionarAccion('RECHAZAR')"
                >RECHAZAR</a
                >
              </div>
            </div>


            </div>
          </div>
        </div>
        <div class="white-content-table">
          <div class="table-responsive">

            <table class="table table-striped">
              <thead>
              <tr>
                <th><input type="checkbox" v-model="checkAll" @change="selectAll"/></th>

                <th>Proceso</th>
                <th>Aplica</th>
                <th>Aprobacion</th>
                <th>Codigo Cliente</th>
                <th>Identificación</th>
                <th>Nombre Negocio</th>
                <th>Nombre Cliente</th>
                <th>Gerencia General</th>
                <th>Gerencia Ventas</th>
                <th>Sector Desarrollo</th>
                <th>Canal</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item) in waitingList" :key="item.id">
                <td>
                  <input type="checkbox" :checked="isSelected(item.id)" @change="selectItem(item)"
                         v-if="item.aplica===true && item.aprobacion==='PENDIENTE'"/>
                </td>
                <td>{{item.estadoProceso}}</td>
                <td>
                  <div v-if="item.estadoProceso !== 'CARGADA'">
                    <div
                        class="badge"
                        :class="
                      item.aplica===true
                        ? 'badge-success'
                        : 'badge-warning'
                    "
                        v-if="item.aplica"
                    >
                      SI
                    </div>
                    <div
                        class="badge"
                        :class="
                      item.aplica===false
                        ? 'badge-danger'
                        : 'badge-warning'
                    "
                        v-else
                    >
                      NO
                    </div>
                  </div>

                </td>
                <td>
                  <div
                      class="badge"
                      :class="
                        item.aprobacion==='APROBADA'
                          ? 'badge-success'
                          : item.aprobacion==='RECHAZADA'
                          ? 'badge-danger'
                          : 'badge-warning'
                      "
                  >
                    {{ item.aprobacion }}
                  </div>
                </td>

                <td>{{ item.codigoCliente }}</td>
                <td>{{ item.identificacion }}</td>
                <td>{{ item.nombreNegocio }}</td>
                <td>{{ item.nombreCliente }}</td>
                <td>{{ item.gerenciaGeneral }}</td>
                <td>{{ item.gerenciaVentas }}</td>
                <td>{{ item.sectorDesarrollo }}</td>
                <td>{{ item.canal }}</td>
              </tr>
              </tbody>
            </table>
            <Paginacion :updatePage="updatePage"/>
            <Filtros
              titulo="Seleccionar filtros"
              :consultar="consultarXFiltros"
              entidad="waiting_list"
            />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Paginacion from '../../../components/Paginacion';
import Filtros from "@/components/CustomFilters.vue";
export default {
  name: "GestionWaitingList",
  components: {
    Paginacion,
    Filtros
  },
  data() {
    return {
      pagina: 1,
      selectedItems: [],
      checkAll: false
    };
  },
  created() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      try {
        this.loaderSpinner();
        await this.$store.dispatch("waiting/consultarWaitingListPaginado", data);
        await this.$store.dispatch(
            "waiting/consultarWaitingListTotalPaginas"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      this.$router.push({name: "WaitingListForm"});
    },

    async goToArchivosCargados() {
      this.$router.push({name: "WaitingListArchivosCargados"});
    },

    updatePage(page) {
      this.pagina = page;
    },

    selectItem(check) {
      const findedOption = this.selectedItems?.findIndex(
          (item) => item === check.id
      );
      let arraySelectedItems = [];
      if (findedOption === -1) {
        arraySelectedItems = [...this.selectedItems, check.id];
      } else {
        arraySelectedItems = this.selectedItems.filter(
            (item) => item !== check.id
        );
      }
      this.selectedItems = arraySelectedItems;
    },

    selectAll() {
      this.selectedItems = [];
      if (this.checkAll) {
        for (let i = 0; i < this.waitingList.length; i++) {
          if (this.waitingList[i].aplica === true && this.waitingList[i].aprobacion === 'PENDIENTE') {
            this.selectedItems.push(this.waitingList[i].id);
          }
        }
      }
    },

    isSelected(id) {
      return this.selectedItems.includes(id);
    },

    async seleccionarAccion(accion) {
      let data = null;
      const codigosSeleccionados = this.waitingList
          .filter(elemento => this.selectedItems.includes(elemento.id))
          .map(elemento => elemento.codigoCliente);
      if (accion === "APROBAR") {
        data = {
          estado: "APROBADA",
          codigos: codigosSeleccionados
        }
        this.$swal({
          text: "¿Desea aprobar " + this.selectedItems.length + " registros?",
          //icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("waiting/aprobarWaitingList", data);
              await this.consultar(1);
              this.selectedItems = [];
              this.$swal({
                text: "Registros aprobados con éxito.",
                icon: "success",
              });
            } catch (error) {
              this.$swal("", error.message, "error");
            } finally {
              this.loader.hide();
            }
          }
        })
      } else {
        data = {
          estado: "RECHAZADA",
          codigos: codigosSeleccionados
        }
        this.$swal({
          text: "¿Desea rechazar " + this.selectedItems.length + " registros?",
          //icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("waiting/aprobarWaitingList", data);
              await this.consultar(1);
              this.selectedItems = [];
              this.$swal({
                text: "Registros rechazados con éxito.",
                icon: "success",
              });
            } catch (error) {
              this.$swal("", error.message, "error");
            } finally {
              this.loader.hide();
            }
          }
        })
      }
    },
    async descargar() {
      if (this.condiciones === undefined) {
        this.condiciones = [];
      }
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("waiting/exportar");
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `waiting.csv`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async consultarXFiltros(data) {
      this.condiciones = data?.condiciones;
      this.modoFiltros = true;


      this.loaderSpinner();
      try {
        await this.$store.dispatch("waiting/consultarWaitingListXFiltros", {
          pagina: data?.inicializarPagina ? 1 : this.paginaFiltros,
          condiciones: this.condiciones || [],
        });

        this.totalRegistros = this.$store.getters["waiting/totalRegistros"];
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },

  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    waitingList() {
      return this.$store.getters["waiting/waitingList"]
          ? this.$store.getters["waiting/waitingList"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
<style></style>
