<template>
  <div class="container-scroller">
    <Userbar />
    <div class="container-fluid page-body-wrapper">
      <Sidebar />
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import Sidebar from "../components/Sidebar";
import Userbar from "../components/Userbar";


export default {
  name: "App",
  components: {
    Userbar,
    Sidebar,
  },
};
</script>
