<template>
  <GestionAnalisisMultipleColumna />
</template>
<script>
import GestionAnalisisMultipleColumna from "./components/GestionAnalisisMultipleColumna";
export default {
  name: "AnalisisScreen",
  components: { GestionAnalisisMultipleColumna }
};
</script>
