<template>
  <div class="d-flex justify-content-center">
    <nav aria-label="Page navigation example" v-if="paginas">
      <ul class="pagination">
        <li class="page-item" :disabled="pagina === 1">
          <a class="page-link" @click="setPreviewPage">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          v-for="page in paginas"
          :key="page"
          class="page-item"
          :class="{ active: pagina === page }"
        >
          <a class="page-link" @click="setPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :disabled="pagina === paginas">
          <a class="page-link" @click="setNextPage">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: "Paginacion",
  props: {
    updatePage: { type: Function },
  },
  data() {
    return {
      pagina: 1,
    };
  },
  methods: {
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
  },
  computed: {
    totalPaginas() {
      let origen = this.$store.getters[`pagination/totalPaginas`];
      return origen
        ? origen
        : 0;
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.updatePage(newPage);
      }
    },
  },
};
</script>
