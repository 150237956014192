<template>
  <GestionSolicitudesProveedorAtendidas />
</template>
<script>
import GestionSolicitudesProveedorAtendidas from "./components/GestionSolicitudesProveedorAtendidas.vue";
export default {
  name: "SolicitudesProveedorAtendidas",
  components: { GestionSolicitudesProveedorAtendidas }
};
</script>
