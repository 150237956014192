<template>
  <div>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>

        <tr>
          <th>Placa</th>
          <th>Capacidad en pies</th>
          <th>Logo</th>
          <th>Ubicacion</th>
          <th>Fecha entrega</th>
          <th>Productividad</th>
          <th>Cajas faltantes</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in equipos" :key="index">
          <th>{{item.placa}}</th>
          <th>{{item.capacidadPies}}</th>
          <th>{{item.logo}}</th>
          <th>{{item.estadoEquipo}}</th>
          <th>{{item.fechaEntrega}}</th>
          <th>{{item?.productividad}}</th>
          <th>{{item?.cajasFaltantes}}</th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
<script>
export default {
  props : {
    equipos : []
  }
}
</script>
