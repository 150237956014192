<template>
  <GestionSolicitudesTransporte />
</template>
<script>
import GestionSolicitudesTransporte from "./components/GestionSolicitudesTransporte.vue";
export default {
  name: "SolicitudesTransporte",
  components: { GestionSolicitudesTransporte }
};
</script>
