<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Eventos</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <p class="lh-m mb-0">
              </p>
            </div>
            <div class="col-md-6">

              <button class="btn btn-primary btn-general float-right " @click="agregar">
                <span class="icon-plus"></span> Agregar
              </button>

              <button
                  class="btn btn-primary btn-general float-right mr-3"
                  @click="exportExcel"
                  :disabled="eventos.length === 0"
              >
                <span class="icon-download"></span> Descargar
              </button>

            </div>
          </div>
        </div>

        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%"></th>
                <th>Cliente</th>
                <th>Nombre</th>
                <th>Número equipos</th>
                <th>Orientacion</th>
                <th>Fecha creación</th>
                <th>Gerencia General</th>
                <th>Gerencia Ventas</th>
                <th>Provincia</th>
                <th>Sector</th>
                <th>Dirección</th>
                <th>Teléfono cliente</th>
                <th>Nombre evento</th>
                <th>Fecha de entrega</th>
                <th>Hora de entrega</th>
                <th>Fecha de retiro</th>
                <th>Hora de entrega</th>
                <th>Detalle permiso</th>
                <th>Responsable</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in eventos" :key="item.id">
                <td>
                  <a
                      href="javascript:void(0)"
                      @click="editar(item)"
                      data-toggle="modal"
                      data-target="#modalAprobarEvento"
                      data-backdrop="static"
                      data-keyboard="false"
                      :class="item?.aprobado ? 'text-secondary' : ''"
                  >Aprobar</a
                  >
                </td>
                <td>{{ item.codigoCliente }}</td>
                <td>{{ item.nombreCliente }}</td>
                <td>{{ item.numeroEquipos }}</td>
                <td>{{ item.orientacionEquipo }}</td>
                <td>{{ item.fechaRegistro }}</td>
                <td>{{ item.gerenciaGeneral }}</td>
                <td>{{ item.gerenciaVentas }}</td>
                <td>{{ item.provincia }}</td>
                <td>{{ item.sectorBarrio }}</td>
                <td>{{ item.direccion }}</td>
                <td>{{ item.telefono }}</td>
                <td>{{ item.nombreEvento }}</td>
                <td>{{ item.fechaEntrega }}</td>
                <td>{{ item.horaEntrega }}</td>
                <td>{{ item.fechaRetiro }}</td>
                <td>{{ item.horaRetiro }}</td>
                <td>{{ item.detallePermiso }}</td>
                <td>{{ item.responsable }}</td>
              </tr>
              </tbody>
            </table>

            <paginacion :updatePage="updatePage"/>

          </div>
          <AprobarEvento titulo="Aprobar evento" :asignar="aprobar"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveExcel} from "@progress/kendo-vue-excel-export";
import AprobarEvento from "./AprobarEvento.vue";
import Paginacion from "@/components/Paginacion.vue";

export default {
  name: "GestionEventos",
  data() {
    return {
      pagina: 1,
    };
  },
  created() {
    this.consultar(1)
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    updatePage(page) {
      this.pagina = page;
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("eventos/consultarEventosXPagina", data);
        await this.$store.dispatch(
            "eventos/consultarTotalPaginas"
        );
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("eventos/setEventoSeleccionado", null);
      this.$router.push({name: "EventoForm"});
    },
    async editar(evento) {
      await this.$store.commit("eventos/setEventoSeleccionado", evento);
    },
    async aprobar(data) {
      const dataForm = {
        idEvento: data?.idEvento,
        fechas: data?.fechas,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("eventos/aprobarEvento", dataForm);
        this.loader.hide();
        this.consultar();
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
        this.loader.hide();
      }
    },
    exportExcel() {
      saveExcel({
        data: this.eventos,
        fileName: "eventos",
        columns: [
          {field: "codigoCliente", title: "Cliente"},
          {field: "fechaRegistro", title: "Fecha creación"},
          {field: "nombreCliente", title: "Nombre cliente"},
          {field: "numeroEquipos", title: "Número equipos"},
          {field: "orientacionEquipo", title: "Orientación equipo"},
          {field: "gerenciaGeneral", title: "Gerencia General"},
          {field: "gerenciaVentas", title: "Gerencia de Ventas"},
          {field: "provincia", title: "Provincia"},
          {field: "sectorBarrio", title: "Sector"},
          {field: "direccion", title: "Dirección"},
          {field: "telefono", title: "Teléfono"},
          {
            field: "nombreEvento",
            title: "Nombre evento",
          },
          {field: "fechaEntrega", title: "Fecha de entrega"},
          {field: "horaEntrega", title: "Hora entrega"},
          {field: "fechaRetiro", title: "Fecha de retiro"},
          {field: "horaRetiro", title: "Hora retiro"},
          {field: "detallePermiso", title: "Detalle permiso"},
          {field: "responsable", title: "Responsable"},
        ],
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    eventos() {
      return this.$store.getters["eventos/eventos"]
          ? this.$store.getters["eventos/eventos"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
  components: {Paginacion, AprobarEvento},
};
</script>
<style></style>
