import axios from "axios";

const state = {
  roles: [],
  rolSeleccionado: null
};

const getters = {
  roles: (state) => state.roles,
  rolSeleccionado: (state) => state.rolSeleccionado,
};

const mutations = {
  setRoles: (state, roles) => {
    state.roles = roles;
  },
  setRolSeleccionado: (state, rolSeleccionado) => {
    state.rolSeleccionado = rolSeleccionado;
  },
};

const actions = {
  async consultarRoles({ commit }) {
    try {
      let res = await axios.get(`/v1/roles`);
      commit("setRoles", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
