<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              {{ usuarioSeleccionado ? "Editar usuario" : "Agregar usuario" }}
            </h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                  @submit="submit"
                  :validation-schema="formValidation"
                  v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="nombre">Nombre *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="nombre"
                                id="nombre"
                                class="form-control"
                                placeholder="Nombre"
                                v-model.trim="formData.nombre"
                              />
                              <MensajeValidacion :mensaje="errors.nombre" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="usuario">Usuario *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="usuario"
                                id="usuario"
                                class="form-control"
                                placeholder="Usuario"
                                v-model.trim="formData.usuario"
                              />
                              <MensajeValidacion :mensaje="errors.usuario" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Tipo *</label>
                            <fieldset>
                              <Field
                                as="select"
                                name="tipo"
                                class="form-control"
                                placeholder="Tipo"
                                v-model.trim="formData.tipo"
                              >
                                <option value="" disabled>
                                  Seleccione un tipo
                                </option>
                                <option value="TECNICO">TECNICO</option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipo" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Bodega *</label>
                            <fieldset>
                              <Field
                                as="select"
                                name="bodega"
                                class="form-control"
                                v-model="formData.bodega"
                                placeholder="Bodega"
                              >
                                <option value="" disabled>
                                  Seleccione una bodega
                                </option>
                                <option
                                  v-for="bodega in bodegas"
                                  :key="bodega.id"
                                  :value="bodega.id"
                                >
                                  {{ bodega.nombre }}
                                </option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.bodega" />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error" />
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="submit"
                        id="form-submit"
                        class="btn btn-primary btn-general mr-2"
                      >
                        Guardar
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary btn-general-off"
                        @click="cerrar"
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";

export default {
  name: "UsuarioVehiculoTransporteForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      nombre: "required",
      usuario: "required",
      tipo: "required",
      bodega: "required",
    };
    return {
      formData: {
        nombre: "",
        usuario: "",
        tipo: "",
        bodega: "",
      },
      error: false,
      mensaje: "",
      formValidation,
    };
  },
  mounted() {
    if (this.usuarioSeleccionado) {
      this.formData = { ...this.usuarioSeleccionado };
    }
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarBodegas() {
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      }
    },
    async submit() {
      this.error = false;
      this.loaderSpinner();
      try {
        if (this.usuarioSeleccionado) {
          await this.$store.dispatch("usuarios/editar", {
            id: this.usuarioSeleccionado.id,
            data: this.formData,
          });
          await this.$store.commit("usuarios/setUsuarioSeleccionado", null);
        } else {
          await this.$store.dispatch(
            "usuarios/crearUsuarioVehiculoTransporte",
            this.formData
          );
        }
        this.formData.nombre = "";
        this.formData.usuario = "";
        this.formData.tipo = "";
        this.formData.bodega = "";
        this.mensaje = "Usuario creado con éxito";
        //this.$router.push({ name: "Usuarios" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    usuarioSeleccionado() {
      return this.$store.getters["usuarios/usuarioSeleccionado"]
        ? this.$store.getters["usuarios/usuarioSeleccionado"]
        : null;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
        ? this.$store.getters["bodegas/bodegas"]
        : [];
    },
  },
};
</script>
