<template>
  <div
    class="col-md-12 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Evento</h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>        

        <div class="white-content-table">
          <div class="alert alert-info" role="alert">
            <ul>
              <li>
                Recuerda que los equipos de frío para eventos se deben solicitar con al menos 48 horas de anticipación al evento.
              </li>
              <li>
                Se entregarán únicamente equipos horizontales.
              </li>
              <li>
                Las entregas y retiros de los equipos se realizarán de lunes a viernes de {{ horario?.horaInicio }} a  {{ horario?.horaFin }} y los sábados de {{ horario?.horaInicioSabado }} a  {{ horario?.horaFinSabado }}
              </li>
            </ul>
          </div>
          <Form
            @submit="submit"
            :validation-schema="formValidation"
            v-slot="{ errors }"
          >
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="nombreEvento">Nombre evento *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="nombreEvento"
                          class="form-control"
                          placeholder="Nombre evento"
                          v-model.trim="formData.nombreEvento"
                        />
                        <MensajeValidacion :mensaje="errors.nombreEvento" />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="codigoCliente">Código</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="codigoCliente"
                          id="codigoCliente"
                          class="form-control"
                          placeholder="Código de cliente"
                          v-model.trim="formData.codigoCliente"
                          @blur="consultarCliente"
                        />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="nombreCliente">Cliente *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="nombreCliente"
                          class="form-control"
                          placeholder="Cliente"
                          v-model.trim="formData.nombreCliente"
                        />
                        <MensajeValidacion :mensaje="errors.nombreCliente" />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Gerencia General *</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="gerenciaGeneral"
                          class="form-control"
                          placeholder="Gerencia General"
                          v-model="formData.gerenciaGeneral"
                          @change="consultarGerenciasVentas"
                        >
                          <option value="" disabled>
                            Seleccione una gerencia
                          </option>
                          <option
                            v-for="item in gerenciasGenerales"
                            :key="item.descripcion"
                            :value="item.descripcion"
                          >
                            {{ item.descripcion }}
                          </option>
                        </Field>
                        <MensajeValidacion :mensaje="errors.gerenciaGeneral" />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Gerencia Ventas *</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="gerenciaVentas"
                          class="form-control"
                          placeholder="Gerencia Ventas"
                          v-model="formData.gerenciaVentas"
                        >
                          <option value="" disabled>
                            Seleccione una gerencia
                          </option>
                          <option
                            v-for="item in gerenciasVentas"
                            :key="item.descripcion"
                            :value="item.descripcion"
                          >
                            {{ item.descripcion }}
                          </option>
                        </Field>
                        <MensajeValidacion :mensaje="errors.gerenciaVentas" />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="telefono">Teléfono cliente *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="telefono"
                          class="form-control"
                          placeholder="Teléfono cliente"
                          v-model.trim="formData.telefono"
                          maxlength="10"
                        />
                        <MensajeValidacion :mensaje="errors.telefono" />
                      </fieldset>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="responsable">Responsable *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="responsable"
                          class="form-control"
                          placeholder="Responsable"
                          v-model.trim="formData.responsable"
                        />
                        <MensajeValidacion :mensaje="errors.responsable" />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="provincia">Provincia *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="provincia"
                          class="form-control"
                          placeholder="Provincia"
                          v-model.trim="formData.provincia"
                        />
                        <MensajeValidacion :mensaje="errors.provincia" />
                      </fieldset>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="sectorBarrio">Ciudad *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="sectorBarrio"
                          class="form-control"
                          placeholder="Sector o barrio"
                          v-model.trim="formData.sectorBarrio"
                        />
                        <MensajeValidacion :mensaje="errors.sectorBarrio" />
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="poblacion">Sector/barrio/referencia *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="poblacion"
                          class="form-control"
                          placeholder="Población"
                          v-model.trim="formData.poblacion"
                        />
                        <MensajeValidacion :mensaje="errors.poblacion" />
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center">
                  <div class="col-md-9">
                    <div class="form-group">
                      <label for="direccion">Dirección *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="direccion"
                          class="form-control"
                          placeholder="Dirección"
                          v-model.trim="formData.direccion"
                        />
                        <MensajeValidacion :mensaje="errors.direccion" />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-secondary btn-general"
                      @click="buscarDireccion"
                    >
                      Buscar
                    </button>
                  </div>
                </div>

                <div id="map" style="width: 100%; height: 600px"></div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="responsable">Latitud *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="latitud"
                          class="form-control"
                          placeholder="Latitud"
                          v-model.trim="formData.latitud"
                          disabled
                        />
                        <MensajeValidacion :mensaje="errors.latitud" />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="responsable">Longitud *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="longitud"
                          class="form-control"
                          placeholder="Longitud"
                          v-model.trim="formData.longitud"
                          disabled
                        />
                        <MensajeValidacion :mensaje="errors.longitud" />
                      </fieldset>
                    </div>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="fechaEntrega"
                        >Fecha entrega logística *</label
                      >
                      <fieldset>
                        <Field
                          type="date"
                          name="fechaEntrega"
                          class="form-control"
                          placeholder="Fecha entrega logística"
                          v-model.trim="formData.fechaEntrega"
                          @change="consultarHorario('ENTREGA')"
                        />
                        <MensajeValidacion :mensaje="errors.fechaEntrega" />
                      </fieldset>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="horaEntrega">Hora entrega *</label>
                      <fieldset>
                        <Field
                          type="time"
                          name="horaEntrega"
                          class="form-control"
                          placeholder="Hora de entrega"
                          v-model.trim="formData.horaEntrega"
                          required
                          id="horaEntrega"
                          @change="verificarHorario('ENTREGA')"
                        />
                        <MensajeValidacion :mensaje="errors.horaEntrega" />
                      </fieldset>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="fechaRetiro">Fecha retiro logística *</label>
                      <fieldset>
                        <Field
                          type="date"
                          name="fechaRetiro"
                          class="form-control"
                          placeholder="Fecha retiro logística"
                          v-model.trim="formData.fechaRetiro"
                          @change="consultarHorario('RETIRO')"
                        />
                        <MensajeValidacion :mensaje="errors.fechaRetiro" />
                      </fieldset>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="horaRetiro">Hora retiro *</label>
                      <fieldset>
                        <Field
                          type="time"
                          name="horaRetiro"
                          class="form-control"
                          placeholder="Hora de retiro"
                          v-model.trim="formData.horaRetiro"
                          required
                          id="horaRetiro"
                          @change="verificarHorario('RETIRO')"
                        />
                        <MensajeValidacion :mensaje="errors.horaRetiro" />
                      </fieldset>
                    </div>
                  </div>

                  <!-- <div class="col-md-6">
                    <div class="form-group">
                      <label>Proveedor *</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="proveedor"
                          class="form-control"
                          placeholder="Proveedor"
                          v-model="formData.proveedor"
                        >
                          <option value="" disabled>
                            Seleccione un proveedor
                          </option>
                          <option
                            v-for="item in proveedores"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{ item.nombreProveedor }} - {{ item.direccion }}
                          </option>
                        </Field>
                        <MensajeValidacion :mensaje="errors.proveedor" />
                      </fieldset>
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Tipo de equipo *</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="orientacionEquipo"
                          class="form-control"
                          v-model="formData.orientacionEquipo"
                        >
                          <option value="" disabled>Seleccione ...</option>
                          <option value="HORIZONTAL">HORIZONTAL</option>
                          <option value="VERTICAL">VERTICAL</option>
                        </Field>
                        <MensajeValidacion
                          :mensaje="errors.orientacionEquipo"
                        />
                      </fieldset>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Requiere permiso *</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="requierePermiso"
                          class="form-control"
                          v-model="formData.requierePermiso"
                        >
                          <option value="" disabled>Seleccione ...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </Field>
                        <MensajeValidacion :mensaje="errors.requierePermiso" />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="responsable">Requisitos para entrega/retiro *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="detallePermiso"
                          class="form-control"
                          placeholder="Detalle de permisos"
                          v-model.trim="formData.detallePermiso"
                        />
                        <MensajeValidacion :mensaje="errors.detallePermiso" />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div>
                  <p>Detalle de equipos</p>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <input
                      type="number"
                      class="form-control"
                      v-model="numeroEeff"
                      placeholder="Cantidad de eeff"
                    />
                  </div>
                  <div class="col-md-3">
                    <input
                      type="number"
                      class="form-control"
                      v-model="capacidadPies"
                      placeholder="Capacidad pies"
                    />
                  </div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="logo"
                      placeholder="Logo"
                    />
                  </div>
                  <div class="col-md-3">
                    <a href="javascript:void(0)" @click="agregarDetalle"
                      >Agregar</a
                    >
                  </div>
                </div>
                <br />
                <div class="table-responsive mb-3" v-if="detalles.length > 0">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Cantidad de Eeff</th>
                        <th>Capacidad pies</th>
                        <th>Logo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in detalles" :key="item.id">
                        <td>
                          <a
                            href="javascript:void(0)"
                            class="text-danger"
                            @click="eliminarDetalle(item)"
                            ><i class="ti-close"></i
                          ></a>
                        </td>
                        <td>{{ item?.numeroEeff }}</td>
                        <td>{{ item?.capacidadPies }}</td>
                        <td>{{ item?.logo }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <ErrorMessage :mensaje="mensaje" v-if="error" />
            </div>
            <br />
            <!-- <GMapMap
              :center="center"
              :zoom="8"
              map-type-id="roadmap"
              style="width: 50vw; height: 40vw"
              :click="true"
            >
              <GMapMarker
              :key="index"
            v-for="(m, index) in coordenadas"
            :position="m"
                :clickable="false"

              />
            </GMapMap> -->

            <br />

            <div class="row">
              <div class="col-lg-12">
                <button
                  type="submit"
                  id="form-submit"
                  class="btn btn-primary btn-general mr-2"
                >
                <i class="ti-save"></i> Guardar
                </button>
                <button type="button" class="btn btn-secondary btn-general-off" @click="cerrar">
                  <i class="ti-close"></i> Cerrar
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage.vue";

export default {
  name: "EventoForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      nombreCliente: "required",
      gerenciaGeneral: "required",
      gerenciaVentas: "required",
      //bdrs: "required",
      provincia: "required",
      poblacion: "required",
      sectorBarrio: "required",
      direccion: "required",
      telefono: "required|max:10",
      nombreEvento: "required",
      fechaEntrega: "required",
      fechaRetiro: "required",
      //tarima: "required",
      //kids: "required",
      //hielera: "required",
      //dumy: "required",
      //hl: "required",
      //cajas: "required",
      //proveedor: "required",
      horaEntrega: "required",
      horaRetiro: "required",
      responsable: "required",
      latitud: "required",
      longitud: "required",
      requierePermiso: "required",
      detallePermiso: "required",
      orientacionEquipo: "required",
    };
    return {
      formData: {
        codigoCliente: "",
        nombreCliente: "",
        gerenciaGeneral: "",
        gerenciaVentas: "",
        bdrs: "",
        provincia: "",
        poblacion: "",
        sectorBarrio: "",
        direccion: "",
        telefono: "",
        nombreEvento: "",
        fechaEntrega: "",
        fechaRetiro: "",
        tarima: "",
        kids: "",
        hielera: "",
        dumy: "",
        hl: "",
        cajas: "",
        detalles: [],
        proveedor: "",
        horaEntrega: "",
        horaRetiro: "",
        responsable: "",
        latitud: "",
        longitud: "",
        requierePermiso: "",
        detallePermiso: "",
        orientacionEquipo: "HORIZONTAL",
      },
      formValidation,
      pagina: 1,
      mensaje: "",
      id: null,
      error: false,
      numeroEeff: "",
      capacidadPies: "",
      logo: "",
      detalles: [],
      coordenadas: [],
      //center: { lat: -0.23, lng: -78.23 },
      map: null,
      geocoder: null,
      mapCenter: { lat: -0.23, lng: -78.23 },
      zoom: 7,
    };
  },
  created() {
    this.$store.commit("equipos/inicializar");
  },
  mounted() {
    this.consultarGerenciasGenerales();
    this.consultarProveedores();
    this.initMap();
    this.consultarHorariosEventos();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    habilitarFormulario() {
      this.mostrarFormulario = !this.mostrarFormulario;
    },
    async consultarGerenciasGenerales() {
      try {
        await this.$store.dispatch("rutero/consultarGerenciasGenerales");
      } catch (error) {
        console.log(error);
      }
    },
    async consultarGerenciasVentas() {
      try {
        await this.$store.dispatch("rutero/consultarGerenciasVentas", {
          gerenciaGeneral: this.formData.gerenciaGeneral.toString(),
        });
      } catch (error) {
        console.log(error);
      }
    },
    async consultarProveedores() {
      try {
        await this.$store.dispatch("proveedores/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta de proveedores");
      }
    },
    async consultarHorariosEventos() {
      try {
        await this.$store.dispatch("eventos/consultarHorario");
      } catch (error) {
        alert("No se pudo consultar los horarios");
      }
    },
    agregarDetalle() {
      if (!this.numeroEeff || !this.capacidadPies || !this.logo) {
        return;
      }
      const nuevoDetalle = {
        numeroEeff: this.numeroEeff,
        capacidadPies: this.capacidadPies,
        logo: this.logo,
      };
      this.detalles.push(nuevoDetalle);
      this.numeroEeff = "";
      this.capacidadPies = "";
      this.logo = "";
    },
    eliminarDetalle(detalle) {
      const detallesFiltrados = this.detalles?.filter(
        (item) => item !== detalle
      );
      this.detalles = detallesFiltrados;
    },
    async submit() {
      if(!this.detalles?.length){
        alert("Debe ingresar el detalle de los equipos");
        return;
      }
      this.error = false;
      this.loaderSpinner();
      this.formData.detalles = this.detalles;
      try {
        /* if (this.eventoSeleccionado) {
          await this.$store.dispatch("eventos/editarEvento", {
            id: this.eventoSeleccionado.id,
            data: this.formData,
          });
          await this.$store.commit("eventos/setEventoSeleccionado", null);
        } else {
          await this.$store.dispatch("eventos/crear", this.formData);
        } */
        await this.$store.dispatch("eventos/crear", this.formData);
        //this.$router.push({name: "Evento"});
        this.$router.go(-1);
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
    async consultarCliente() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("rutero/consultarRuteroXCliente", {
          codigoCliente: this.formData?.codigoCliente,
        });
        this.formData.telefono = this.cliente?.telefonoCliente;
        this.formData.nombreCliente = this.cliente?.nombresCliente;
        this.formData.gerenciaGeneral = this.cliente?.gerenciaGeneral ?? "";
        await this.consultarGerenciasVentas();
        this.formData.gerenciaVentas = this.cliente?.gerenciaVentas ?? "";
      } catch (error) {
        this.formData.telefono = "";
      } finally {
        this.loader.hide();
      }
    },
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.mapCenter,
        zoom: this.zoom,
      });
      this.geocoder = new google.maps.Geocoder();
    },
    buscarDireccion() {
      this.geocoder.geocode(
        { address: this.formData.direccion },
        (results, status) => {
          if (status === "OK") {
            this.map.setCenter(results[0].geometry.location);
            let marker = new google.maps.Marker({
              map: this.map,
              position: results[0].geometry.location,
              draggable: true,
            });
            this.formData.latitud = results[0].geometry.location.lat();
            this.formData.longitud = results[0].geometry.location.lng();
            this.zoom = 13;
            this.map.setZoom(15);
            google.maps.event.addListener(
              marker,
              "drag",
              function (event) {
                this.map.panTo(event.latLng);
                this.formData.latitud = event.latLng.lat();
                this.formData.longitud = event.latLng.lng();
              }.bind(this)
            );
          } else {
            alert(
              "No se pudo obtener la ubicación, intente con otra dirección"
            );
          }
        }
      );
    },
    async consultarHorario(origen) {
      if (origen === "ENTREGA") {
        this.formData.horaEntrega = null;
      } else {
        this.formData.horaRetiro = null;
      }
      let fechaBusqueda =
        origen === "ENTREGA"
          ? this.formData?.fechaEntrega
          : this.formData?.fechaRetiro;
      try {
        const resp = await this.$store.dispatch(
          "eventos/consultarEventoHorario",
          {
            fecha: fechaBusqueda,
            origen,
          }
        );
        if (resp?.error) {
          alert(resp.detalle[0]);
          /* if (origen === "ENTREGA") {
            this.formData.fechaEntrega = null;
            this.formData.horaEntrega = null;
          } else {
            this.formData.fechaRetiro = null;
            this.formData.horaRetiro = null;
          } */
        }
      } catch (error) {
        alert(error);
      }
    },
    verificarHorario(fecha) {
      if (fecha === "ENTREGA") {
        const fechaMin = new Date(
          this.formData?.fechaEntrega +
            " " +
            this.horarioEntrega?.horaInicio +
            ":00"
        );
        const fechaMax = new Date(
          this.formData?.fechaEntrega +
            " " +
            this.horarioEntrega?.horaFin +
            ":00"
        );
        if (
          !(
            new Date(
              this.formData.fechaEntrega + " " + this.formData.horaEntrega
            ) >= fechaMin &&
            new Date(
              this.formData.fechaEntrega + " " + this.formData.horaEntrega
            ) <= fechaMax
          )
        ) {
          alert("Hora fuera de rango permitido");
          //this.formData.horaEntrega = null;
        }
      } else {
        const fechaMin = new Date(
          this.formData?.fechaRetiro +
            " " +
            this.horarioRetiro?.horaInicio +
            ":00"
        );
        const fechaMax = new Date(
          this.formData?.fechaRetiro + " " + this.horarioRetiro?.horaFin + ":00"
        );
        if (
          !(
            new Date(
              this.formData.fechaRetiro + " " + this.formData.horaRetiro
            ) >= fechaMin &&
            new Date(
              this.formData.fechaRetiro + " " + this.formData.horaRetiro
            ) <= fechaMax
          )
        ) {
          alert("Hora fuera de rango permitido");
          //this.formData.horaRetiro = null;
        }
      }
    },
    async consultarHoarariosPermitidos() {
      /* try {
        const resp = await this.$store.dispatch(
          "eventos/consultarEventoHorario",
          {
            fecha: fechaBusqueda,
            origen,
          }
        );
        if (resp?.error) {
          alert(resp.detalle[0]);
          if (origen === "ENTREGA") {
            this.formData.fechaEntrega = null;
            this.formData.horaEntrega = null;
          } else {
            this.formData.fechaRetiro = null;
            this.formData.horaRetiro = null;
          }
        }
      } catch (error) {
        alert(error);
      } */
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    gerenciasGenerales() {
      return this.$store.getters["rutero/gerenciasGenerales"]
        ? this.$store.getters["rutero/gerenciasGenerales"]
        : [];
    },
    gerenciasVentas() {
      return this.$store.getters["rutero/gerenciasVentas"]
        ? this.$store.getters["rutero/gerenciasVentas"]
        : [];
    },
    eventoSeleccionado() {
      return this.$store.getters["eventos/eventoSeleccionado"]
        ? this.$store.getters["eventos/eventoSeleccionado"]
        : null;
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
        ? this.$store.getters["proveedores/proveedores"]
        : [];
    },
    cliente() {
      return this.$store.getters["rutero/cliente"]
        ? this.$store.getters["rutero/cliente"]
        : null;
    },
    horarioEntrega() {
      return this.$store.getters["eventos/horarioEntrega"]
        ? this.$store.getters["eventos/horarioEntrega"]
        : null;
    },
    horarioRetiro() {
      return this.$store.getters["eventos/horarioRetiro"]
        ? this.$store.getters["eventos/horarioRetiro"]
        : null;
    },
    horario() {
      return this.$store.getters["eventos/horario"]
        ? this.$store.getters["eventos/horario"]
        : null;
    },
  },
};
</script>
<style>
.no-padding {
  padding: 0;
  margin: 0;
}

.radio-margin {
  margin-right: 15px;
}
</style>
