import axios from "axios";

const state = {
  usuarioBodega: null,
};

const getters = {
  usuarioBodega: (state) => state.usuarioBodega,
};

const mutations = {
  setUsuarioBodega: (state, usuarioBodega) => {
    state.usuarioBodega = usuarioBodega;
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      const res = await axios.get(`/v1/usuario-bodega/vehiculo`);
      commit("setUsuarioBodega", res?.data?.result);
    } catch (error) {
      commit("setUsuarioBodega", []);
      return error;
    }
  },
  async consultarBodegaTecnico({ commit }) {
    try {
      const res = await axios.get(`/v1/usuario-bodega/tecnico`);
      commit("setUsuarioBodega", res?.data?.result);
    } catch (error) {
      commit("setUsuarioBodega", []);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
