<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              {{ bodegaGerenciaSeleccionada ? "Editar" : "Agregar" }}
            </h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                  @submit="submit"
                  :validation-schema="formValidation"
                  v-slot="{ errors }"
                >
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="gerenciaVentas">Proveedor *</label>
                        <fieldset>
                          <Field
                            name="proveedor"
                            as="select"
                            class="form-control"
                            v-model="formData.proveedor"
                          >
                            <option value="" disabled>
                              Seleccione un proveedor
                            </option>
                            <option
                              v-for="proveedor in proveedores"
                              :key="proveedor.id"
                              :value="proveedor.id"
                            >
                              {{ proveedor.nombreProveedor }}
                            </option>
                          </Field>
                          <MensajeValidacion :mensaje="errors.proveedor" />
                        </fieldset>
                      </div>
                    </div>

                    <ErrorMessage :mensaje="mensaje" v-if="error" />
                  </div> -->

                  <div class="row">
                    <div class="col-md-12">
                      <h6>Bodega: {{ bodegaSeleccionada?.nombre }}</h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gerenciaGeneral">Gerencia general *</label>
                        <fieldset>
                          <Field
                            name="gerenciaGeneral"
                            as="select"
                            class="form-control"
                            v-model="formData.gerenciaGeneral"
                            @change="consultarGerenciasVentas"
                          >
                            <option value="" disabled>
                              Seleccione una gerencia
                            </option>
                            <option
                              v-for="gerencia in gerenciasGenerales"
                              :key="gerencia.id"
                              :value="gerencia.descripcion"
                            >
                              {{ gerencia.descripcion }}
                            </option>
                          </Field>
                          <MensajeValidacion :mensaje="errors.gerenciaGeneral" />
                        </fieldset>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Gerencia Ventas *</label>
                        <fieldset>
                          <Field
                            as="select"
                            name="gerenciaVentas"
                            class="form-control"
                            placeholder="Gerencia Ventas"
                            v-model="formData.gerenciaVentas"
                          >
                            <option value="" disabled>
                              Seleccione una gerencia
                            </option>
                            <option
                              v-for="item in gerenciasVentas"
                              :key="item.descripcion"
                              :value="item.descripcion"
                            >
                              {{ item.descripcion }}
                            </option>
                          </Field>
                          <MensajeValidacion :mensaje="errors.gerenciaVentas" />
                        </fieldset>
                      </div>
                    </div>
                  </div>

                  <ErrorMessage :mensaje="mensaje" v-if="error" />
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="submit"
                        id="form-submit"
                        class="btn btn-primary btn-general mr-2"
                      >
                      <i class="ti-save"></i> Guardar
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary btn-general-off"
                        @click="cerrar"
                      >
                      <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";

export default {
  name: "BodegaGerenciaForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      gerenciaGeneral: "required",
    };
    return {
      formData: {
        gerenciaGeneral: "",
        gerenciaVentas: "",
      },
      error: false,
      mensaje: "",
      formValidation,
    };
  },
  mounted() {
    if (this.bodegaGerenciaSeleccionada) {
      this.formData = { ...this.bodegaGerenciaSeleccionada };
    }
    this.consultarCatalogos();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarCatalogos() {
      
      this.loaderSpinner();
      try {
        await this.$store.dispatch("rutero/consultarGerenciasGenerales");
        if (this.bodegaGerenciaSeleccionada) {
          this.formData.gerenciaGeneral =
            this.bodegaGerenciaSeleccionada?.gerenciaGeneral ?? "";
          await this.consultarGerenciasVentas();
          this.formData.gerenciaVentas =
            this.bodegaGerenciaSeleccionada?.gerenciaVentas ?? "";
        }
      } catch (error) {
        alert(error);
      } finally {
        this.loader.hide();
      }
    },
    async submit() {
      this.error = false;
      this.loaderSpinner();
      try {
        const data = {
          ...this.formData,
          bodega: this.bodegaSeleccionada.id,
        };
        let respuesta = null;
        if (this.bodegaGerenciaSeleccionada) {
          respuesta = await this.$store.dispatch("bodegaGerencia/editar", {
            id: this.bodegaGerenciaSeleccionada.id,
            data,
          });
          //await this.$store.commit("bodegaGerencia/setBodegaGerenciaSeleccionada", null);
        } else {
          respuesta = await this.$store.dispatch("bodegaGerencia/crear", data);
        }

        if(respuesta?.error){
          alert(respuesta?.detalle);
          return;
        }
        this.$router.push({ name: "BodegaGerencia" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
        //const errores = error?.response?.data?.errors ? error?.response?.data?.errors[0] : "No se pudo guardar la información"
        //alert(errores)
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
    async consultarGerenciasVentas() {
      try {
        await this.$store.dispatch("rutero/consultarGerenciasVentas", {
          gerenciaGeneral: this.formData.gerenciaGeneral.toString(),
        });
      } catch (error) {
        alert(error);
      }
    },
  },
  computed: {
    bodegaSeleccionada() {
      return this.$store.getters["bodegas/bodegaSeleccionada"]
        ? this.$store.getters["bodegas/bodegaSeleccionada"]
        : null;
    },
    proveedorSeleccionado() {
      return this.$store.getters["proveedores/proveedorSeleccionado"]
        ? this.$store.getters["proveedores/proveedorSeleccionado"]
        : null;
    },
    gerenciasGenerales() {
      return this.$store.getters["rutero/gerenciasGenerales"]
        ? this.$store.getters["rutero/gerenciasGenerales"]
        : [];
    },
    gerenciasVentas() {
      return this.$store.getters["rutero/gerenciasVentas"]
        ? this.$store.getters["rutero/gerenciasVentas"]
        : [];
    },
    bodegaGerenciaSeleccionada() {
      return this.$store.getters["bodegaGerencia/bodegaGerenciaSeleccionada"]
        ? this.$store.getters["bodegaGerencia/bodegaGerenciaSeleccionada"]
        : null;
    },
  },
};
</script>
