<template>
  <router-view/>
  <button
      type="button"
      v-show="false"
      id="modalButton"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#modalMessages"
      data-backdrop="static"
      data-keyboard="false"
  >
    Modal messages
  </button>
</template>

<style>
@import "assets/css/feather.css";
@import "assets/css/themify-icons.css";
@import "assets/css/vendor.bundle.base.css";
@import "assets/css/dataTables.bootstrap4.css";
@import "assets/css/select.dataTables.min.css";
@import "assets/css/style.css";
@import "assets/css/custom.css";
</style>

<script>
export default {
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    window.vueComponent = this;
    this.loadGoogleMapsScript();
  },
  methods: {
    loadGoogleMapsScript() {
      const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
      if (!document.getElementById('google-maps-script')) {
        const script = document.createElement('script');
        script.id = 'google-maps-script';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      } else {
        this.initMap();
      }
    },
    initMap() {
      const mapElement = document.getElementById('map');
      if (mapElement) {
        this.map = new window.google.maps.Map(mapElement, {
          center: {lat: -0.23, lng: 78.23},
          zoom: 8
        });
      } else {
        console.error("No se encontró el elemento con id 'map'");
      }
    }

  }
};

window.initMap = function () {
  const vm = window.vueComponent;
  if (vm) {
    vm.initMap();
  } else {
    console.error("No se pudo acceder al componente Vue");
  }
};
</script>
