<template>
  <div
    class="modal fade"
    id="modalEventos"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalEventosLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalEventosLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <ul>
                  <li v-for="(item, $index) in listaEquipos" :key="$index">
                    <strong>Placa:</strong>
                    {{ item.placa }}
                    <strong>Serie:</strong>
                    {{ item.serie }}
                    <strong>Bodega:</strong>
                    {{ item.bodegaDescripcion }}
                    <strong>Modelo:</strong>
                    {{ item.modelo }}
                    <strong>Capacidad (pies):</strong>
                    {{ item.capacidadPies }}
                    <strong>Logo:</strong>
                    {{ item.logo }}
                    <div
                      class="badge"
                      :class="
                        item.estadoEquipo === 'DISPONIBLE'
                          ? 'badge-success'
                          : 'badge-secondary'
                      "
                    >
                      {{ item.estadoEquipo }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="form-check">
              <input
                class="form-check-input ml-1"
                type="radio"
                id="ejecutado"
                value="EJECUTADO"
                v-model="estadoEvento"
              />
              <label class="form-check-label" for="ejecutado">
                EJECUTADO
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input ml-1"
                type="radio"
                id="fallido"
                value="FALLIDO"
                v-model="estadoEvento"
              />
              <label class="form-check-label" for="fallido"> FALLIDO </label>
            </div>

            <div class="form-group">
                <label for="exampleFormControlTextarea1">Observación</label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="observacion"
                  required
                ></textarea>
              </div>
            
            <div>
              <div class="mt-3 mb-3">
                <label for="formFile" class="form-label">Cargar archivo</label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  ref="file"
                  @change="uploadFile"
                />
              </div>
              <div class="mb-3" v-if="archivosArray.length > 0">
                <label for="formFile" class="form-label"
                  >Lista de archivos</label
                >
                <ul>
                  <li v-for="(archivo, $index) in archivosArray" :key="$index">
                    {{ archivo?.name }}
                    <a
                      href="javascript:void(0)"
                      class="text-danger"
                      @click="eliminarArchivo(archivo)"
                      ><i class="ti-close"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="procesarEvento()"
              :disabled="!archivosArray.length || observacion.length === 0"
            >
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              v-show="false"
              id="btProcesar"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="
                procesar({
                  archivosArray,
                  estadoEvento,
                  observacion,
                })
              "
            >
              Procesar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titulo: String,
    tipo: String,
    listaEquipos: [Object],
    procesar: { type: Function },
  },
  data() {
    return {
      archivosArray: [],
      estadoEvento: "EJECUTADO",
      observacion: "",
    };
  },
  methods: {
    uploadFile() {
      this.archivosArray.push(this?.$refs?.file?.files[0]);
    },
    eliminarArchivo(archivo) {
      const archivosFiltrados = this.archivosArray?.filter(
        (item) => item !== archivo
      );
      this.archivosArray = archivosFiltrados;
    },
    procesarEvento() {
      document.getElementById("btProcesar").click();
      this.archivosArray = [];
    }
  },
};
</script>
