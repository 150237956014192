<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <GMapMap
          :center="center"
          :zoom="8"
          map-type-id="roadmap"
          style="width: 70vw; height: 40vw"
        >
          <GMapMarker
            :key="index"
            v-for="(m, index) in coordenadas"
            :position="m"
            :clickable="true"
            @click="seleccionarPunto(m)"
            :icon="`https://maps.google.com/mapfiles/ms/icons/${
              m?.requerimiento?.solicitudProveedor?.solicitud?.diasTranscurridosLabolables < 3
                ? 'green'
                : m?.requerimiento?.solicitudProveedor?.solicitud?.diasTranscurridosLabolables > 5
                ? 'red'
                : 'yellow'
            }-dot.png`"
            :title="`Cliente: ${m.requerimiento.solicitudProveedor.solicitud.codigoCliente.toString()} Solicitud: ${m.requerimiento.solicitudProveedor.solicitud.id.toString()}`"
          />
        </GMapMap>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "MapaTransporte",
  components: {  },
  props: {
    cerrar: { type: Function },
    seleccionarPunto: { type: Function },
    coordenadas: Array,
  },
  data() {
    return {
      center: { lat: -0.23, lng: -78.23 },
    };
  },
  mounted() {
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    }
  },
};
</script>
