import axios from "axios";
import store from "../index";

const state = {
    waitingList: [],
    totalPaginas:0,
    totalRegistros:0

};

const getters = {
    waitingList: (state) => state.waitingList,
    totalPaginas: (state) => state.totalPaginas,
    totalRegistros: (state) => state.totalRegistros,
};

const mutations = {
    setWaitingList: (state, waitingList) => {
        state.waitingList = waitingList;
    },
    setTotalPaginas: (state, totalPaginas) => {
        state.totalPaginas = totalPaginas;
    },
    setTotalRegistros: (state, totalRegistros) => {
        state.totalRegistros = totalRegistros;
    },
};

const actions = {

    async consultarWaitingListPaginado({commit}, data) {
        try {

            let res = await axios.get(`/v1/waitinglist/pagina/${data.pagina}`);
            commit("setWaitingList", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async consultarWaitingListTotalPaginas() {
        try {
            const resp = await axios.get(`/v1/waitinglist/total`);
            store.dispatch("pagination/updateTotales", {
                totalPaginas: resp?.data?.totalPaginas,
                totalRegistros: resp?.data?.totalRegistros
            })
        } catch (error) {
            return error;
        }
    },
    async cargarArchivo(_, data) {
        try {
            const headers = {'Content-Type': 'multipart/form-data'};
            const resp = await axios.post(`/v1/waitinglist/upload`, data, {headers});
            return {'status': 'success', 'data': resp?.data?.result};
        } catch (error) {
            return {'status': 'error', 'error': error?.response?.data};
        }
    },
    async aprobarWaitingList(_, data){
        try {
             await axios.post(`v1/waitinglist/aprobacion`, data);
            } catch (error) {
                return {'status': 'error', 'error': error?.response?.data};
            }


    },
    async consultarWaitingListXFiltros({ commit }, data) {
        try {
            console.log(data);
            let resp = await axios.post(`/v1/waitinglist/filtros`, data);
            commit("setWaitingList", resp?.data?.result?.waitingList);
            commit("setTotalPaginas", resp?.data?.result?.paginacion?.totalPaginas || 1);
            commit("setTotalRegistros", resp?.data?.result?.paginacion?.totalRegistros || 0);
            store.dispatch("pagination/updateTotales", {
                totalPaginas: resp?.data?.result?.paginacion?.totalPaginas,
                totalRegistros: resp?.data?.result?.paginacion?.totalRegistros
            })
        } catch (error) {
            return error;
        }
    },
    async exportar({commit}) {
        try {
            let resp = await axios.post(`/v1/exportar/waitinglist`, {responseType: "arraybuffer"});

            if (resp?.data) {
                commit("setMensaje", "Descarga exitosa");
                return resp?.data
            } else {
                commit("setMensaje", "Descarga fallida");
                return null;
            }
        } catch (error) {
            return error;
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
