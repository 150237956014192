<template>
  <div
    class="col-md-12 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Mi Perfil
            </h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                
                <div v-if="!isEditing">
                  <div class="text-center" v-if="foto">
                    <img
                      class="profile"
                      :src="`data:image/png;base64,${foto}`"
                      height="150"
                      width="150"
                    />
                  </div>
                  <div class="text-center" v-else>
                    <img
                      class="profile"
                      src="../../assets/images/avatar.png"
                      height="150"
                      width="150"
                    />
                  </div>
                </div>
                <div class="row mb-4" v-else>
                  <div class="col-md-12 text-center">
                    <cropper
                      class="cropper"
                      :src="image.src"
                      :stencil-props="{
                        aspectRatio: 12 / 12,
                      }"
                      ref="cropper"
                      @change="onChange"
                      :canvas="{
                        height: 256,
                        width: 256,
                      }"
                    />
                    <button class="btn btn-primary btn-general mt-3" @click="uploadImage">
                      Guardar
                    </button>
                  </div>
                </div>
                
                <input
                  type="file"
                  id="file"
                  ref="file"
                  v-show="verFileInput"
                  @change="loadImage($event)"
                  accept=".jpg"
                />
                <div class="mb-4">
                  <div class="text-center">
                    <small class="txt-celeste"
                      >Usar la camiseta con los logos de CN y Refrigeración
                      Industrial</small
                    >
                  </div>
                  <div class="text-center">
                    <small class="txt-celeste">Portar la credencial</small>
                  </div>
                  <div class="text-center mb-4">
                    <small class="txt-celeste">Fondo blanco</small>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-primary btn-general" @click="cargarFoto">
                      <span class="icon-photo"></span> Cambiar foto
                    </button>
                  </div>
                </div>

                <form>
                  <div class="row mb-4">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="clave">Número de celular</label>
                        <fieldset>
                          <input
                            type="text"
                            name="celular"
                            id="celular"
                            class="form-control"
                            placeholder="Ingrese su número Celular"
                            v-model="formData.celular"
                            required
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="clave">Número de Cédula</label>
                        <fieldset>
                          <input
                            type="text"
                            name="cedula"
                            id="cedula"
                            class="form-control"
                            placeholder="Ingrese su número de Cédula"
                            v-model="formData.cedula"
                            required
                          />
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="button"
                        id="form-submit"
                        class="btn btn-general btn-primary mr-2"
                        @click="guardarPerfil"
                      >
                      <span class="icon-save"></span> Guardar
                      </button>
                      <button
                        type="button"
                        class="btn btn-general-off btn-secondary"
                        @click="cerrar"
                      >
                      <span class="icon-close"></span> Cerrar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}
export default {
  name: "Perfil",
  components: {
    Cropper,
  },
  data() {
    return {
      formData: {
        cedula: "",
        celular: "",
      },
      verFileInput: false,
      archivo: null,
      img: "",
      result: {
        coordinates: null,
        image: null,
      },
      image: {
        src: null,
        type: null,
      },
      isEditing: false
    };
  },
  mounted() {
    this.consultarPerfil();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarPerfil() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("perfil/consultarPerfil");
        this.formData = {
          cedula: this.perfil?.cedula ?? null,
          celular: this.perfil?.celular ?? null,
        };
        await this.$store.dispatch("perfil/consultarFoto");
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async guardarPerfil() {
      const { celular, cedula } = this.formData;
      if (!celular || !cedula) {
        return;
      }
      try {
        this.loaderSpinner();
        const data = {
          celular,
          cedula,
        };
        await this.$store.dispatch("perfil/actualizarPerfil", data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    cargarFoto() {
      document.getElementById("file").click();
      this.isEditing = true;
    },
    async uploadFile() {
      const fileInput = document.getElementById("file");
      const filePath = fileInput.value;
      var allowedExtensions = /(\.jpg)$/i;

      if (!allowedExtensions.exec(filePath)) {
        alert("Solo se permite imágenes jpg");
        fileInput.value = "";
        return false;
      }
      this.loaderSpinner();
      try {
        const archivo = this?.$refs?.file?.files[0];
        const formData = new FormData();
        formData.append("fotoPerfil", archivo);
        await this.$store.dispatch("perfil/actualizarFoto", {
          data: formData,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      };
    },
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult();

      if (canvas) {
        const formData = new FormData();
        canvas.toBlob((blob) => {
          formData.append("file", blob);
          this.$store.dispatch("perfil/actualizarFoto", {
            data: formData,
          });
        }, "image/jpeg");
        this.isEditing = false;
      }
    },
    seleccionarImagen() {
      this.img = this?.$refs?.file?.files[0].name;
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);
        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }
        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Read image as base64 and set it as src:
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },
  },
  computed: {
    perfil() {
      return this.$store.getters["perfil/perfil"]
        ? this.$store.getters["perfil/perfil"]
        : null;
    },
    foto() {
      return this.$store.getters["perfil/foto"]
        ? this.$store.getters["perfil/foto"]
        : null;
    },
  },
};
</script>
<style scoped>
.profile {
  border-radius: 50%;
}
.foto {
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: black !important;
  padding: 2px;
  border-radius: 50%;
}

</style>
