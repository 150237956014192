<template>
  <div
    class="modal fade"
    id="modalListaEquipos"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalListaEquiposLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalListaEquiposLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <ul>
                  <li v-for="(item, $index) in listaEquipos" :key="$index">
                    <strong>Placa:</strong>
                    {{ item.placa }}
                    <strong>Serie:</strong>
                    {{ item.serie }}
                    <strong>Bodega:</strong>
                    {{ item.bodegaDescripcion }}
                    <strong>Modelo:</strong>
                    {{ item.modelo }}
                    <strong>Capacidad (pies):</strong>
                    {{ item.capacidadPies }}
                    <strong>Logo:</strong>
                    {{ item.logo }}
                    <div
                    class="badge"
                    :class="
                      item.estadoEquipo === 'DISPONIBLE'
                        ? 'badge-success'
                        : 'badge-secondary'
                    "
                  >
                    {{ item.estadoEquipo }}
                  </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titulo: String,
    listaEquipos: [Object],
  },
};
</script>
