<template>
  <div class="row">
    <div
      class="form-group col-md-12"
      v-for="adjunto in adjuntos"
      :key="adjunto?.id"
    >
      <fieldset v-if="adjunto.tipo === 'FILE'">
        <label :for="adjunto?.id" class="form-label">{{
            adjunto?.descripcion
          }}</label>
          <input
            class="form-control"
            type="file"
            :id="adjunto.id"
            ref="file"
            @change="uploadFile"
          />
      </fieldset>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tipoSolicitud: String,
    uploadFile: { type: Function },
  },
  data() {
    return {
      
    };
  },
  created(){},
  mounted() {
    this.loadAdjuntos();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async loadAdjuntos() {
      try {
        await this.$store.dispatch("atencionAdjunto/consultar", {
          tipoSolicitud: this.tipoSolicitud,
        });
      } catch (error) {
        alert("No se pudo consultar los adjuntos");
      }
    },
    /* handleSelectChange(event, filtro) {
      const selectedOption = event.target.value;
      if (selectedOption === "TODOS") {
        const conditionsFiltered = this.condiciones?.filter(
          (item) => item?.field !== filtro?.nombreBase
        );
        this.condiciones = conditionsFiltered;
        return;
      }
      const condicion = {
        field: filtro.nombreBase,
        comparator: filtro.comparator,
        value: selectedOption,
      };
      //Buscar si existe el filtro en condiciones
      const filterIndex = this.condiciones?.findIndex(
        (item) => item?.field === filtro?.nombreBase
      );

      if (filterIndex !== -1) {
        this.condiciones?.splice(filterIndex, 1, condicion);
      } else {
        this.condiciones = [...this.condiciones, condicion];
      }
    },
    updateText(event, filtro) {
      const selectedOption = event.target.value;
      if (!selectedOption?.length) {
        const conditionsFiltered = this.condiciones?.filter(
          (item) => item?.field !== filtro?.nombreBase
        );
        this.condiciones = conditionsFiltered;
        return;
      }
      const condicion = {
        field: filtro.nombreBase,
        comparator: filtro.comparator,
        value: selectedOption,
      };
      //Buscar si existe el filtro en condiciones
      const filterIndex = this.condiciones?.findIndex(
        (item) => item?.field === filtro?.nombreBase
      );

      if (filterIndex !== -1) {
        this.condiciones?.splice(filterIndex, 1, condicion);
      } else {
        this.condiciones = [...this.condiciones, condicion];
      }
    }, */
  },
  computed: {
    adjuntos() {
      return this.$store.getters["atencionAdjunto/atencionAdjuntos"]
        ? this.$store.getters["atencionAdjunto/atencionAdjuntos"]
        : [];
    },
  },
};
</script>
