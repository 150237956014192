import axios from "axios";
import router from "../../router/index";
import * as msal from "@azure/msal-browser";
import jwt_decode from "jwt-decode";

const state = {
  token: null,
  rol: null,
  user: null,
  cambiarClave: false
};

const getters = {
  isLogged: (state) => state.token,
  getPerfil: (state) => state.rol,
  cambiarClave: (state) => state.cambiarClave,
};

const msalConfig = {
  auth: {
      clientId: process.env.VUE_APP_AZURE_CLIENTID,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_TENANTID}/`,
      redirectUri: process.env.VUE_APP_AZURE_AUTH_REDIRECT_URI
  },
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
  }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const mutations = {
  setLoginInfo: (state, data) => {
    state.token = data.result.token;
    state.rol = data.result.rol;
    state.cambiarClave = data.result.cambiarClave;
  },
  setLogin: (state, data) => {
    state.token = data.token;
    state.rol = data.role;
  },
  setCambiarClave: (state, data) => {
    state.cambiarClave = data.cambiarClave;
  },
  clearAuthData: (state) => {
    state.token = null;
    state.rol = null;
  },
};

const actions = {
  async login({ commit }, data) {
    try {
      const resp = await axios.post("/v1/login", {
        email: data.email,
        clave: data.clave,
      });
      commit("setLoginInfo", resp.data);
      return resp;
    } catch (error) {
      window.sessionStorage.removeItem("_token_eeff");
      commit("clearAuthData");
      return {
          message: error?.response?.data?.errors[0],
          error: true
      };
    }
  },

  async otp({ commit }, data) {
    try {
      const resp = await axios.post("/v1/otp", {
        email: data.email,
        codigo: Number(data.otp),
      });
      window.sessionStorage.setItem("_token_eeff", resp.data.result.token);
      commit("setLoginInfo", resp.data);
      return resp;
    } catch (error) {
      if(error?.response?.status === 400){
        this.logout();
      }
      window.sessionStorage.removeItem("_token_eeff");
      commit("clearAuthData");
      return {
          message: error?.response?.data?.errors[0],
          error: true
      };
    }
  },

  async otpForwarding({ commit }, data) {
    try {
      const resp = await axios.post("/v1/otpForwarding", {
        email: data.email
      });
      commit("setLoginInfo", resp.data);
      return resp;
    } catch (error) {
      window.sessionStorage.removeItem("_token_eeff");
      commit("clearAuthData");
      return {
          message: error?.response?.data?.errors[0],
          error: true
      };
    }
  },
  
  async sso({ commit }) {
    try {
      const loginRequest = {
          scopes: [process.env.VUE_APP_AZURE_SCOPES]
      };  

      const loginPopupResponse = await msalInstance.loginPopup(loginRequest)
      const ssoResponse = await axios.post("/v1/sso", {
        token: loginPopupResponse.accessToken
      },{
        headers: {
          'Authorization': `Bearer ${loginPopupResponse.accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      window.sessionStorage.setItem("_token_eeff", ssoResponse.data.result.token);
      commit("setLoginInfo", ssoResponse.data);
      return ssoResponse;
    } catch (error) {
      window.sessionStorage.removeItem("_token_eeff");
      commit("clearAuthData");
      return {
          message: error?.response?.data?.errors[0],
          error: true
      };
    }
  },
  logout: ({ commit }) => {
    window.sessionStorage.removeItem("_token_eeff");
    commit("clearAuthData");
    router.push({ name: "Login" });
  },

  initializeAuth({ commit }) {
    const token = window.sessionStorage.getItem("_token_eeff");
    if (token) {
      const decodedToken = jwt_decode(token);
      commit("setLogin", {
        token: token,
        role: decodedToken.rol,
      });
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
