<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Objetivos Visita</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>
        
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th>Cliente</th>
                  <th>Fecha</th>
                  <th>Usuario</th>
                  <th>Respuesta</th>
                  <th>Pagado</th>
                  <th>Vencido</th>
                  <th>Fecha proceso pago</th>
                  <th>Ticket creado</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="item in objetivos" :key="item.id">
                  <td>
                  </td>
                  <td>{{ item?.codigoCliente }}</td>
                  <td>{{ item?.fecha }}</td>
                  <td>{{ item?.usuario }}</td>
                  <td>{{ item?.respuesta }}</td>
                  <td>{{ item?.pagado }}</td>
                  <td>{{ item?.vencido }}</td>
                  <td>{{ item?.fechaProcesoPagado }}</td>
                  <td>{{ item.ticketCreado }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionObjetivosVisita",
  data() {
    return {};
  },
  created() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("objetivosVisita/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    objetivos() {
      return this.$store.getters["objetivosVisita/objetivos"]
        ? this.$store.getters["objetivosVisita/objetivos"]
        : [];
    },
  },
};
</script>
<style></style>
