<template>
  <GestionEquiposSupervisor />
</template>
<script>
import GestionEquiposSupervisor from "./components/GestionEquiposSupervisor.vue";
export default {
  name: "EquiposSupervisor",
  components: { GestionEquiposSupervisor }
};
</script>
