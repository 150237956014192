<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Requerimientos Asignados</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">

            <div class="col-md-6">
              <p class="lh-m mb-0">
                <span class="card-title">Total registros: </span><span
                  class="txt-celeste font-weight-bold fs-l">{{ totalRegistros }}</span>
              </p>

            </div>

            <div class="col-md-6">

              <div class="dropdown">
                <button
                    class="btn btn-primary btn-general dropdown-toggle float-right mr-2"
                    type="button"
                    id="tipo"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <i class="ti-filter"></i> Filtrar {{ tipoFiltro }}
                </button>
                <div class="dropdown-menu" aria-labelledby="tipo">
                  <a class="dropdown-item" href="#" @click="filtrar('TODOS')"
                  >TODOS</a
                  >
                  <a
                      class="dropdown-item"
                      href="#"
                      @click="filtrar('MANTENIMIENTO')"
                  >MANTENIMIENTO</a
                  >
                  <a class="dropdown-item" href="#" @click="filtrar('SOLICITUD')"
                  >SOLICITUD</a
                  >
                  <a class="dropdown-item" href="#" @click="filtrar('RETIRO')"
                  >RETIRO</a
                  >
                  <a class="dropdown-item" href="#" @click="filtrar('CAMBIO')"
                  >CAMBIO</a
                  >
                </div>
              </div>

              <button @click="abrirMapa" class="btn btn-primary btn-general  float-right mr-2">
                <span class="icon-map"></span> Mapa
              </button>

            </div>

          </div>
        </div>

        <div class="row mb-4">
          <div
              class="modal"
              tabindex="-1"
              :style="displayModal ? 'display: block;' : 'display: none;'"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Cargar archivo</h5>
                </div>
                <div class="modal-body">
                  <p>Seleccione el archivo</p>
                  <div class="input-group mb-3">
                    <input
                        type="file"
                        @change="uploadFile"
                        ref="file"
                        class="form-control"
                    />
                  </div>
                  <div class="alert alert-danger" role="alert" v-if="error">
                    No se pudo cargar el archivo
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-secondary btn-general-off"
                      data-bs-dismiss="modal"
                      @click="cerrarModalArchivo"
                  >
                    <i class="ti-close"></i> Cerrar
                  </button>
                  <button
                      type="button"
                      class="btn btn-primary btn-general"
                      @click="cargarArchivo"
                      :disabled="archivo ? false : true"
                  >
                    <i class="ti-check-box"></i> Procesar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="white-content-table mb-4">


          <div class="col-md-6">

            <div
                class="row"
            >
              <div class="col-md-12">
                <div class="form-check-inline">
                  <input
                      class="form-check-input"
                      type="radio"
                      id="codigoCliente"
                      value="CLIENTE"
                      v-model="tipoFiltroLocal"
                  />
                  <label class="form-check-label radio-margin" for="codigoCliente">
                    Cliente
                  </label>

                  <input
                      class="form-check-input"
                      type="radio"
                      id="ticket"
                      value="TICKET"
                      v-model="tipoFiltroLocal"
                  />
                  <label class="form-check-label radio-margin" for="ticket">
                    Ticket
                  </label>
                  <div class="form-check-inline">
                    <input
                        class="form-check-input"
                        type="radio"
                        id="todos"
                        value="TODOS"
                        v-model="tipoFiltroLocal"
                        @click="consultarTodos()"
                    />
                    <label class="form-check-label radio-margin" for="todos">
                      Todos
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
                class="input-group col-md-12 mt-2 no-padding mb-2 mb-md-0"

            >
              <input
                  type="text"
                  class="form-control"
                  v-model="valorBusqueda"
                  aria-label="Text input with segmented dropdown button"
              />
              <div class="input-group-append">
                <button
                    type="button"
                    class="btn btn-outline-secondary btn-general btn-buscar"
                    @click="buscarEquipo"
                >
                  <span class="icon-search"></span> Buscar
                </button>
              </div>
            </div>

          </div>


          <form name="f1" id="formElement">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>Ticket</th>
                  <th>Bodega</th>
                  <th>Cliente</th>
                  <th>Tipo</th>
                  <th>Fecha solicitud</th>
                  <th>Fecha asignacion</th>
                  <th>Días Transcurridos</th>
                  <th>Gerencia general</th>
                  <th>Gerencia ventas</th>
                  <th>ECB</th>
                  <th>Nombre</th>
                  <th>Establecimiento</th>
                  <th>Dirección</th>
                  <th>Tipo de caso</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in solicitudesFiltradas" :key="item.id"
                    :class="item?.tipoCaso === 'COOL INVENTORY BOT' ? 'bgFila' : ''">
                  <td>
                    <a href="javascript:void(0)" @click="procesar(item)"
                    ><i class="ti-angle-right"></i
                    ></a>
                  </td>
                  <td>
                    {{ item.solicitud.id }}
                    <a
                        v-if="item.solicitud.solicitudPrincipal"
                        href="javascript:void(0)"
                        @click="verSolicitudRelacionada(item.solicitud)"
                    ><i class="ti-files"></i
                    ></a>
                  </td>
                  <td>{{ item.solicitud.numeroTicket }}</td>
                  <td>{{ item.bodega.nombre }}</td>
                  <td>{{ item.solicitud.codigoCliente }}</td>
                  <td>
                    <strong>{{ item.solicitud.tipoSolicitud }}</strong>
                  </td>
                  <td>{{ item.solicitud.fechaSolicitud }}</td>
                  <td>{{ item.fechaAsignacion }}</td>
                  <td>
                    <div
                        class="badge"
                        :class="
                          item.diasTranscurridosLaborablesAsignacion < 3
                            ? 'badge-success'
                            : item.diasTranscurridosLaborablesAsignacion > 5
                            ? 'badge-danger'
                            : 'badge-warning'
                        "
                    >
                      {{ item.diasTranscurridosLaborablesAsignacion }} día(s)
                    </div>
                  </td>

                  <td>{{ item.solicitud.gerenciaGeneral }}</td>
                  <td>{{ item.solicitud.gerenciaVentas }}</td>
                  <td>{{ item.solicitud.ecb }}</td>
                  <td>{{ item.solicitud.nombre }}</td>
                  <td>{{ item.solicitud.nombreEstablecimiento }}</td>
                  <td>{{ item.solicitud.direccion }}</td>
                  <td>{{ item.solicitud.tipoCaso }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <Modal :method="procesarSeleccionados" :mensaje="mensaje"/>
        <ModalMessages :mensaje="mensajeProceso" :titulo="titulo" :method="cerrarMensajeCartera"/>
        <Alerta
            v-if="mostrar"
            titulo=""
            mensaje="Proceso realizado exitosamente"
        />

      </div>
    </div>
  </div>
</template>
<script>
import Alerta from "../../../components/Alerta.vue";
import Modal from "../../../components/Modal.vue";
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "GestionSolicitudesProveedor",
  components: {Alerta, Modal, ModalMessages},
  data() {
    return {
      archivo: null,
      displayModal: false,
      error: false,
      idSeleccionadosProxy: [],
      idSeleccionados: [],
      mostrar: false,
      displayConfirmacion: false,
      mensaje: "",
      solicitudesFiltradas: [],
      tipoFiltro: "",
      mensajeProceso: [],
      valorBusqueda: "",
      tipoFiltroLocal: "",
      titulo: "Solicitud relacionada",
    };
  },
  mounted() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("solicitudesProveedor/consultar");
        this.solicitudesFiltradas = this.solicitudes;
        this.configurarCoordenadas(this.solicitudes);
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    configurarCoordenadas(solicitudes) {
      let coordenadas = [];
      solicitudes.map((item) =>
          coordenadas.push({
            lat: item.solicitud.latitud,
            lng: item.solicitud.longitud,
            id: item.id,
            requerimiento: item,
          })
      );
      this.$store.commit("solicitudesProveedor/setCoordenadas", coordenadas);
    },
    abrirMapa() {
      this.$store.commit("solicitudesProveedor/inicializarPuntosSeleccionados");
      this.$router.push({
        name: "Mapa",
        params: {
          tipoFiltro: this.tipoFiltro,
        },
      });
    },
    async procesar(item) {
      await this.$store.commit(
          "solicitudesProveedor/setSolicitudProveedorSeleccionada",
          item
      );
      if (item.solicitud.tipoSolicitud === "CAMBIO") {
        this.$router.push({
          name: "ProcesarCambioForm",
        });
        return;
      }
      if (item.solicitud.tipoSolicitud === "RETIRO") {
        this.$router.push({
          name: "ProcesarRetiroForm",
        });
        return;
      }
      if (item.solicitud.tipoSolicitud === "SOLICITUD") {
        this.$router.push({
          name: "ProcesarInstalacionForm",
        });
        return;
      }
      if (item.solicitud.tipoSolicitud === "MANTENIMIENTO") {

        const bloqueoCliente = await this.$store.dispatch(
            "cartera/consultarBloqueoCliente",
            {codigoCliente: item?.solicitud?.codigoCliente}
        );

        if (bloqueoCliente) {
          this.mensajeProceso = [bloqueoCliente];
          this.titulo = "Advertencia";
          document.getElementById("modalButton").click();
        } else {
          this.$router.push({
            name: "ProcesarMantenimientoForm",
          });
        }
      }
    },
    cerrarMensajeCartera() {
      if (this.titulo === "Advertencia") {
        this.$router.push({
          name: "ProcesarMantenimientoForm",
        });
      }
    },
    seleccionarFila(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;
      if (checked) {
        this.idSeleccionados.push(parseInt(valor));
      } else {
        const filtrados = this.idSeleccionados.filter(
            (item) => item !== parseInt(valor)
        );
        this.idSeleccionados = filtrados;
      }
    },
    asignar() {
      this.mensaje =
          "Se realizará la asignación de bodega para las solicitudes seleccionadas. Está seguro?";
    },
    async procesarSeleccionados() {
      try {
        await this.$store.dispatch(
            "solicitudes/procesar",
            this.idSeleccionados
        );
        this.mostrar = true;
        this.idSeleccionados = [];

        setTimeout(() => {
          this.desmarcarChecks();
        }, 100);
      } catch (error) {
        this.mensaje = "No se pudo concluir con la asignación";
      }
    },
    desmarcarChecks() {
      for (let i = 0; i < document.f1.elements.length; i++) {
        if (document.f1.elements[i].type == "checkbox") {
          document.f1.elements[i].checked = false;
        }
      }
    },
    filtrar(filtro) {
      this.tipoFiltro = filtro;
      if (filtro === "TODOS") {
        this.solicitudesFiltradas = this.solicitudes;
        return;
      }
      const solicitudesTemp = this.solicitudes.filter(
          (item) => item.solicitud.tipoSolicitud === filtro
      );
      this.solicitudesFiltradas = solicitudesTemp;
      this.configurarCoordenadas(this.solicitudesFiltradas);
    },
    verSolicitudRelacionada(solicitud) {
      if (solicitud?.solicitudPrincipal) {
        this.titulo = "Solicitud relacionada";
        this.mensajeProceso = [
          `Id: ${solicitud?.solicitudPrincipal?.id}`,
          `Fecha: ${solicitud?.solicitudPrincipal?.fechaSolicitud}`,
          `Ticket: ${solicitud?.solicitudPrincipal?.numeroTicket ?? ""}`,
          `Motivo: ${solicitud?.solicitudPrincipal?.motivo}`,
          `Establecimiento: ${solicitud?.solicitudPrincipal?.nombreEstablecimiento}`,
        ];
        document.getElementById("modalButton").click();
      }
    },
    async buscarEquipo() {
      this.loaderSpinner();
      try {
        if (this.tipoFiltroLocal && this.tipoFiltroLocal === "CLIENTE") {
          const requerimientoXCliente = this.solicitudesFiltradas?.filter(
              (item) => item?.solicitud?.codigoCliente === this.valorBusqueda
          );
          if (requerimientoXCliente?.length === 0) {
            return;
          }
          this.solicitudesFiltradas = requerimientoXCliente;
          this.configurarCoordenadas(requerimientoXCliente);
        }
        if (this.tipoFiltroLocal && this.tipoFiltroLocal === "TICKET") {
          const requerimientoXTicket = this.solicitudesFiltradas?.filter(
              (item) => item?.solicitud?.numeroTicket === this.valorBusqueda
          );
          if (requerimientoXTicket?.length === 0) {
            return;
          }
          this.solicitudesFiltradas = requerimientoXTicket;
          this.configurarCoordenadas(requerimientoXTicket);
        }
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    consultarTodos() {
      this.valorBusqueda = "";
      this.solicitudesFiltradas = this.solicitudes;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    solicitudes() {
      return this.$store.getters["solicitudesProveedor/solicitudesProveedor"]
          ? this.$store.getters["solicitudesProveedor/solicitudesProveedor"]
          : [];
    },
    rol() {
      return this.$store.state.auth.rol;
    },
    totalRegistros() {
      return this.solicitudesFiltradas.length;
    },
  },
};
</script>
<style>

</style>
