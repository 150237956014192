<template>
  <div
    class="modal fade"
    id="modalAsignarBodegaTecnico"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAsignarBodegaTecnicoLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAsignarBodegaTecnicoLabel">
            <strong>{{ titulo }} {{ tipo }}</strong>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="method"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="form-group">
                  <label>Seleccione una Bodega</label>
                  <select
                    class="form-control"
                    v-model="bodega"
                    @change="consultarTecnicos"
                  >
                    <option value="" disabled></option>
                    <option
                      v-for="item in bodegas"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.nombre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="form-group">
                  <label>Seleccione una Técnico</label>
                  <select class="form-control" v-model="tecnico">
                    <option value="" disabled></option>
                    <option
                      v-for="item in tecnicos"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.tecnico }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="
              asignar({
                tecnico,
              })
            "
          >
            Asignar
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="cerrar"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cerrar: { type: Function },
    asignar: { type: Function },
    titulo: String,
    tipo: String,
  },
  data() {
    return {
      bodega: 0,
      tecnico: 0,
    };
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarBodegasXProveedor() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bodegas/consultarXProveedor", {proveedor: this.eventoSeleccionado?.proveedor});
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async consultarTecnicos() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("ruta/consultarTecnicosXBodega", {
          idBodega: this.bodega,
        });
      } catch (error) {
        alert("No se pudo realizar la consulta de los técnicos");
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
        ? this.$store.getters["bodegas/bodegas"]
        : [];
    },
    tecnicos() {
      return this.$store.getters["ruta/tecnicos"]
        ? this.$store.getters["ruta/tecnicos"]
        : [];
    },
    eventoSeleccionado() {
      return this.$store.getters["eventos/eventoSeleccionado"]
        ? this.$store.getters["eventos/eventoSeleccionado"]
        : [];
    }
  },
  watch: {
    eventoSeleccionado(eventoAnterior, eventoNuevo){
      if(eventoAnterior?.proveedor !== eventoNuevo?.proveedor){
        this.consultarBodegasXProveedor();
      }
    }
  }
};
</script>
