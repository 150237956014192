<template>
  <GestionUsuarios/>
</template>

<script>
import GestionUsuarios from "@/views/admin/usuarios/components/GestionUsuarios";

export default {
  components: {GestionUsuarios}
}
</script>
