<template>
  <div>
    <br />
    <span class="text-danger">{{ mensaje }}</span>
  </div>
</template>

<script>
export default {
  props: ["mensaje"],
};
</script>