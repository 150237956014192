import axios from "axios";
import store from "../index";

const state = {
    waitingListArchivo: [],

};

const getters = {
    waitingListArchivo: (state) => state.waitingListArchivo,
};

const mutations = {
    setWaitingListArchivo: (state, waitingListArchivo) => {
        state.waitingListArchivo = waitingListArchivo;
    },
};

const actions = {

    async consultarWaitingListArchivoPaginado({ commit }, data) {
        console.log(data)
        try {
            let res = await axios.get(`/v1/waitinglist-archivo/pagina/${data.pagina}`);
            commit("setWaitingListArchivo", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async consultarWaitingListArchivoTotalPaginas() {
        try {
            const resp = await axios.get(`/v1/waitinglist-archivo/total`);
            store.dispatch("pagination/updateTotales", {
                totalPaginas: resp?.data?.totalPaginas,
                totalRegistros: resp?.data?.totalRegistros
            })
        } catch (error) {
            return error;
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
