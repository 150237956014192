<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Reparaciones</h3>
          </div>
        </div>

        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Ubicación</th>
                <th></th>
                <th>Taller</th>
                <th>Placa</th>
                <th>Serie</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Logo</th>
                <th>Año fabricación</th>
                <th>Tipo</th>
                <th>Fabricante Genesis</th>
                <th>Status Neveras</th>
                <th>Valor Libros</th>
                <th>Valor Comercial</th>
                <th>Status Libros</th>
                <th>Año Adquisición</th>
                <th>Capacidad Botellas</th>
                <th>Capacidad Cajas</th>
                <th>Capacidad Pies</th>
                <th>Activo Fijo</th>
                <th>Número Equipo</th>
                <th>VH</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in reparaciones" :key="item.id">
                <td>
                  <a
                      href="javascript:void(0)"
                      @click="seleccionarReparacion(item)"
                      data-toggle="modal"
                      data-target="#modalAtenderReparacion"
                      data-backdrop="static"
                      data-keyboard="false"
                  ><i class="ti-pencil"></i
                  ></a>
                </td>
                <td>{{ item.ubicacion }}</td>
                <td class="">
                  <div
                      class="badge"
                      :class="
                      item.estadoEquipo === 'DISPONIBLE'
                        ? 'badge-success'
                        : 'badge-secondary'
                    "
                  >
                    {{ item.estadoEquipo }}
                  </div>
                </td>
                <td>{{ item.bodegaDescripcion }}</td>
                <td style="font-weight: bold">
                 {{ item.placa }}
                </td>
                <td>{{ item.serie }}</td>
                <td>{{ item.marca }}</td>
                <td>{{ item.modelo }}</td>
                <td>{{ item.logo }}</td>
                <td>{{ item.anioFabricacion }}</td>
                <td>{{ item.tipoEquipo }}</td>
                <td>{{ item.fabricanteGenesis }}</td>
                <td>{{ item.statusNeveras }}</td>
                <td>{{ item.valorLibros }}</td>
                <td>{{ item.valorComercial }}</td>
                <td>{{ item.statusLibros }}</td>
                <td>{{ item.anioAdquisicion }}</td>
                <td>{{ item.capacidadBotellas }}</td>
                <td>{{ item.capacidadCajas }}</td>
                <td>{{ item.capacidadPies }}</td>
                <td>{{ item.activoFijo }}</td>
                <td>{{ item.numeroEquipo }}</td>
                <td>{{ item.vh }}</td>
              </tr>
              </tbody>
            </table>

            <AtenderReparacion :finalizar="finalizarReparacion"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AtenderReparacion from "@/views/reparaciones/components/AtenderReparacion";

export default {
  name: "GestionReparaciones",
  components: {AtenderReparacion},
  data() {
    return {};
  },
  mounted() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },

    async consultar() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("reparaciones/consultar");
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarReparacion(reparacion) {
      this.$store.commit("reparaciones/setReparacionSeleccionada", reparacion);
    },
    async finalizarReparacion(data) {
      const formData = {
        equipo: this.reparacionSeleccionada?.id,
        observacion: data.observacion,
        bodega: this.reparacionSeleccionada?.bodega,
      };

      try {
        await this.$store.dispatch("reparaciones/crearReparacion", formData);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    reparaciones() {
      return this.$store.getters["reparaciones/reparaciones"]
          ? this.$store.getters["reparaciones/reparaciones"]
          : [];
    },
    rol() {
      return this.$store.state.auth.rol;
    },
    reparacionSeleccionada() {
      return this.$store.getters["reparaciones/reparacionSeleccionada"]
          ? this.$store.getters["reparaciones/reparacionSeleccionada"]
          : null;
    },
  },
};
</script>
<style></style>
