<template>
  <div
      class="modal fade"
      id="modalMovimientos"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalMovimientosLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalMovimientosLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <p v-if="!movimientos?.length">No existen movimientos registrados</p>
                <div class="table-responsive" v-else>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Usuario</th>
                      <th>Cliente</th>
                      <th>Bodega</th>
                      <th>Solicitud</th>
                      <th>Observacion</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in movimientos" :key="item.id">
                      <td>{{ item.fecha }}</td>
                      <td>{{ item?.usuario ?? '' }}</td>
                      <td>{{ item?.cliente ?? '' }}</td>
                      <td>{{ item?.bodega?.nombre ?? '' }}</td>
                      <td>{{ item.solicitud }}</td>
                      <td>{{ item.observacion }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary btn-general-off"
                data-dismiss="modal"
            >
              <i class="ti-close"></i> Cerrar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    titulo: String,
    equipo: Number
  },
  data() {
    return {

    }
  },
  mounted() {
    this.$watch('equipo', this.actualizarMovimientos);
  },
  methods: {
    async consultarMovimientos(equipo) {
      this.loader = this.$loading.show();
      try {
        await this.$store.dispatch("movimientos/consultarMovimientos", {
          idEquipoFrio: equipo,
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loader.hide();
      }
    },
    actualizarMovimientos(nuevoEquipo, antiguoEquipo) {
      if (nuevoEquipo !== antiguoEquipo) {
        this.consultarMovimientos(nuevoEquipo);
      }
    },
  },
  computed:{
    movimientos() {
      return this.$store.getters["movimientos/movimientos"]
          ? this.$store.getters["movimientos/movimientos"]
          : [];
    },
  }
};
</script>
