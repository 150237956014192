<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Solicitudes Rechazadas</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <button
                class="btn btn-primary btn-general float-right mb-2 ml-2"
                @click="agregarExcepcion(selectedItems)"
                data-toggle="modal"
                data-target="#modalExcepcion"
                data-backdrop="static"
                data-keyboard="false"
              >
                <span class="icon-play"></span> Procesar
              </button>
              <button
                @click="exportExcel"
                class="btn btn-primary btn-general float-right"
                :disabled="solicitudesRechazadas.length === 0"
              >
                <span class="icon-download"></span> Descargar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-table">
          <div class="row">
            <div class="col-md-6">
              <div class="form-check-inline mt-2">
                <input
                  class="form-check-input"
                  type="radio"
                  id="ticket"
                  value="TICKET"
                  v-model="tipoFiltro"
                />
                <label class="form-check-label radio-margin" for="ticket">
                  Ticket
                </label>

                <input
                  class="form-check-input"
                  type="radio"
                  id="cliente"
                  value="CLIENTE"
                  v-model="tipoFiltro"
                />
                <label class="form-check-label mr-2" for="cliente">
                  Cliente
                </label>

                <div class="form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="todos"
                    value="TODOS"
                    v-model="tipoFiltro"
                    @click="consultar(1)"
                  />
                  <label class="form-check-label mr-2" for="todos">
                    Todos
                  </label>
                </div>
              </div>
              <div class="input-group col-md-12 no-padding">
                <input
                  type="text"
                  class="form-control"
                  v-model="valorBusqueda"
                  aria-label="Text input with segmented dropdown button"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-general btn-buscar mb-2 mb-md-0"
                    @click="buscarSolicitud"
                    :disabled="
                      valorBusqueda.length === 0 || tipoFiltro === 'TODOS'
                    "
                  >
                    <span class="icon-search"></span> Buscar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>Ticket</th>
                  <th>Cliente</th>
                  <th>Tipo</th>
                  <th>Fecha de solicitud</th>
                  <th>Dias Trascurridos</th>
                  <th>Nivel de riesgo</th>
                  <th>Capacidad</th>
                  <th>Mediana</th>
                  <th>Gerencia general</th>
                  <th>Gerencia ventas</th>
                  <th>ECB</th>
                  <th>Nombre</th>
                  <th>Establecimiento</th>
                  <th>Dirección</th>
                  <th>Tipo de caso</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in solicitudesRechazadas" :key="item.id">
                  <td>
                    <input type="checkbox" @click="selectItem(item)" />
                  </td>
                  <td>{{ item.solicitud.id }}</td>
                  <td>{{ item.solicitud.numeroTicket }}</td>
                  <td>{{ item.solicitud.codigoCliente }}</td>
                  <td>{{ item.solicitud.tipoSolicitud }}</td>
                  <td>{{ item.solicitud.fechaSolicitud }}</td>
                  <td>
                    <div
                      class="badge"
                      :class="
                        item.solicitud.diasTranscurridosLabolables < 3
                          ? 'badge-success'
                          : item.solicitud.diasTranscurridosLabolables > 5
                          ? 'badge-danger'
                          : 'badge-warning'
                      "
                    >
                      {{ item.solicitud.diasTranscurridosLabolables }} día(s)
                    </div>
                  </td>
                  <td>{{ item.solicitud.prioridad }}</td>
                  <td>{{ item.solicitud.capacidadEeffMediana }}</td>
                  <td>{{ item.solicitud.medianaUltimoTrimestre }}</td>
                  <td>{{ item.solicitud.gerenciaGeneral }}</td>
                  <td>{{ item.solicitud.gerenciaVentas }}</td>
                  <td>{{ item.solicitud.ecb }}</td>
                  <td>{{ item.solicitud.nombre }}</td>
                  <td>{{ item.solicitud.nombreEstablecimiento }}</td>
                  <td>{{ item.solicitud.direccion }}</td>
                  <td>{{ item.solicitud.tipoCaso }}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <div class="d-flex justify-content-center">
              <nav aria-label="Page navigation example" v-if="paginas">
                <ul class="pagination">
                  <li class="page-item" :disabled="pagina === 1">
                    <a class="page-link" @click="setPreviewPage">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li
                    v-for="page in paginas"
                    :key="page"
                    class="page-item"
                    :class="{ active: pagina === page }"
                  >
                    <a class="page-link" @click="setPage(page)">{{ page }}</a>
                  </li>
                  <li class="page-item" :disabled="pagina === paginas">
                    <a class="page-link" @click="setNextPage">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <AgregarExcepcion
              titulo="Agregar excepción"
              :agregar="procesarAgregarExcepcion"
            />
            <ModalMessages
              :mensaje="mensajeProceso"
              titulo="Agregar excepción"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AgregarExcepcion from "./AgregarExcepcion.vue";
import ModalMessages from "../../../components/ModalMessages.vue";
import { saveExcel } from "@progress/kendo-vue-excel-export";

export default {
  name: "GestionEquipos",
  components: { AgregarExcepcion, ModalMessages },
  data() {
    return {
      pagina: 1,
      mensaje: "",
      id: null,
      valorBusqueda: "",
      tipoFiltro: "",
      mensajeProceso: [],
      selectedItems: [],
    };
  },
  mounted() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      this.loaderSpinner();
      this.valorBusqueda = "";
      try {
        const data = {
          pagina: pagina || 1,
        };
        await this.$store.dispatch(
          "solicitudesRechazadas/consultarSolicitudesXPagina",
          data
        );
        await this.$store.dispatch(
          "solicitudesRechazadas/consultarTotalPaginas"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
    agregarExcepcion(solicitud) {
      this.$store.commit(
        "solicitudesRechazadas/setSolicitudRechazadaSeleccionada",
        solicitud
      );
    },
    selectItem(check) {
      const findedOption = this.selectedItems?.findIndex(
        (item) => item === check.id
      );

      let arraySelectedItems = [];
      if (findedOption === -1) {
        arraySelectedItems = [...this.selectedItems, check.id];
      } else {
        arraySelectedItems = this.selectedItems.filter(
          (item) => item !== check.id
        );
      }
      this.selectedItems = arraySelectedItems;
    },
    async procesarAgregarExcepcion(data) {
      this.loaderSpinner();
      try {

        await this.$store.dispatch("solicitudesRechazadas/agregarExcepciones", {
          observacion: data.observacion,
          solicitudRechazadas: this.selectedItems,
        });
        this.mensajeProceso = ["Se agregó la excepxión con éxito"];
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensajeProceso = ["No se pudo agregar la excepción"];
        document.getElementById("modalButton").click();
      } finally {
        this.loader.hide();
      }
    },
    async buscarSolicitud() {
      this.loaderSpinner();
      try {
        if (this.tipoFiltro && this.tipoFiltro === "TICKET") {
          await this.$store.dispatch(
            "solicitudesRechazadas/consultarSolicitudXTicket",
            {
              ticket: this.valorBusqueda,
            }
          );
        }
        if (this.tipoFiltro && this.tipoFiltro === "CLIENTE") {
          await this.$store.dispatch(
            "solicitudesRechazadas/consultarSolicitudXCliente",
            {
              codigoCliente: this.valorBusqueda,
            }
          );
        }

        /* await this.$store.commit(
          "solicitudesRecrazadas/setSolicitudesRechazadas",
          Object.keys(this.solicitudRechazadaSeleccionada).length === 0
            ? null
            : [this.solicitudRechazadaSeleccionada]
        );
        await this.$store.commit("solicitudesRecrazadas/setTotalPaginas", 1); */
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    exportExcel() {
      saveExcel({
        data: this.solicitudesRechazadas,
        fileName: "solicitudes-rechazadas",
        columns: [
          { field: "solicitud.id", title: "Id" },
          { field: "solicitud.numeroTicket", title: "Tickets relacionados" },
          { field: "solicitud.codigoCliente", title: "Cliente" },
          { field: "solicitud.tipoSolicitud", title: "Tipo" },
          { field: "solicitud.fechaSolicitud", title: "Fecha solicitud" },
          {
            field: "solicitud.diasTranscurridosLabolables",
            title: "Días Transcurridos",
          },
          { field: "solicitud.prioridad", title: "Prioridad" },
          { field: "solicitud.capacidadEeffMediana", title: "Capacidad" },
          { field: "solicitud.medianaUltimoTrimestre", title: "Mediana" },
          { field: "solicitud.gerenciaGeneral", title: "Gerencia general" },
          { field: "solicitud.gerenciaVentas", title: "Gerencia ventas" },
          { field: "solicitud.ecb", title: "ECB" },
          { field: "solicitud.nombre", title: "Nombre" },
          {
            field: "solicitud.nombreEstablecimiento",
            title: "Establecimiento",
          },
          { field: "solicitud.direccion", title: "Dirección" },
          { field: "solicitud.tipoCaso", title: "Tipo de caso" },
        ],
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    solicitudesRechazadas() {
      return this.$store.getters["solicitudesRechazadas/solicitudesRechazadas"]
        ? this.$store.getters["solicitudesRechazadas/solicitudesRechazadas"]
        : [];
    },
    totalPaginas() {
      return this.$store.getters["solicitudesRechazadas/totalPaginas"]
        ? this.$store.getters["solicitudesRechazadas/totalPaginas"]
        : [];
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
