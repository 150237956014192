import axios from "axios";

const state = {
  menu: null,
  menus: [],
  menuSeleccionado: null,
  menuByRole: []
};

const getters = {
  menu: (state) => state.menu,
  menus: (state) => state.menus,
  menuByRole: (state) => state.menuByRole,
  menuSeleccionado: (state) => state.menuSeleccionado,
};

const mutations = {
  setMenu: (state, menu) => {
    state.menu = menu;
  },
  setMenus: (state, menus) => {
    state.menus = menus;
  },
  setMenuByRole: (state, menuByRole) => {
    state.menuByRole = menuByRole;
  },
  setMenuSeleccionado: (state, menuSeleccionado) => {
    state.menuSeleccionado = menuSeleccionado;
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/menus`);
      commit("setMenu", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async searchAllMenus({ commit }) {
    try {
      let res = await axios.get(`/v1/menus/all`);
      commit("setMenus", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async searchMenuByRole({ commit }, data) {
    try {
      let res = await axios.get(`/v1/menus/${data?.rol}/rol`);
      commit("setMenuByRole", res?.data?.result);
    } catch (error) {
      commit("setMenuByRole", []);
      return error;
    }
  },
  async crear({ commit, state }, data) {
    try {
      let res = await axios.post(`/v1/menus`, data);
      let nuevo = res?.data?.result;
      let actualizados = [...state.menus, nuevo];
      commit("setMenus", actualizados);
    } catch (error) {
      return error;
    }
  },
  async asignar(_, data) {
    try {
      await axios.post(`/v1/menus-rol/asignar`, data);
    } catch (error) {
      return error;
    }
  },
  async desasignar(_, data) {
    try {
      await axios.delete(`/v1/menus-rol/${data?.id}/${data?.rol}/desasignar`);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
