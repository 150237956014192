<template>
  <div>
    <div class="form-check">
      <input
        class="form-check-input ml-1"
        type="checkbox"
        @click="habilitarBusquedaXPlaca"
        :id="`busqueda${origen}`"
      />
      <label class="form-check-label" for="busqueda">
        Búsqueda por placa
      </label>
    </div>
    <div
      class="input-group col-md-12 mt-2 no-padding"
      v-if="mostrarBuscarXPlaca"
    >
      <input
        type="text"
        class="form-control"
        placeholder="Buscar por placa"
        v-model="placa"
        aria-label="Text input with segmented dropdown button"
      />
      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-outline-secondary btn-general btn-buscar"
          @click="buscarXPlaca"
          :disabled="placa.length === 0"
        >
          <span class="icon-search"></span> Buscar
        </button>
      </div>
    </div>
    <div
      class="form-check"
      v-for="(item, $index) in equiposEncontrados"
      :key="$index"
    >
      <input
        v-if="origen !== 'MANTENIMIENTO'"
        class="form-check-input ml-1"
        type="checkbox"
        :id="item?.id"
        :value="item?.id"
        @click="seleccionarFila"
        :disabled="
          (origen === 'CAMBIO' && item?.estadoEquipo !== 'ENCI')
        "
      />
      <label class="form-check-label" :for="item?.id">
        <strong>Placa:</strong>
        {{ item?.placa }}
        <strong>Serie:</strong>
        {{ item?.serie }}
        <strong>Modelo:</strong>
        {{ item?.modelo }}
        <strong>Capacidad (cajas):</strong>
        {{ item?.capacidadCajas }}
        <strong>Capacidad (pies):</strong>
        {{ item?.capacidadPies }}
        <strong>Logo:</strong>
        {{ item?.logo }}
        <strong>Estado: </strong>
        <div
          class="badge"
          :class="
            item?.estadoEquipo === 'DISPONIBLE'
              ? 'badge-success'
              : 'badge-secondary'
          "
        >
          {{ item?.estadoEquipo }}
        </div>
      </label>
    </div>
    <div>
      <p class="text-danger">{{ mensaje }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      equiposEncontrados: [],
      mostrarBuscarXPlaca: false,
      placa: "",
      mensaje: "",
    };
  },
  props: {
    seleccionarEquipo: { type: Function },
    origen: String,
  },
  mounted(){

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    habilitarBusquedaXPlaca() {
      /* if (this.mostrarBuscarXPlaca && this.equiposEncontrados.length>0) {
        this.seleccionarEquipo({
          tipo: "DELETE_ALL",
          valor: this.equiposEncontrados,
        });
      } */
      this.mostrarBuscarXPlaca = !this.mostrarBuscarXPlaca;
    },
    async buscarXPlaca() {
      this.mensaje = "";
      try {
        this.loaderSpinner();
        const resp = await this.$store.dispatch(
          "equipos/consultarEquipoXPlaca",
          { placa: this.placa }
        );
        /* if(resp?.estadoEquipo !== 'DISPONIBLE'){
          this.mensaje = "No disponible";
          return;
        } */
        if (!resp) {
          return;
        }

        const equipoEncontrado = this.equiposEncontrados?.find(
          (item) => item?.id === resp?.id
        );

        if (!equipoEncontrado) {
          this.equiposEncontrados.push(resp);
        }
      } catch (error) {
        //this.equiposEncontrados = [];
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarFila(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;
      if (checked) {
        this.seleccionarEquipo({ tipo: "ADD", valor: parseInt(valor) });
      } else {
        this.seleccionarEquipo({ tipo: "DELETE", valor: parseInt(valor) });
      }
    },
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
        "solicitudesProveedor/solicitudProveedorSeleccionada"
      ]
        ? this.$store.getters[
            "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
        : {};
    },
  },
};
</script>
