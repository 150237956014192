import axios from "axios";

const state = {
  mensaje: "",
  solicitudesCerradas: [],
  totalPaginas: 0
};

const getters = {
  solicitudesCerradas: (state) => state.solicitudesCerradas,
  totalPaginas: (state) => state.totalPaginas,
};

const mutations = {
  inicializar: (state) => {
    state.solicitudesProveedor = null
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  setSolicitudesCerradas: (state, solicitudesCerradas) => {
    state.solicitudesCerradas = solicitudesCerradas;
  },
  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  }
};

const actions = {
  async consultarSolicitudesCerradasXPagina({ commit }, data) {
    try {
      let res = await axios.get(`/v1/rutas/1/vehiculo/atendidos/${data.pagina}/pagina`);
      commit("setSolicitudesCerradas", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginas({ commit }, data) {
    try {
      const resp = await axios.get(`/v1/rutas/${data.idBodegaVehiculo}/vehiculo/atendidos/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async consultarSolicitudesAtendidasXFechas({ commit }, data) {
    try {
      let res = await axios.get(`/v1/rutas/${data.idBodegaVehiculo}/vehiculo/atendidos/${data.fechaInicio}/${data.fechaFin}`);
      commit("setSolicitudesCerradas", res?.data?.result);
      commit("setTotalPaginas", 1);
    } catch (error) {
      commit("setSolicitudesCerradas", []);
      commit("setTotalPaginas", 1);
      return error;
    }
  }

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
