<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Análisis múltiple
            </h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <label for="codigoCliente mt-0">Código de cliente</label>
                <div class="input-group">
                  <input
                      type="text"
                      class="form-control"
                      v-model="codigoCliente"
                      placeholder="Código de cliente"
                      aria-label="Text input with segmented dropdown button"
                  />
                  <div class="input-group-append">
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-general btn-buscar mb-2 mb-md-0"
                        @click="agregar"
                        :disabled="!codigoCliente.length"
                    >
                      <span class="icon-plus"></span> Agregar
                    </button>
                  </div>
                </div>

                <span v-for="(item, $index) in codigos" :key="$index"
                      class="pl-2 pr-2 bg-celeste txt-blanco mr-2 mt-2 border-general">
                                        {{ item }}
                                        <a
                                            href="javascript:void(0)"
                                            class="text-danger txt-blanco ml-2"
                                            @click="eliminarCodigo(item)"
                                        ><i class="ti-close"></i
                                        ></a>
                                    </span>

              </div>
            </div>
            <div class="col-md-6">
              <button type="button" class="btn btn-primary btn-general float-right" @click="calcular">
                <span class="icon-check"></span> Calcular
              </button>
            </div>
          </div>
        </div>


        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Código</th>
                <th>Estado</th>
                <th>Gerencia General</th>
                <th>Gerencia Ventas</th>
                <th>Nombre</th>
                <th>Establecimiento</th>
                <th>Cédula</th>
                <th>Dirección</th>
                <th>Teléfono</th>
                <th>ECB</th>
                <th>Cantidad de EEFF</th>
                <th>Wellington</th>
                <th>Días vencido</th>
                <th>Monto vencido</th>
                <th>Cliente crecimiento</th>
                <th>Nps Predictivo</th>
                <th>Últimos meses compra</th>
                <th>Últimos meses compra ácida</th>
                <th>Nivel de riesgo</th>

                <th>Venta promedio</th>

                <th>Capacidad aplica volumen</th>

                <th>Credit Score</th>
                <th>Fecha de Alta Cliente</th>
                <th>% Prob. de Crecimiento</th>
                <th>Quantil Densidad</th>
                <th>Volumen curso</th>
                <th>Cajas</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, $index) in analisis" :key="$index">
                <td>{{ item.ruteroDto?.clienteSap }}</td>
                <td>{{ item.ruteroDto?.estadoCliente }}</td>
                <td>{{ item.ruteroDto?.gerenciaGeneral }}</td>
                <td>{{ item.ruteroDto?.gerenciaVentas }}</td>
                <td>{{ item.ruteroDto?.nombresCliente }}</td>
                <td>{{ item.ruteroDto?.nombrePoc }}</td>
                <td>{{ item.ruteroDto?.noIdentificacionFiscal }}</td>
                <td>{{ item.ruteroDto?.direccionPoc }}</td>
                <td>{{ item.ruteroDto?.telefonoCliente }}</td>
                <td>{{ item.ruteroDto?.sectorDesarrollo }}</td>
                <td>{{ item?.cantidadEeff }}</td>
                <td>{{ item.wellington }}</td>
                <td>{{ item.cartera?.diasMora }}</td>
                <td>{{ item.cartera?.vencido }}</td>
                <td>
                  {{ item.calculoInstalacion?.crecimiento === 0 ? "NO" : "SI" }}
                </td>
                <td>{{ item.semNps[0]?.nps }}</td>
                <td>{{ item.calculoInstalacion?.ultimosMesesCompra }}</td>
                <td>{{ item.calculoInstalacion?.ultimosMesesCompraAcida }}</td>
                <td>{{ item.calculoInstalacion?.prioridad }}</td>

                <td>{{ item.calculoInstalacion?.promedioUltimosTresMesesVolumen }}</td>
                <td>{{ item.calculoInstalacion?.capacidadAplicaVolumen }}</td>

                <td>{{ item.informacionAnalisis?.creditScore }}</td>

                <td>{{ item.ruteroDto?.fechaAlta }}</td>
                <td>{{ item.informacionAnalisis?.probCrecimiento }}</td>
                <td>{{ item.informacionAnalisis?.quantilDensidad }}</td>
                <td>{{ item?.cajasCurso?.volumen }}</td>
                <td>
                  <a
                      href="javascript:void(0)"
                      @click="presentarCajas(item?.cajas ?? [])"
                      data-toggle="modal"
                      data-target="#modalCajas"
                      data-backdrop="static"
                      data-keyboard="false"
                  ><i class="ti-comment-alt"></i
                  ></a>
                </td>
              </tr>
              </tbody>
            </table>
            <Cajas :data="cajas"/>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import Cajas from "./Cajas.vue";

export default {
  name: "GestionAnalisisMultiple",
  components: {Cajas},
  data() {
    return {
      codigoCliente: "",
      codigos: [],
      cajas: [],
    };
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async agregar() {
      const codigoEncontrado = this.codigos?.find(
          (item) => item === this.codigoCliente
      );

      if (codigoEncontrado) {
        return;
      }
      this.codigos?.push(this.codigoCliente);
      this.codigoCliente = "";
    },
    eliminarCodigo(codigo) {
      const codigosFiltrados = this.codigos?.filter((item) => item !== codigo);
      this.codigos = codigosFiltrados;
    },
    async calcular() {

      try {
        await this.$store.dispatch("analisis/calcularAnalisis", {
          codigoCliente: this.codigos,
        });
      } catch (error) {
        console.log(error);
      }
    },
    presentarCajas(cajas) {
      cajas.sort((a, b) => {
        const nameA = a.mes; // ignore upper and lowercase
        const nameB = b.mes; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.cajas = cajas;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    analisis() {
      return this.$store.getters["analisis/analisisMultiple"]
          ? this.$store.getters["analisis/analisisMultiple"]
          : {};
    },
  },
};
</script>
<style>
</style>
