import axios from "axios";

const state = {
  rutas: null,
  rutaSeleccionada: null,
  vehiculos: [],
  tecnicos: [],
};

const getters = {
  rutas: (state) => state.rutas,
  rutaSeleccionada: (state) => state.rutaSeleccionada,
  vehiculos: (state) => state.vehiculos,
  tecnicos: (state) => state.tecnicos,
};

const mutations = {
  setRutas: (state, rutas) => {
    state.rutas = rutas;
  },
  setRutaSeleccionada: (state, rutaSeleccionada) => {
    state.rutaSeleccionada = rutaSeleccionada;
  },
  setVehiculos: (state, vehiculos) => {
    state.vehiculos = vehiculos;
  },
  setTecnicos: (state, tecnicos) => {
    state.tecnicos = tecnicos;
  },
  inicializar: (state) => {
    (state.rutas = null), (state.rutaSeleccionada = null);
  },
};

const actions = {
  async crearRuta({ commit, state }, data) {
    try {
      let res = await axios.post(`/v1/rutas`, data);
      let nuevaRuta = res?.data?.result;
      let rutasActualizadas = [...state.equipos, nuevaRuta];
      commit("setRutas", rutasActualizadas);
    } catch (error) {
      return error;
    }
  },
  async consultarVehiculosXBodega({ commit }, data) {
    try {
      let res = await axios.get(`/v1/bodega-vehiculos/${data.idBodega}/bodega`);
      commit("setVehiculos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTecnicosXBodega({ commit }, data) {
    try {
      let res = await axios.get(`/v1/bodega-tecnicos/${data.idBodega}/bodega`);
      commit("setTecnicos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarRutaVehiculos({ commit }, data) {
    try {
      let res = await axios.get(`/v1/rutas/${data.idBodegaVehiculo}/vehiculo`);
      commit("setRutas", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarRutaTecnicos({ commit }, data) {
    try {
      let res = await axios.get(`/v1/rutas/${data.idBodegaTecnico}/tecnico`);
      commit("setRutas", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async procesarRetiro({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`v1/solicitudes-asignadas/procesar-retiro`, data?.data, { headers });
      let res = await axios.get(`/v1/rutas/${data.idBodegaTecnico}/tecnico`);
      commit("setRutas", res?.data?.result);
      //return res?.data?.result;
      return "Proceso de retiro exitoso";
    } catch (error) {
      return "No se pudo procesar el requerimiento";
    }
  },
  async procesarInstalacion({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`v1/solicitudes-asignadas/procesar-instalacion`, data?.data, { headers });
      let res = await axios.get(`/v1/rutas/${data.idBodegaTecnico}/tecnico`);
      commit("setRutas", res?.data?.result);
      return "Proceso de instlación exitoso";
    } catch (error) {
      return "No se pudo procesar el requerimiento";
    }
  }
  
  
  /* ,
  async consultarVehiculos({ commit }, data) {
    try {
      let res = await axios.get(`/v1/rutas/${data.idBodegaVehiculo}/vehiculo`);
      commit("setVehiculos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTecnicos({ commit }, data) {
    try {
      let res = await axios.get(`/v1/rutas/${data.idBodegaVehiculo}/tecnico`);
      commit("setTecnicos", res?.data?.result);
    } catch (error) {
      return error;
    }
  } */
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
