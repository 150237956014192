<template>
    <footer v-if="show" class="footer">
    <div class="container text-center">
        <p>
            Para revisar como Cervecería Nacional y sus compañías afiliadas, tratan sus datos personales, revise la Política de Protección de Datos de Proveedores en: <a href="https://www.cervecerianacional.ec/sites/g/files/seuoyk1251/files/2024-07/PoliticaProveedores-Final.pdf" target="_blank">https://www.cervecerianacional.ec/sites/g/files/seuoyk1251/files/2024-07/PoliticaProveedores-Final.pdf</a>
        </p>
    </div>
    </footer>
</template>
<script>

  export default {
    name: "Footer",
    components: {},
    data() {
      return {
        show: false
      };
    },
    methods: {
        showFooter(){
            if(process.env.VUE_APP_COUNTRY === 'EC'){
                this.show = true;
            }
        }
    },
    computed: {

    },mounted() {
        this.showFooter();
    }
  };
  </script>
<style>
.footer {
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

</style>
  