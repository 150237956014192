<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Archivos Cargados a Lista de Espera</h3>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="table-responsive">
            <table class="table table-striped mb-3">
              <thead>
              <tr >
                <th>Fecha</th>
                <th>Usuario</th>
                <th>Correctos</th>
                <th>Fallidos</th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="item in waitingListArvhivos" :key="item.id">
                <td>{{item.fecha}}</td>
                <td>{{item.usuario}}</td>
                <td>{{ item.correctos }}</td>
                <td>{{ item.fallidos }}</td>
              </tr>
              </tbody>
            </table>
            <Paginacion :updatePage="updatePage"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Paginacion from '../../../components/Paginacion.vue';

export default {
  name: "WaitingListArchivosCargados",
  components: {
    Paginacion
  },
  data() {
    return {
      pagina: 1,
    };
  },
created() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("waitingArchivo/consultarWaitingListArchivoPaginado", data);
        await this.$store.dispatch(
            "waitingArchivo/consultarWaitingListArchivoPaginado"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },

    updatePage(page) {
      this.pagina = page;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    waitingListArvhivos() {
      return this.$store.getters["waitingArchivo/waitingListArchivo"]
          ? this.$store.getters["waitingArchivo/waitingListArchivo"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
