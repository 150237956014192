import axios from "axios";

const state = {
  eventos: null,
  eventoSeleccionado: null,
  mensaje: "",
  horarioEntrega: null,
  horarioRetiro: null,
  horario: null,
  totalPaginas: null,
  totalRegistros: null,
};

const getters = {
  eventos: (state) => state.eventos,
  eventoSeleccionado: (state) => state.eventoSeleccionado,
  horarioEntrega: (state) => state.horarioEntrega,
  horarioRetiro: (state) => state.horarioRetiro,
  horario: (state) => state.horario,
  totalPaginas: (state) => state.totalPaginas,
  totalRegistros: (state) => state.totalRegistros,
};

const mutations = {
  setEventos: (state, eventos) => {
    state.eventos = eventos;
  },
  setEventoSeleccionado: (state, eventoSeleccionado) => {
    state.eventoSeleccionado = eventoSeleccionado;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  setHorarioEntrega: (state, horarioEntrega) => {
    state.horarioEntrega = horarioEntrega;
  },
  setHorarioRetiro: (state, horarioRetiro) => {
    state.horarioRetiro = horarioRetiro;
  },
  setHorario: (state, horario) => {
    state.horario = horario;
  },
  inicializar: (state) => {
    (state.eventos = null), (state.eventoSeleccionado = null);
  },
  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
  setTotalRegistros: (state, data) => {
    state.totalRegistros = data;
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/eventos`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },

  async consultarEventosXPagina({ commit }, data) {
    try {
      let res = await axios.get(`/v1/eventos/${data.pagina}/pagina`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginas({ commit }) {
    try {
      const resp = await axios.get(`/v1/eventos/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      commit("setTotalRegistros", resp?.data?.totalRegistros);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },



  async crear({ commit }, data) {
    try {
      await axios.post(`/v1/eventos`, data);
      commit("setMensaje", "Se ha creado el evento con éxito");
    } catch (error) {
      return error;
    }
  },
  async agregarEquipos({ commit }, data) {
    try {
      await axios.post(`/v1/eventos/${data.idEvento}/equipos`, data.equipos);
      commit("setMensaje", "Se agregaron equipos con éxito");
    } catch (error) {
      return error;
    }
  },
  async consultarEventosXProveedor({ commit }) {
    try {
      let res = await axios.get(`/v1/eventos/proveedor`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async procesarEventoInstalar({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/procesar-evento/solicitud`, data?.data , { headers });
      let res = await axios.get(`/v1/eventos/proveedor`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async procesarEventoRetirar({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/procesar-evento/retiro`, data?.data , { headers });
      let res = await axios.get(`/v1/eventos/proveedor`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async asignarTecnico({ commit }, data) {
    try {
      await axios.post(`/v1/eventos-ruta`, data);
      commit("setMensaje", "Se ha asignado el técnico con éxito");
    } catch (error) {
      return error;
    }
  },
  async consultarEventosXTecnico({ commit }) {
    try {
      let res = await axios.get(`/v1/eventos-ruta`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      commit("setEventos", []);
      return error;
    }
  },
  async procesarEventoInstalarTecnico({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/procesar-evento/solicitud`, data?.data , { headers });
      let res = await axios.get(`/v1/eventos-ruta`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async procesarEventoRetirarTecnico({ commit }, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/procesar-evento/retiro`, data?.data , { headers });
      let res = await axios.get(`/v1/eventos-ruta`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarEventosXUsuario({ commit }) {
    try {
      let res = await axios.get(`/v1/eventos/usuario`);
      commit("setEventos", res?.data?.result);
    } catch (error) {
      commit("setEventos", []);
      return error;
    }
  },
  async consultarEventoHorario({ commit }, data) {
    try {
      let res = await axios.get(`/v1/eventos/${data?.fecha}/horarios`);
      if(data?.origen === 'ENTREGA'){
        commit("setHorarioEntrega", res?.data?.result);
      }else{
        commit("setHorarioRetiro", res?.data?.result);
      }
    } catch (error) {
      if(data?.origen === 'ENTREGA'){
        commit("setHorarioEntrega", null);
      }else{
        commit("setHorarioRetiro", null);
      }
      return error?.response?.data?.errors ? {
        error: true,
        detalle: error?.response?.data?.errors
      } : {
        error: true,
        detalle: ["Error al consultar el horario disponible"]
      } ;
    }
  },
  async aprobarEvento({ commit }, data) {
    try {
      await axios.put(`/v1/eventos/${data?.idEvento}`, data?.fechas);
      commit("setMensaje", "Se ha actualizado el evento con éxito");
    } catch (error) {
      return error;
    }
  },
  async consultarHorario({ commit }) {
    try {
      let res = await axios.get(`/v1/eventos/horarios`);
      commit("setHorario", res?.data?.result);
    } catch (error) {
      commit("setHorario", []);
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
