<template>
  <div>

    <div class="mt-4 ml-4">
      <h5 class="card-title">
        Ticket
        {{ rutaSeleccionada?.solicitudProveedor?.solicitud?.numeroTicket }}
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">
        Tipo Solicitud:
        {{ rutaSeleccionada.solicitudProveedor.solicitud.tipoSolicitud }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Fecha Solicitud:
        {{ rutaSeleccionada.solicitudProveedor.solicitud.fechaSolicitud }}
      </h6>
    </div>


    <div class="mt-4 ml-4">
      <h5 class="card-title">
        Cliente
        {{ rutaSeleccionada.solicitudProveedor.solicitud.codigoCliente }}
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">
        Identificación: {{ rutaSeleccionada.solicitudProveedor.solicitud.identificacion }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Nombre: {{ rutaSeleccionada.solicitudProveedor.solicitud.nombre }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Establecimiento:
        {{ rutaSeleccionada.solicitudProveedor.solicitud.nombreEstablecimiento }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Dirección:
        {{ rutaSeleccionada.solicitudProveedor.solicitud.direccion }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Teléfono:
        {{
          rutaSeleccionada.solicitudProveedor.solicitud?.telefono || rutaSeleccionada.solicitudProveedor.solicitud?.telefonoCliente
        }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Sector desarrollo:
        {{ rutaSeleccionada.solicitudProveedor.solicitud.ecb }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Franja horaria:
        {{ rutaSeleccionada.solicitudProveedor.solicitud.franjaHoraria }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Referencia:
        {{ rutaSeleccionada.solicitudProveedor.solicitud.referencia }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Observación:
        {{ rutaSeleccionada.solicitudProveedor.solicitud.observacion }}
      </h6>
      <div>
        <a
            href="javascript:void(0)"
            @click="abrirMapa(rutaSeleccionada.solicitudProveedor.solicitud)"
        ><i class="ti-map menu-icon" style="color: red; font-size: 30px"></i
        ></a>
      </div>
    </div>
  </div>


</template>
<script>
export default {
  methods: {
    abrirMapa(solicitud) {
      const {latitud, longitud} = solicitud;
      window.open(`https://maps.google.com/maps?q=loc:${latitud},${longitud}`);
    },
  },
  computed: {
    rutaSeleccionada() {
      return this.$store.getters[
          "ruta/rutaSeleccionada"
          ]
          ? this.$store.getters[
              "ruta/rutaSeleccionada"
              ]
          : [];
    }
  },
};
</script>
