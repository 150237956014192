const state = {
  totalPaginas: null,
  totalRegistros: null,
};

const getters = {
  totalPaginas: (state) => state.totalPaginas,
  totalRegistros: (state) => state.totalRegistros,
};

const mutations = {
  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
  setTotalRegistros: (state, data) => {
    state.totalRegistros = data;
  },
};

const actions = {
  async updateTotales({ commit }, data) {
    try {
        commit("setTotalPaginas", data?.totalPaginas);
        commit("setTotalRegistros", data?.totalRegistros);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
