import axios from "axios";

const state = {
  equipo: {}
};

const getters = {
  equipo: (state) => state.equipo,
};

const mutations = {
  setEquipo: (state, equipo) => {
    state.equipo = equipo;
  },
  inicializar: (state) => {
    state.equipo = {}
  },
};

const actions = {
  async procesarBaja({store}, data) {
    try {
      console.log(store)
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/equipos/${data.idEquipo}/baja`, data.archivo, { headers });
      return "success"
    } catch (error) {
      return ["error", error?.response?.data?.errors[0]];
    }
  },
  async procesarReserva({store}, data) {
    try {
      console.log(store)
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/equipos/${data.idEquipo}/reserva`, data.archivo, { headers });
      return "success"
    } catch (error) {
      return "error";
    }
  },
  /* async cargarArchivo({store}, data) {
    try {
      console.log(store)
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/solicitudes/upload`, data, { headers });
      return "success"
    } catch (error) {
      return "error";
    }
  },
  async filtrar({ commit }, data) {
    try {
      const res = await axios.post(`v1/solicitudes/filtros`, data);
      commit("setSolicitudes", res?.data?.result);
    } catch (error) {
      return "No se pudo filtrar los requerimientos";
    }
  },
  async procesar({ commit }, data) {
    try {
      const solicitudes = await axios.post(`v1/solicitudes/asignacion`, data);
      const res = await axios.get(`/v1/solicitudes`);
      commit("setSolicitudes", res?.data?.result);
      return solicitudes?.data || "";
    } catch (error) {
      return "No se pudo procesar la solicitud";
    }
  },

  async asignarIndividual({ commit }, data) {
    try {
      const asignacion = await axios.get('v1/solicitudes/'+data.id+'/asignacion');
      const res = await axios.get(`/v1/solicitudes`);
      commit("setSolicitudes", res?.data?.result);
      return asignacion?.data || "";
    } catch (error) {
      return "No se pudo procesar la solicitud";
    }
  }, */

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
