import axios from "axios";

const state = {
  bodegas: null,
  bodegaSeleccionada: null,
};

const getters = {
  bodegas: (state) => state.bodegas,
  bodegaSeleccionada: (state) => state.bodegaSeleccionada,
};

const mutations = {
  setBodegas: (state, bodegas) => {
    state.bodegas = bodegas;
  },
  setBodegaSeleccionada: (state, bodegaSeleccionada) => {
    state.bodegaSeleccionada = bodegaSeleccionada;
  },
  inicializar: (state) => {
    (state.bodegas = null), (state.bodegaSeleccionada = null);
  },
};

const actions = {
  async crear({ commit, state }, data) {
    try {
      let res = await axios.post(`/v1/bodegas`, data);
      let nuevo = res?.data?.result;
      let actualizados = [...state.bodegas, nuevo];
      commit("setBodegas", actualizados);
    } catch (error) {
      return error;
    }
  },
  async editar({ commit, state }, data) {
    try {
      let res = await axios.put(`/v1/bodegas/${data.id}`, data.data);
      let copia = state.bodegas;
      let actualizado = copia.find(
        (item) => item.id === res?.data?.result?.id
      );
      let index = copia.indexOf(actualizado);
      copia.splice(index, 1, res?.data);
      commit("setBodegas", copia);
    } catch (error) {
      return error;
    }
  },
  async eliminar({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/bodegas/${data.id}`);
      let copia = state.bodegas;
      const filtrados = copia.filter(
        (item) => item.id !== res?.data?.result?.id
      );
      commit("setBodegas", filtrados);
    } catch (error) {
      commit("setBodegas", []);
      return error;
    }
  },
  async consultarXProveedor({ commit }, data) {
    try {
    const res = await axios.get(`/v1/bodegas/${data?.proveedor}/proveedor`);
      commit("setBodegas", res?.data?.result);
    } catch (error) {
      commit("setBodegas", []);
      return error;
    }
  },
  async consultarTodas({ commit }) {
    try {
    const res = await axios.get(`/v1/bodegas`);
      commit("setBodegas", res?.data?.result);
    } catch (error) {
      commit("setBodegas", []);
      return error;
    }
  },
  async consultarTodasSupervisor({ commit }) {
    try {
    const res = await axios.get(`/v1/bodegas/supervisor`);
      commit("setBodegas", res?.data?.result);
    } catch (error) {
      commit("setBodegas", []);
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
