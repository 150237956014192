<template>
  <div
    class="modal fade"
    id="modalActividades"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalActividadesLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalActividadesLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <p v-if="!listaActividades?.length">No existen actividades registradas</p>
                <div class="table-responsive" v-else>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Usuario</th>
                        <th>Equipo</th>
                        <th>Tipo</th>
                        <th>Observacion</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in listaActividades" :key="item.id">
                        <td>{{ item.fecha }}</td>
                        <td>{{ item?.usuario ?? '' }}</td>
                        <td>{{ item?.equipo?.placa ?? '' }}</td>
                        <td>{{ item?.tipoActividad ?? '' }}</td>
                        <td>{{ item.observacion }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-general-off"
              data-dismiss="modal"
            >
            <i class="ti-close"></i> Cerrar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titulo: String,
    listaActividades: [Object],
  },
};
</script>
