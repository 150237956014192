import axios from "axios";


const state = {
  atencionAdjuntos: [],
};

const getters = {
  atencionAdjuntos: (state) => state.atencionAdjuntos,
};

const mutations = {
  setAtencionAdjuntos: (state, atencionAdjuntos) => {
    state.atencionAdjuntos = atencionAdjuntos;
  },
};

const actions = {
  async consultar({ commit }, data) {
    try {
      let res = await axios.get(`/v1/atencion-adjuntos/${data.tipoSolicitud}/tipo`);
      commit("setAtencionAdjuntos", res?.data?.result);
    } catch (error) {
        commit("setAtencionAdjuntos", []);
      return error;
    }
  },
  async crear({ commit, state }, data) {
    try {
      let res = await axios.post(`/v1/atencion-adjuntos`, data);
      let nuevo = res?.data?.result;
      let actualizados = [...state.atencionAdjuntos, nuevo];
      commit("setAtencionAdjuntos", actualizados);
    } catch (error) {
      return error;
    }
  },
  async eliminar({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/atencion-adjuntos/${data.id}`);
      let copia = state.atencionAdjuntos;
      const filtrados = copia.filter(
        (item) => item.id !== res?.data?.result?.id
      );
      commit("setAtencionAdjuntos", filtrados);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
