<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              {{ bodegaSeleccionada ? "Editar bodega" : "Agregar bodega" }}
            </h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                  @submit="submit"
                  :validation-schema="formValidation"
                  v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="gerenciaVentas">Proveedor *</label>
                        <fieldset>
                          <Field
                            name="proveedor"
                            as="select"
                            class="form-control"
                            v-model="formData.proveedor"
                            :disabled="bodegaSeleccionada"
                          >
                            <option value="" disabled>
                              Seleccione un proveedor
                            </option>
                            <option
                              v-for="proveedor in proveedores"
                              :key="proveedor.id"
                              :value="proveedor.id"
                            >
                              {{ proveedor.nombreProveedor }}
                            </option>
                          </Field>
                          <MensajeValidacion :mensaje="errors.proveedor" />
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nombre">Nombre *</label>
                        <fieldset>
                          <Field
                            type="text"
                            name="nombre"
                            class="form-control"
                            placeholder="Nombre"
                            v-model.trim="formData.nombre"
                          />
                          <MensajeValidacion :mensaje="errors.nombre" />
                        </fieldset>
                      </div>
                    </div>

                    <ErrorMessage :mensaje="mensaje" v-if="error" />
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="submit"
                        id="form-submit"
                        class="btn btn-primary btn-general mr-2"
                      >
                      <i class="ti-save"></i> Guardar
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary btn-general-off"
                        @click="cerrar"
                      >
                      <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";

export default {
  name: "BodegaForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      nombre: "required",
    };
    return {
      formData: {
        nombre: "",
        proveedor: null
      },
      error: false,
      mensaje: "",
      formValidation,
    };
  },
  mounted() {
    this.consultarProveedores();
    if (this.bodegaSeleccionada) {
      this.formData = { ...this.bodegaSeleccionada };
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async submit() {
      this.error = false;
      this.loaderSpinner();
      try {
        const data = {
          ...this.formData,
        };
        if (this.bodegaSeleccionada) {
          await this.$store.dispatch("bodegas/editar", {
            id: this.bodegaSeleccionada.id,
            data: {
              nombre: this.formData.nombre
            },
          });
          await this.$store.commit("bodegas/setBodegaSeleccionada", null);
        } else {
          await this.$store.dispatch("bodegas/crear", data);
        }
        this.$router.push({ name: "Bodegas" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
    async consultarProveedores() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("proveedores/consultar");
        if (this.proveedorSeleccionado) {
          this.formData.proveedor = this.proveedorSeleccionado?.id;
        }else{
          this.formData.proveedor = this.bodegaSeleccionada?.proveedor?.id;
        }
      } catch (error) {
        alert("No se pudo realizar la consulta de provvedores");
      } finally {
        this.loader.hide();
      }
    }
  },
  computed: {
    bodegaSeleccionada() {
      return this.$store.getters["bodegas/bodegaSeleccionada"]
        ? this.$store.getters["bodegas/bodegaSeleccionada"]
        : null;
    },
    proveedorSeleccionado() {
      return this.$store.getters["proveedores/proveedorSeleccionado"]
        ? this.$store.getters["proveedores/proveedorSeleccionado"]
        : null;
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
          ? this.$store.getters["proveedores/proveedores"]
          : [];
    },
  },
};
</script>
