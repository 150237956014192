<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Lista Negra</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-8">
              <button
                @click="agregar"
                class="btn btn-general btn-primary float-right"
              >
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th>Cliente</th>
                  <th>Motivo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaNegra" :key="item.id">
                  <td>
                    <a href="javascript:void(0)" @click="eliminar(item.id)"
                      ><i class="ti-trash btn-icon-append"></i
                    ></a>
                  </td>
                  <td>{{ item.cliente }}</td>
                  <td>{{ item.motivo }}</td>
                </tr>
              </tbody>
            </table>
            <Paginacion :updatePage="updatePage"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Paginacion from '../../../../components/Paginacion.vue';
export default {
  name: "GestionListaNegra",
  components: {
    Paginacion
  },
  data() {
    return {
      pagina: 1,
    };
  },
  created() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("listaNegra/consultarListaNegraPaginado", data);
        await this.$store.dispatch(
          "listaNegra/consultarListaNegraTotalPaginas"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      this.$router.push({ name: "ListaNegraForm" });
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("listaNegra/eliminar", { id });
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
    updatePage(page){
      this.pagina = page;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    listaNegra() {
      return this.$store.getters["listaNegra/listaNegra"]
        ? this.$store.getters["listaNegra/listaNegra"]
        : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
<style></style>
