<template>
  <GestionSolicitudesTecnico />
</template>
<script>
import GestionSolicitudesTecnico from "./components/GestionSolicitudesTecnico.vue";
export default {
  name: "SolicitudesTecnico",
  components: { GestionSolicitudesTecnico }
};
</script>
