<template>
  <div
    class="modal fade"
    id="modalCajas"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCajasLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="method"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Año</th>
                            <th>Mes</th>
                            <th>Volumen</th>
                            <th>Cajas</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, $index) in data" :key="$index">
                            <td>{{ item?.anio }}</td>
                            <td>{{ item?.mes }}</td>
                            <td>{{ item?.volumen }}</td>
                            <td>{{ item?.cajas }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cerrar: { type: Function },
    data: Array,
  },
  data() {
    return {};
  },
};
</script>
