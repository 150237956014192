import axios from "axios";

const state = {
  adjuntos: [],
  adjuntoSeleccionado: null,
  mensaje: null,
};

const getters = {
  adjuntos: (state) => state.adjuntos,
  adjuntoSeleccionado: (state) => state.adjuntoSeleccionado,
  mensaje: (state) => state.mensaje,
};

const mutations = {
  setAdjuntos: (state, adjuntos) => {
    state.adjuntos = adjuntos;
  },
  setAdjuntoSeleccionado: (state, adjuntoSeleccionado) => {
    state.adjuntoSeleccionado = adjuntoSeleccionado;
  },

  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
};

const actions = {
  async consultarAdjuntos({ commit }, data) {
    try {
      let res = await axios.get(`/v1/descargas/${data.idSolicitud}`);
      commit("setAdjuntos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async descargarAdjunto({ commit }, data) {
    try {
      let resp = await axios.get(
        `/v1/descargas/${data.idSolicitudProveedorArchivo}/archivo`,
        { responseType: "arraybuffer" }
      );
      if(resp?.data){
        commit("setMensaje", "Descarga exitosa");
        return resp?.data
      }else{
        commit("setMensaje", "Descarga fallida");
        return null;
      }
      
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
