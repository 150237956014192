<template>
  <GestionSolicitudesCerradasTecnico />
</template>
<script>
import GestionSolicitudesCerradasTecnico from "./components/GestionSolicitudesCerradasTecnico.vue";
export default {
  name: "SolicitudesCerradasTecnico",
  components: { GestionSolicitudesCerradasTecnico }
};
</script>
