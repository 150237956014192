<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Solicitudes</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-8">

              <button
                  @click="exportExcel"
                  class="btn btn-primary btn-general float-right"
                  :disabled="solicitudesProvedor.length === 0"
              >
                <span class="icon-download"></span> Descargar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-table">

          <div class="row">
            <div class="col-md-3 mb-2 mb-md-0">
              <input type="date" class="form-control" v-model="fechaInicio" />
            </div>
            <div class="col-md-3 mb-2 mb-md-0">
              <input type="date" class="form-control " v-model="fechaFin" />
            </div>
            <div class="col-md-3 mb-2 mb-md-0">
              <button
                  type="button"
                  class="btn btn-secondary btn-general"
                  @click="consultarXFechas"
                  :disabled="fechaInicio && fechaFin ? false : true"
              >
                <span class="icon-search"></span> Consultar
              </button>
            </div>
            <div class="col-md-3">

            </div>
          </div>

          <div class="table-responsive" @change="consultar">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Tickets</th>
                  <th>Estado</th>
                  <th>Técnico</th>
                  <th>Tipo Solicitud</th>
                  <th>Bodega</th>
                  <th>Cliente</th>
                  <th>Fecha solicitud</th>
                  <th>Días Transcurridos</th>
                  <th>Gerencia general</th>
                  <th>Gerencia ventas</th>
                  <th>ECB</th>
                  <th>Nombre</th>
                  <th>Establecimiento</th>
                  <th>Dirección</th>
                  <th>Tipo de caso</th>
                  <th>Observación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in solicitudesProvedor" :key="item.id">
                  <td>{{ item?.solicitud?.id }}</td>
                  <td>
                    {{
                      item?.numeroTicketRelacionados?.includes("null")
                        ? ""
                        : item?.numeroTicketRelacionados
                    }}
                  </td>
                  <td>
                    <div
                      class="badge"
                      :class="
                        item?.estadoSolicitud === 'EJECUTADO'
                          ? 'badge-success'
                          : item?.estadoSolicitud === 'EN_RUTA'
                          ? 'badge-warning'
                          : 'badge-danger'
                      "
                    >
                      {{ item?.estadoSolicitud }}
                    </div>
                  </td>
                  <td>
                    <strong>{{ item?.tecnico }}</strong>
                  </td>
                  <td>
                    <strong>{{ item?.solicitud?.tipoSolicitud }}</strong>
                  </td>

                  <td>{{ item?.bodega?.nombre }}</td>
                  <td>{{ item?.solicitud?.codigoCliente }}</td>
                  <td>{{ item?.solicitud?.fechaSolicitud }}</td>
                  <td>
                    <div
                      class="badge"
                      :class="
                        item?.solicitud?.diasTranscurridosLabolables < 3
                          ? 'badge-success'
                          : item?.solicitud?.diasTranscurridosLabolables > 5
                          ? 'badge-danger'
                          : 'badge-warning'
                      "
                    >
                      {{ item?.solicitud?.diasTranscurridosLabolables }} día(s)
                    </div>
                  </td>
                  <td>{{ item?.solicitud?.gerenciaGeneral }}</td>
                  <td>{{ item?.solicitud?.gerenciaVentas }}</td>
                  <td>{{ item?.solicitud?.ecb }}</td>
                  <td>{{ item?.solicitud?.nombre }}</td>
                  <td>{{ item?.solicitud?.nombreEstablecimiento }}</td>
                  <td>{{ item?.solicitud?.direccion }}</td>
                  <td>{{ item?.solicitud?.tipoCaso }}</td>
                  <td>{{ item?.observacion }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
export default {
  name: "GestionSolicitudesTecnico",
  components: {},
  data() {
    return {
      fechaInicio: "",
      fechaFin: "",
    };
  },
  created() {
    this.$store.commit(
      "solicitudesProveedorAtendidas/setSolicitudesProveedorAtendidas",
      []
    );
  },
  mounted() {},
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarXFechas() {
      try {
        this.loaderSpinner();
        const data = {
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        };
        //if (this.$route.params.ruta === "TODOS") {
          await this.$store.dispatch(
            "solicitudesProveedorAtendidas/consultarXFechasAdmin",
            data
          );
        //}
        if (this.$route.params.ruta === "BODEGA") {
          await this.$store.dispatch(
            "solicitudesProveedorAtendidas/consultarXFechasBodega",
            data
          );
        }
        if (this.$route.params.ruta === "PROVEEDOR") {
          await this.$store.dispatch(
            "solicitudesProveedorAtendidas/consultarXFechas",
            data
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    exportExcel() {
      saveExcel({
        data: this.solicitudesProvedor,
        fileName: "solicitudes-atendidas",
        columns: [
          { field: "solicitud.id", title: "Id" },
          { field: "numeroTicketRelacionados", title: "Tickets relacionados" },
          { field: "estadoSolicitud", title: "Estado" },
          { field: "solicitud.tipoSolicitud", title: "Tipo" },
          { field: "bodega.nombre", title: "Bodega" },
          { field: "solicitud.codigoCliente", title: "Cliente" },
          { field: "solicitud.fechaSolicitud", title: "Fecha solicitud" },
          {
            field: "solicitud.diasTranscurridosLabolables",
            title: "Días Transcurridos",
          },
          { field: "solicitud.gerenciaGeneral", title: "Gerencia general" },
          { field: "solicitud.gerenciaVentas", title: "Gerencia ventas" },
          { field: "solicitud.ecb", title: "ECB" },
          { field: "solicitud.nombre", title: "Nombre" },
          {
            field: "solicitud.nombreEstablecimiento",
            title: "Establecimiento",
          },
          { field: "solicitud.direccion", title: "Dirección" },
          { field: "solicitud.tipoCaso", title: "Tipo de caso" },
        ],
      });
    },
  },

  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    solicitudesProvedor() {
      return this.$store.getters[
        "solicitudesProveedorAtendidas/solicitudesProveedorAtendidas"
      ]
        ? this.$store.getters[
            "solicitudesProveedorAtendidas/solicitudesProveedorAtendidas"
          ]
        : [];
    },
    rol() {
      return this.$store.state.auth.rol;
    },
  },
};
</script>
<style></style>
