<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-12">
            <h3 class="font-weight-bold">Procesar requerimiento</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">

          <form name="f1" id="formElement">

            <CabeceraRequerimiento/>

            <div class="mt-4 ml-4">
              <h5 class="card-title">Datos Equipos</h5>
              <p class="text-muted" v-if="!equipos.length">
                No se recuperaron equipos fríos en el cliente, por favor póngase
                en contacto con el Administrador
              </p>

              <!--<BusquedaXPlaca
                  :seleccionarEquipo="seleccionarEquipo"
                  origen="RETIRO"
              />-->

              <div class="form-check" v-for="item in equipos" :key="item.id">
                <input
                    class="form-check-input ml-1"
                    type="checkbox"
                    :id="item.id"
                    :value="item.id"
                    v-model="idEquipos"
                />
                <label class="form-check-label" :for="item.id">
                  <strong>Placa:</strong>
                  {{ item?.placa }}
                  <strong>Serie:</strong>
                  {{ item?.serie }}
                  <strong>Capacidad (cajas):</strong>
                  {{ item?.capacidadCajas }}
                  <strong>Capacidad (pies):</strong>
                  {{ item?.capacidadPies }}
                </label>
              </div>
            </div>

            <div class="mt-4 ml-4">
              <h5 class="card-title">Atender requerimiento</h5>

              <div class="form-check">
                <input
                    class="form-check-input ml-1"
                    type="radio"
                    id="ejecutado"
                    value="EJECUTADO"
                    v-model="requerimiento"
                    @click="seleccionarRequerimiento"
                />
                <label class="form-check-label" for="ejecutado">
                  EJECUTADO
                </label>
              </div>
              <div class="form-check">
                <input
                    class="form-check-input ml-1"
                    type="radio"
                    id="fallido"
                    value="FALLIDO"
                    v-model="requerimiento"
                    @click="seleccionarRequerimiento"
                />
                <label class="form-check-label" for="fallido"> FALLIDO </label>
              </div>

              <div class="form-group" v-if="mostrarMotivo">
                <label>Motivo</label>
                <select class="form-control" v-model="motivo">
                  <option value="" disabled>Seleccione un motivo</option>
                  <option value="DIRECCIÓN NO ENCONTRADA">
                    DIRECCIÓN NO ENCONTRADA
                  </option>
                  <option value="EQUIPO NO ENCONTRADO">
                    EQUIPO NO ENCONTRADO
                  </option>
                  <option value="OTRO MOTIVO">OTRO MOTIVO</option>
                </select>
              </div>

              <h5 v-if="variable">Novedad</h5>
              <CustomVariableMultiple
                  :variable="variable"
                  :selectVariable="selectEjecutadoFallido"
              />
              <CustomAdjuntos
                  :tipoSolicitud="
                  solicitudProveedorSeleccionada.solicitud.tipoSolicitud
                "
                  :uploadFile="uploadFile"
              />

              <div class="mb-3" v-if="archivosArray.length > 0">
                <label for="formFile" class="form-label"
                >Lista de archivos</label
                >
                <ul>
                  <li v-for="(archivo, $index) in archivosArray" :key="$index">
                    {{ archivo?.name }}
                    <a
                        href="javascript:void(0)"
                        class="text-danger"
                        @click="eliminarArchivo(archivo)"
                    ><i class="ti-close"></i
                    ></a>
                  </li>
                </ul>
              </div>

              <div class="form-group">
                <label for="exampleFormControlTextarea1">Observación</label>
                <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="observacion"
                ></textarea>
              </div>

              <button
                  type="button"
                  class="btn btn-primary btn-block mt-3"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  @click="confirmar"
                  :disabled="!archivosArray?.length"
              >
                <i class="ti-check-box"></i> Atender requerimiento
              </button>
            </div>

          </form>
          <Modal :mensaje="mensaje" :method="procesar"/>
          <ModalMessages
              :mensaje="mensajeProceso"
              :titulo="titulo"
              :method="cerrar"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import Modal from "../../../components/Modal.vue";
import CabeceraRequerimiento from "./CabeceraRequerimiento.vue";
import CustomAdjuntos from "../../../components/CustomAdjuntos.vue";
import CustomVariableMultiple from "../../../components/CustomVariableMultiple.vue";

export default {
  components: {
    ModalMessages,
    CabeceraRequerimiento,
    Modal,
    CustomAdjuntos,
    CustomVariableMultiple,
  },
  data() {
    return {
      observacion: "",
      idEquipos: [],
      requerimiento: "",
      archivo: null,
      mensaje: "",
      mensajeProceso: null,
      titulo: "",
      ejecutarCerrar: false,
      archivosArray: [],
      variable: null,
      infoAdicional: [],
      mostrarMotivo: false,
      motivo: "",
    };
  },
  created() {
    this.$store.commit("equipos/setEquipos", null);
  },
  mounted() {
    this.consultarEquiposCliente();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarEquiposCliente() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("equipos/consultarEquipoXCliente", {
          cliente:
          this.solicitudProveedorSeleccionada?.solicitud?.codigoCliente,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarRequerimiento(e) {
      if (e.target.value === "FALLIDO") {
        this.mostrarMotivo = true;
        this.variable = `FALLIDO_${this.solicitudProveedorSeleccionada.solicitud.tipoSolicitud}`;
      } else {
        this.mostrarMotivo = false;
        this.variable = `EJECUTADO_${this.solicitudProveedorSeleccionada.solicitud.tipoSolicitud}`;
      }
    },
    uploadFile(file) {
      const selectedFile = file?.target?.files[0];
      if (!selectedFile) return;
      const findedFile = this.archivosArray?.findIndex(
          (item) => item.name === selectedFile.name
      );

      let arraySelectedFiles = [];
      if (findedFile === -1) {
        arraySelectedFiles = [...this.archivosArray, selectedFile];
      } else {
        arraySelectedFiles = [...this.archivosArray];
      }
      this.archivosArray = arraySelectedFiles;
    },
    eliminarArchivo(archivo) {
      const archivosFiltrados = this.archivosArray?.filter(
          (item) => item !== archivo
      );
      this.archivosArray = archivosFiltrados;
    },
    confirmar() {
      this.mensaje = "¿Desea continuar con el proceso de retiro?";
    },
    async procesar() {
      if (
          this.requerimiento === "" ||
          !this.solicitudProveedorSeleccionada.id ||
          (this.requerimiento === "EJECUTADO" && this.idEquipos.length === 0) ||
          this.infoAdicional.length === 0
      ) {
        this.mensajeProceso = [
          "Por favor ingrese toda la información requerida antes de continuar con el proceso de retiro",
        ];
        this.titulo = "Advertencia";
        this.ejecutarCerrar = false;
        document.getElementById("modalButton").click();
        return;
      }
      const formData = new FormData();
      this.archivosArray?.map((item) => formData.append("file", item));
      formData.append("estadoSolicitud", this.requerimiento);
      formData.append(
          "motivo",
          this.requerimiento === "EJECUTADO" ? "" : this.motivo
      );
      formData.append("infoAdicional", this.infoAdicional);
      formData.append(
          "idSolicitudProveedor",
          this.solicitudProveedorSeleccionada.id
      );
      formData.append(
          "idEquipoFrio",
          this.requerimiento === "EJECUTADO" ? this.idEquipos : []
      );
      formData.append("observacion", this.observacion);

      try {
        this.loaderSpinner();

        const resp = await this.$store.dispatch(
            "solicitudesProveedor/procesarRetiro",
            {
              data: formData,
            }
        );
        this.mensajeProceso = [resp];
        this.titulo = "Retiro";
        this.ejecutarCerrar = true;
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensaje = error;
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      if (this.ejecutarCerrar) {
        this.$router.go(-1);
      }
    },
    seleccionarEquipo(datos) {
      if (datos.tipo === "ADD") {
        this.idEquipos.push(datos.valor);
      }
      if (datos.tipo === "DELETE") {
        const idEquiposFiltrados = this.idEquipos.filter(
            (item) => item !== datos.valor
        );
        this.idEquipos = idEquiposFiltrados;
      }
    },
    selectEjecutadoFallido(infoAdicional) {
      this.infoAdicional = infoAdicional;
    },
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
          "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
          ? this.$store.getters[
              "solicitudesProveedor/solicitudProveedorSeleccionada"
              ]
          : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
          ? this.$store.getters["equipos/equipos"]
          : {};
    },
  },
};
</script>
