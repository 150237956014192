<template>

  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <div class="menu-sl">
      <div>
        <a class="navbar-brand brand-logo ml-0 mr-0 pl-0 pr-0" style="width: 20%;" href="/"
          ><img
            src="../assets/images/imgs/Icono-archivos-cargados-over.png"
            class="w-100 p-3"
            alt="logo"
        /></a>
        <a class="navbar-brand brand-logo ml-0 mr-0 pl-0 pr-0" style="width: 80%;" href="/"
          ><img
            src="../assets/images/imgs/LogoHorizontalfondoblanco.png"
            class="w-100 p-3"
            alt="logo"
            style="background-color: #fff;"
        /></a>
      </div>
      <ul class="nav">
        <li v-for="(item, index) in menu" :key="index" class="nav-item">
          <div v-if="!item.submenus.length">
            <router-link class="nav-link" href="#" :to="{ name: item?.componente }">
              <i :class="item?.icon"></i>
              <span class="menu-title">{{ item?.name }}</span>
            </router-link>
          </div>
          <div v-else>
            <a
                class="nav-link collapsed"
                data-toggle="collapse"
                :href="'#ui-'+ index"
                aria-expanded="false"
                :aria-controls="'ui-'+index"
            >
              <i :class="item?.icon"></i>
              <span class="menu-title">{{ item.name }}</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" :id="'ui-'+index" style="">
              <ul class="nav flex-column sub-menu">
                <li v-for="(submenu, index) in item.submenus" :key="index" class="nav-item">
                  <router-link class="nav-link" href="#" :to="{ name: submenu?.componente}">
                    <span class="menu-title">{{ submenu?.name }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <div class="text-center">
            <small>
              <hr/>
              Build 5.1.0
            </small>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {

  mounted() {
    this.consultarMenu();
  },

  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },

    async consultarMenu() {
      await this.$store.dispatch("menu/consultar");
    }

  },
  computed: {
    rol() {
      if (!this?.$store?.state?.auth?.rol) {
        return;
      }     
      return this.$store.state.auth.rol;
    },

    menu() {
      return this.$store.getters["menu/menu"]
          ? this.$store.getters["menu/menu"]
          : false;
    },
  },
};
</script>
