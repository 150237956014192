import axios from "axios";

const state = {
    regularizaciones: null,
    regularizacionSeleccionada: null,
    totalPaginas: null,
};

const getters = {
    regularizaciones: (state) => state.regularizaciones,
    regularizacionSeleccionada: (state) => state.regularizacionSeleccionada,
    totalPaginas: (state) => state.totalPaginas,
};

const mutations = {
    setRegularizaciones: (state, regularizaciones) => {
        state.regularizaciones = regularizaciones;
    },
    setRegularizacionSeleccionada: (state, regularizacionSeleccionada) => {
        state.regularizacionSeleccionada = regularizacionSeleccionada;
    },

    setTotalPaginas: (state, data) => {
        state.totalPaginas = data;
    },
};

const actions = {

    async consultarRegularizaciones({commit}) {
        try {
            let res = await axios.get(`/v1/regularizaciones`);
            commit("setRegularizaciones", res?.data?.result.solicitudes);
        } catch (error) {
            return error;
        }
    },
    async consultarXFechas({commit}, data) {
        try {
            let res = await axios.get(`/v1/regularizaciones/${data.fechaInicio}/${data.fechaFin}`);
            commit("setRegularizaciones", res?.data?.result.solicitudes);
        } catch (error) {
            return error;
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
