<template>
    <div class="container-scroller bg-cool">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="brand-logo">
                <img
                    src="./../../../assets/images/imgs/logo-fondo-marino.png"
                    alt="logo"
                    class="d-block"
                />
              </div>
              <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                <h4 class="font-weight-bold-blue">Bienvenido</h4>
                <h6 class="font-weight-light">Ingrese el Código enviado a su correo.</h6>
                <Form @submit="login" :validation-schema="formValidation" v-slot="{ errors }">
                  <div class="form-group mb-3">
                    <fieldset>
                      <Field type="text" name="otp" id="otp" class="form-control"
                            placeholder="Otp" v-model.trim="formData.otp"/>
                      <MensajeValidacion :mensaje="errors.otp"/>
                      <MensajeValidacion :mensaje="mensaje"  v-if="error"/>
                    </fieldset>
                  </div>
                  <div class="mb-3">
                    <button
                      class="btn btn-block btn-primary btn font-weight-medium auth-form-btn"
                      type="submit"
                    >
                      Iniciar Sesión
                    </button>
                    <p class="disabled-link" v-bind:class="{ 'enabled-link': isLinkEnabled }">
                      <a class="font-weight-bold" @click="otpForwarding" v-if="isLinkEnabled">Reenviar Código</a>
                      <span v-else>Reenviar Código en {{ countdown }} segundos</span>
                    </p>
                  </div>  
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </template>
<script>
import {Form, Field} from "vee-validate";
import MensajeValidacion from "./../../../components/MensajeValidacion.vue";

  export default {
    name: "Otp",
    components: {
      Form,
      Field,
      MensajeValidacion,
    },
    data() {
      const formValidation = {
        otp: "required"
      };
      return {
        formData: {
          otp:''
        },
        error: false,
        formValidation,
        isLinkEnabled: false,
        countdown: 60,
      };
    },
    methods: {
       async login(){
          try {           
            this.formData.email = this.email;
            const resp = await this.$store.dispatch("auth/otp", this.formData);
            const { error,message } = resp;
            if (error) {
              this.mensaje = message;
              this.error = true;
              return;
            }
            
            if(this.cambiarClave){
              this.$router.push("/cambiar-clave");
              return;
            }
            await this.choosePage(resp?.data?.result?.rol);
            window.sessionStorage.removeItem("email");
          } catch (error) {
            this.$store.dispatch("auth/logout");
          }
        },
        async otpForwarding(){
          try {           
            this.formData.email = this.email;
            const resp = await this.$store.dispatch("auth/otpForwarding", this.formData);
            const { error,message } = resp;
            if (error) {
              this.mensaje = message;
              this.error = true;
              return;
            }
          } catch (error) {
            this.$store.dispatch("auth/logout")
          }
        },

        async choosePage(role){     
            switch (role) {
                case "ROLE_ADMIN":
                    this.$router.replace({ name: "Equipos" });
                    break;
                case "ROLE_BODEGA":
                    this.$router.replace({ name: "SolicitudesProveedor" });
                    break;
                case "ROLE_TECNICO":
                    await this.$store.dispatch("usuariosBodega/consultarBodegaTecnico");
                    this.$router.replace({ name: "DashboardTecnico" });
                    break;
                case "ROLE_TRANSPORTE":
                    await this.$store.dispatch("usuariosBodega/consultar");
                    this.$router.replace({ name: "SolicitudesTransporte" });
                    break;
                case "ROLE_ANALISTA_PRINCIPAL":
                    this.$router.replace({ name: "Equipos" });
                    break;
                case "ROLE_SUPERVISOR_TECNICO":
                    this.$router.replace({ name: "Equipos" });
                    break;
                case "ROLE_CXC":
                    this.$router.replace({ name: "AnalisisMultiple" });
                    break;
                case "ROLE_EVENTO":
                    this.$router.replace({ name: "EventosUsuario" });
                    break;
                default:
                    this.$router.push({ name: "Login" });
                    break;
            }
        },
        async startCountdown() {
          this.isLinkEnabled = false;
          this.countdown = 60;
          const interval = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              this.isLinkEnabled = true;
              clearInterval(interval);
            }
          }, 1000);
        },
    },
    computed: {
      email() {
        return window.sessionStorage.getItem('email');
      },
      cambiarClave() {
      return this.$store.getters["auth/cambiarClave"]
        ? this.$store.getters["auth/cambiarClave"]
        : null;
    },
    },mounted() {
      this.startCountdown();
    },
  };
  </script>