import axios from "axios";

const state = {
  proveedores: null,
  proveedorSeleccionado: null,
};

const getters = {
  proveedores: (state) => state.proveedores,
  proveedorSeleccionado: (state) => state.proveedorSeleccionado,
};

const mutations = {
  setProveedores: (state, proveedores) => {
    state.proveedores = proveedores;
  },
  setProveedorSeleccionado: (state, proveedorSeleccionado) => {
    state.proveedorSeleccionado = proveedorSeleccionado;
  },
  inicializar: (state) => {
    (state.proveedores = null), (state.proveedorSeleccionado = null);
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/proveedores`);
      commit("setProveedores", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async crear({ commit, state }, data) {
    try {
      let res = await axios.post(`/v1/proveedores`, data);
      let nuevo = res?.data?.result;
      let actualizados = [...state.proveedores, nuevo];
      commit("setProveedores", actualizados);
    } catch (error) {
      return error;
    }
  },
  async editar({ commit, state }, data) {
    try {
      let res = await axios.put(`/v1/proveedores/${data.id}`, data.data);
      let copia = state.proveedores;
      let actualizado = copia.find(
        (item) => item.id === res?.data?.result?.id
      );
      let index = copia.indexOf(actualizado);
      copia.splice(index, 1, res?.data);
      commit("setProveedores", copia);
    } catch (error) {
      return error;
    }
  },
  async eliminar({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/proveedores/${data.id}`);
      let copia = state.proveedores;
      const filtrados = copia.filter(
        (item) => item.id !== res?.data?.result?.id
      );
      commit("setProveedores", filtrados);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
