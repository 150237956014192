<template>
  <GestionUsuariosVehiculosTransporte/>
</template>

<script>
import GestionUsuariosVehiculosTransporte from "@/views/admin/usuarios/components/GestionUsuariosVehiculosTransporte";

export default {
  components: {GestionUsuariosVehiculosTransporte}
}
</script>
