<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-12">
            <h3 class="font-weight-bold">Reasignar requerimientos</h3>
          </div>
        </div>
        <div class="white-content-general mt-4">

          <div>
            <p>Bodega</p>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <fieldset>
                  <select
                      name="bodega"
                      class="form-control"
                      v-model="bodega"
                      @change="seleccionarBodega"
                  >
                    <option value="" disabled>Seleccione una bodega</option>
                    <option
                        v-for="bodega in bodegas"
                        :key="bodega.id"
                        :value="bodega.id"
                    >
                      {{ bodega.nombre }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>
          <div>
            <p>Solicitud</p>
          </div>
          <div class="row">
            <div class="col-md-4">
              <input
                  type="text"
                  class="form-control"
                  v-model="solicitud"
                  placeholder="Solicitud"
              />
            </div>
            <div class="col-md-3">
              <a href="javascript:void(0)" @click="agregar">Agregar</a>
            </div>
          </div>
          <br/>
          <div class="table-responsive mb-3" v-if="requerimientos.length > 0">
            <table class="table table-sm">
              <thead>
              <tr>
                <th></th>
                <th>Solicitud</th>
                <th>Bodega</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in requerimientos" :key="item.id">
                <td>
                  <a
                      href="javascript:void(0)"
                      class="text-danger"
                      @click="eliminar(item)"
                  ><i class="ti-close"></i
                  ></a>
                </td>
                <td>{{ item?.solicitud }}</td>
                <td>{{ item?.nombreBodega }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <br/>
          <div class="row">
            <div class="col-lg-12">
              <button
                  type="button"
                  id="form-submit"
                  class="btn btn-primary btn-general mr-2"
                  @click="guardar"
                  :disabled="!requerimientos.length"
              >
                <i class="ti-save"></i> Guardar
              </button>
            </div>
          </div>
          <ModalMessages
              :mensaje="mensajeProceso"
              titulo="Reasignar requerimiento"
          />

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "ReasignarRequerimiento",
  components: {ModalMessages},
  data() {
    return {
      bodega: "",
      nombreBodega: "",
      solicitud: "",
      requerimientos: [],
      mensajeProceso: null,
      solicitudes: []
    };
  },
  created() {
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarBodegas() {
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      }
    },
    seleccionarBodega(e) {
      this.nombreBodega = e.target.options[e.target.selectedIndex].innerHTML;
    },
    agregar() {
      if (!this.solicitud || !this.bodega) {
        return;
      }
      const nuevoRequerimiento = {
        solicitud: this.solicitud,
        bodega: this.bodega,
        nombreBodega: this.nombreBodega,
      };
      this.requerimientos.push(nuevoRequerimiento);
      this.solicitudes.push(this.solicitud);
      this.solicitud = "";
    },
    eliminar(requerimiento) {
      const requerimientosFiltrados = this.requerimientos?.filter(
          (item) => item !== requerimiento
      );
      this.requerimientos = requerimientosFiltrados;
    },
    async guardar() {
      const data = {
        bodega: this.bodega,
        solicitudesProveedor: this.solicitudes,
      };
      try {
        this.loaderSpinner();
        const resp = await this.$store.dispatch(
            "solicitudesProveedor/reasignarRequerimiento",
            {
              data,
            }
        );

        if (resp === "ERROR") {
          this.mensajeProceso = ["No se pudo procesar el Reasignamiento"];
        } else {
          this.mensajeProceso = [resp];
          this.solicitud = "";
          this.bodega = "";
          this.nombreBodega = "";
          this.requerimientos = [];
          this.solicitudes = [];
        }
        this.ejecutarCerrar = false;
        document.getElementById("modalButton").click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
  },
};
</script>
<style></style>
