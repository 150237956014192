<template>
  <div>
    <div class="white-content-general mb-4">
      <div class="row">
        <div class="col-md-4">
          <p class="lh-m mb-0">
            <span class="card-title">Total registros: </span
            ><span class="txt-celeste font-weight-bold fs-l">{{
              totalRegistros
            }}</span>
          </p>
        </div>
        <div class="col-md-8">
          <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#modalFiltros"
              data-backdrop="static"
              data-keyboard="false"
              class="btn btn-primary btn-general float-right mb-2 mb-md-0"
          >
            <i class="ti-filter menu-icon"></i> Filtrar</a
          >

          <button
              v-if="rol === 'ROLE_ADMIN'"
              @click="agregar"
              class="btn btn-primary btn-general float-right mr-2"
          >
            <span class="icon-plus"></span> Agregar
          </button>

          <button
              v-if="
                    rol === 'ROLE_BODEGA' || rol === 'ROLE_ADMIN' || 'ROLE_ANALISTA_PRINCIPAL'
                  "

              @click="descargar"
              class="btn btn-primary btn-general float-right mr-2"
          >
            <span class="icon-download"></span> Descargar
          </button>

          <button
              v-if="rol === 'ROLE_ADMIN'"
              @click="descargarMovimientos"
              class="btn btn-primary btn-general float-right mr-2"
          >
            <span class="icon-download"></span> Movimientos
          </button>
        </div>
      </div>
    </div>

    <div class="white-content-table">
      <div class="table-responsive">
        <table class="table table-striped mb-3">
          <thead>
          <tr>
            <th></th>
            <th>Ubicación</th>
            <th></th>
            <th>Cliente</th>
            <th>Días almacenados</th>
            <th>Placa</th>
            <th>Serie</th>
            <th>Marca</th>
            <th>Modelo</th>
            <th>Logo</th>
            <th>Año fabricación</th>
            <th>Tipo</th>
            <th>Fabricante Genesis</th>
            <th>Status Neveras</th>
            <th>Valor Libros</th>
            <th>Valor Comercial</th>
            <th>Status Libros</th>
            <th>Año Adquisición</th>
            <th>Capacidad Botellas</th>
            <th>Capacidad Cajas</th>
            <th>Capacidad Pies</th>
            <th>Activo Fijo</th>
            <th>Número Equipo</th>
            <th>VH</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in equipos" :key="item.id">
            <td>
              <a
                  v-if="rol === 'ROLE_ADMIN'"
                  href="javascript:void(0)"
                  @click="editarUbicacion(item)"
                  data-toggle="modal"
                  data-target="#modalEditUbicacion"
                  data-backdrop="static"
                  data-keyboard="false"
              ><i class="ti-location-pin"></i></a
              >&nbsp;
              <a
                  href="javascript:void(0)"
                  @click="editar(item)"
                  v-if="rol === 'ROLE_ADMIN'"
              ><i class="ti-pencil"></i></a
              >&nbsp;
              <a
                  href="javascript:void(0)"
                  @click="editarEquipoProveedor(item)"
                  v-if="
                    rol === 'ROLE_BODEGA' || rol === 'ROLE_ANALISTA_PRINCIPAL'
                  "
              ><i class="ti-pencil"></i></a
              >&nbsp;
              <a
                  href="javascript:void(0)"
                  @click="eliminar(item.id)"
                  v-if="rol === 'ROLE_ADMIN'"
              ><i class="ti-trash txt-rojo"></i
              ></a>
              &nbsp;
              <a
                  href="javascript:void(0)"
                  @click="consultarMovimientos(item.id)"
                  data-toggle="modal"
                  data-target="#modalMovimientos"
              ><i class="ti-exchange-vertical"></i
              ></a>
            </td>
            <td>{{ item?.ubicacion }} {{ item?.bodegaDescripcion ?? "" }}</td>
            <td>
              <div
                  class="badge"
                  :class="
                    item.estadoEquipo === 'DISPONIBLE'
                      ? 'badge-success'
                      : 'badge-secondary'
                  "
              >
                {{ item.estadoEquipo }}
              </div>
            </td>
            <td>{{ item.cliente }}</td>
            <td>{{ item?.diasAlmacenados }}</td>
            <td style="font-weight: bold">
              {{ item.placa }}
            </td>
            <td>{{ item.serie }}</td>
            <td>{{ item.marca }}</td>
            <td>{{ item.modelo }}</td>
            <td>{{ item.logo }}</td>
            <td>{{ item.anioFabricacion }}</td>
            <td>{{ item.tipoEquipo }}</td>
            <td>{{ item.fabricanteGenesis }}</td>
            <td>{{ item.statusNeveras }}</td>
            <td>{{ item.valorLibros }}</td>
            <td>{{ item.valorComercial }}</td>
            <td>{{ item.statusLibros }}</td>
            <td>{{ item.anioAdquisicion }}</td>
            <td>{{ item.capacidadBotellas }}</td>
            <td>{{ item.capacidadCajas }}</td>
            <td>{{ item.capacidadPies }}</td>
            <td>{{ item.activoFijo }}</td>
            <td>{{ item.numeroEquipo }}</td>
            <td>{{ item.vh }}</td>
          </tr>
          </tbody>
        </table>

        <paginacion :updatePage="updatePage"/>

        <Filtros
            titulo="Seleccionar filtros"
            :consultar="consultarXFiltros"
            entidad="equipos_frios_view"
        />

        <Modal :mensaje="mensaje" :method="procesarEliminacion"/>

        <EditUbicacionEquipo
            titulo="Actualizar ubicación del equipo"
            :actualizar="actualizar"
        />

        <Movimientos :equipo="equipo" titulo="Movimientos"/>
      </div>
    </div>
  </div>
</template>
<script>
import Movimientos from "@/components/Movimientos.vue";
import Modal from "@/components/Modal.vue";
import Filtros from "@/components/CustomFilters.vue";
import EditUbicacionEquipo from "@/views/admin/equipos/components/EditUbicacionEquipo.vue";
import Paginacion from "@/components/Paginacion.vue";

export default {
  name: "EquiposFriosData",
  components: {Paginacion, Modal, EditUbicacionEquipo, Filtros, Movimientos},
  props: {
    rol: String,
  },
  data() {
    return {
      pagina: 1,
      mensaje: "",
      id: null,
      valorBusqueda: "",
      tipoFiltro: "",
      habilitarInputBusqueda: false,
      habilitarBodegas: false,
      bodega: "",
      habilitarEstados: false,
      estadoEquipo: "",
      totalRegistros: 0,
      modoFiltros: false,
      condiciones: [],
      paginaFiltros: 1,
      equipo: null,
    };
  },
  mounted() {
    this.consultarXFiltros(this.data);
  },

  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },

    async agregar() {
      await this.$store.commit("equipos/setEquipoSeleccionado", null);
      this.$router.push({name: "EquipoForm"});
    },
    async editar(equipo) {
      await this.$store.commit("equipos/setEquipoSeleccionado", equipo);
      this.$router.push({name: "EquipoForm"});
    },
    async editarEquipoProveedor(equipo) {
      await this.$store.commit("equipos/setEquipoSeleccionado", equipo);
      this.$router.push({name: "EditEquipoProveedor"});
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("equipos/eliminarEquipo", {id: id});

            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },

    async procesarEliminacion() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/eliminarEquipo", {id: this.id});
        this.id = null;
        this.$swal({
          text: "Registro eliminado con éxito.",
          icon: "success",
        });
      } catch (error) {
        this.$swal("", error.message, "error");
      } finally {
        this.loader.hide();
      }
    },

    editarUbicacion(equipo) {
      this.$store.commit("equipos/setEquipoSeleccionado", equipo);
    },

    async actualizar(data) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/editarUbicacionEquipo", {
          data: {
            codigoCliente: data.codigoCliente,
            bodega: data.bodega,
            ubicacion: data.tipoUbicacion,
            estadoEquipo: data.estadoUbicacion,
          },
          id: data.id,
        });
      } catch (error) {
        this.$swal("", error.message, "error");
      } finally {
        this.loader.hide();
      }
    },

    async consultarXFiltros(data) {
      this.condiciones = data?.condiciones;
      this.modoFiltros = true;

      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/consultarEquipoXFiltros", {
          pagina: data?.inicializarPagina ? 1 : this.paginaFiltros,
          condiciones: data?.condiciones || [],
        });

        this.totalRegistros = this.$store.getters["equipos/totalRegistros"];
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async descargar() {
      if (this.condiciones === undefined) {
        this.condiciones = [];
      }
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("exportar/exportar", {
          entidad: "inventario_view",
          filtro: {
            condiciones: this.condiciones
          }
        });
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `inventario.csv`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async descargarMovimientos() {
      if (this.condiciones === undefined) {
        this.condiciones = [];
      }
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("exportar/exportar", {
          entidad: "movimientos_view",
          filtro: {
            condiciones: this.condiciones
          }
        });
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `movimientos.csv`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async consultarMovimientos(equipo) {
      this.equipo = equipo;
    },
    updatePage(page) {
      this.pagina = page;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
          ? this.$store.getters["equipos/equipos"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        if (this.modoFiltros) {
          this.paginaFiltros = newPage;
          this.consultarXFiltros({
            condiciones: this.condiciones,
            inicializarPagina: false,
          });
          return;
        }
        this.consultar(newPage);
      }
    },
  },
};
</script>
