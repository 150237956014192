<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Proceso de Regularizaciones</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>
        
        <div class="white-content-general mb-4">
          <div class="row" v-if="regularizacionesFiltradas.length">
            <div class="col-md-12">
              <div class="form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="codigoCliente"
                  value="CLIENTE"
                  v-model="tipoFiltroLocal"
                  @click="mostrarBusquedaInput"
                />
                <label class="form-check-label radio-margin" for="codigoCliente">
                  Código cliente
                </label>

                <input
                  class="form-check-input"
                  type="radio"
                  id="ticket"
                  value="TICKET"
                  v-model="tipoFiltroLocal"
                  @click="mostrarBusquedaInput"
                />
                <label class="form-check-label radio-margin" for="ticket">
                  Ticket
                </label>
                <input
                  class="form-check-input"
                  type="radio"
                  id="tipoCaso"
                  value="TIPO"
                  v-model="tipoFiltroLocal"
                  @click="mostrarTipoCaso"
                />
                <label class="form-check-label radio-margin" for="tipoCaso">
                  Tipo caso
                </label>
                <input
                  class="form-check-input"
                  type="radio"
                  id="fechas"
                  value="FECHAS"
                  v-model="tipoFiltroLocal"
                  @click="mostrarFechas"
                />
                <label class="form-check-label radio-margin" for="fechas">
                  Fechas
                </label>
                <div class="form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="todos"
                    value="TODOS"
                    v-model="tipoFiltroLocal"
                    @click="consultarTodos()"
                  />
                  <label class="form-check-label radio-margin" for="todos">
                    Todos
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="input-group col-md-6 mt-2 no-padding"
            v-if="regularizacionesFiltradas.length && habilitarInputBusqueda"
          >
            <input
              type="text"
              class="form-control"
              v-model="valorBusqueda"
              aria-label="Text input with segmented dropdown button"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-outline-secondary btn-general btn-buscar"
                @click="buscarEquipo"
                :disabled="
                  valorBusqueda.length === 0 || tipoFiltroLocal === 'TODOS'
                "
              >
              <span class="icon-search"></span> Buscar
              </button>
            </div>
          </div>
          <div class="row mt-2" v-if="habilitarTipoCaso">
            <div class="col-md-6">
              <div class="form-group">
                <fieldset>
                  <select
                    name="tipoCaso"
                    class="form-control"
                    v-model="valorBusqueda"
                    @change="buscarEquipo"
                  >
                    <option value="" disabled>Seleccione un tipo</option>
                    <option value="CHATBOT">CHATBOT</option>
                    <option value="BEESCARE">BEESCARE</option>
                    <option value="COOL INVENTORY">COOL INVENTORY</option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="row mt-2" v-if="habilitarFechas">
            <div class="col-md-3">
              <input type="date" class="form-control" v-model="fechaInicio" />
            </div>
            <div class="col-md-3 mb-2">
              <input type="date" class="form-control" v-model="fechaFin" />
            </div>
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-secondary"
                @click="consultarXFechas"
                :disabled="fechaInicio && fechaFin ? false : true"
              >
              <span class="icon-list"></span> Consultar
              </button>
            </div>
          </div>
        </div>
        
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>Ticket</th>
                  <th>Cliente</th>
                  <th>Tipo</th>
                  <th>Fecha de solicitud</th>
                  <th>Dias Trascurridos</th>
                  <th>Nivel de riesgo</th>
                  <th>Capacidad</th>
                  <th>Mediana</th>
                  <th>Gerencia general</th>
                  <th>Gerencia ventas</th>
                  <th>ECB</th>
                  <th>Nombre</th>
                  <th>Establecimiento</th>
                  <th>Dirección</th>
                  <th>Tipo de caso</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in regularizacionesFiltradas" :key="item.id">
                  <td>
                    <a href="javascript:void(0)"
                      ><i class="ti-location-pin"></i></a
                    >
                  </td>
                  <td>{{ item.id }}</td>
                  <td>{{ item.numeroTicket }}</td>
                  <td>{{ item.codigoCliente }}</td>
                  <td>{{ item.tipoSolicitud }}</td>
                  <td>{{ item.fechaSolicitud }}</td>
                  <td>
                    <div
                      class="badge"
                      :class="
                        item.diasTranscurridosLabolables < 3
                          ? 'badge-success'
                          : item.diasTranscurridosLabolables > 5
                          ? 'badge-danger'
                          : 'badge-warning'
                      "
                    >
                      {{ item.diasTranscurridosLabolables }} día(s)
                    </div>
                  </td>
                  <td>{{ item.prioridad }}</td>
                  <td>{{ item.capacidadEeffMediana }}</td>
                  <td>{{ item.medianaUltimoTrimestre }}</td>
                  <td>{{ item.gerenciaGeneral }}</td>
                  <td>{{ item.gerenciaVentas }}</td>
                  <td>{{ item.ecb }}</td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.nombreEstablecimiento }}</td>
                  <td>{{ item.direccion }}</td>
                  <td>{{ item.tipoCaso }}</td>
                </tr>
              </tbody>
            </table>
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionRegularizaciones",
  components: {},
  data() {
    return {
      mensaje: "",
      valorBusqueda: "",
      regularizacionesFiltradas: [],
      tipoFiltroLocal: "",
      habilitarTipoCaso: false,
      habilitarInputBusqueda: false,
      habilitarFechas: false,
      fechaInicio: "",
      fechaFin: "",
    };
  },
  mounted() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      this.valorBusqueda = "";
      try {
        await this.$store.dispatch(
          "regularizaciones/consultarRegularizaciones"
        );
        this.regularizacionesFiltradas = this.regularizaciones;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    mostrarBusquedaInput() {
      this.habilitarTipoCaso = false;
      this.habilitarInputBusqueda = true;
      this.habilitarFechas = false;
    },
    mostrarTipoCaso() {
      this.habilitarTipoCaso = true;
      this.habilitarInputBusqueda = false;
      this.habilitarFechas = false;
    },
    mostrarFechas() {
      this.habilitarTipoCaso = false;
      this.habilitarInputBusqueda = false;
      this.habilitarFechas = true;
    },
    async buscarEquipo() {
      this.loaderSpinner();
      try {
        if (this.tipoFiltroLocal && this.tipoFiltroLocal === "CLIENTE") {
          const requerimientoXCliente = this.regularizacionesFiltradas?.filter(
            (item) => item?.codigoCliente === this.valorBusqueda
          );
          if (requerimientoXCliente?.length === 0) {
            return;
          }
          this.regularizacionesFiltradas = requerimientoXCliente;
        }
        if (this.tipoFiltroLocal && this.tipoFiltroLocal === "TICKET") {
          const requerimientoXTicket = this.regularizacionesFiltradas?.filter(
            (item) => item?.numeroTicket === this.valorBusqueda
          );
          if (requerimientoXTicket?.length === 0) {
            return;
          }
          this.regularizacionesFiltradas = requerimientoXTicket;
        }
        if (this.tipoFiltroLocal && this.tipoFiltroLocal === "TIPO") {
          const requerimientoXTipoCaso = this.regularizacionesFiltradas?.filter(
            (item) => item?.tipoCaso === this.valorBusqueda
          );
          if (requerimientoXTipoCaso?.length === 0) {
            return;
          }
          this.regularizacionesFiltradas = requerimientoXTipoCaso;
        }
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    consultarTodos() {
      this.valorBusqueda = "";
      this.regularizacionesFiltradas = this.regularizaciones;
    },
    async consultarXFechas() {
      try {
        this.loaderSpinner();
        const data = {
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        };
        await this.$store.dispatch("regularizaciones/consultarXFechas", data);
        this.regularizacionesFiltradas = this.regularizaciones;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    regularizaciones() {
      return this.$store.getters["regularizaciones/regularizaciones"]
        ? this.$store.getters["regularizaciones/regularizaciones"]
        : [];
    },
  },
};
</script>
