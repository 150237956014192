<template>
  <div>

    <div class="mt-4 ml-4">
      <h5 class="card-title">
        Ticket
        {{ solicitudProveedorSeleccionada.solicitud.numeroTicket }}
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">
        Tipo Solicitud:
        {{ solicitudProveedorSeleccionada.solicitud.tipoSolicitud }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Fecha Solicitud:
        {{ solicitudProveedorSeleccionada.solicitud.fechaSolicitud }}
      </h6>
    </div>


    <div class="mt-4 ml-4">
      <h5 class="card-title">
        Cliente
        {{ solicitudProveedorSeleccionada.solicitud.codigoCliente }}
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">
        Identificación: {{ solicitudProveedorSeleccionada.solicitud.identificacion }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Nombre: {{ solicitudProveedorSeleccionada.solicitud.nombre }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Establecimiento:
        {{ solicitudProveedorSeleccionada.solicitud.nombreEstablecimiento }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Dirección:
        {{ solicitudProveedorSeleccionada.solicitud.direccion }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Teléfono:
        {{
          solicitudProveedorSeleccionada.solicitud.telefono || solicitudProveedorSeleccionada.solicitud.telefonoCliente
        }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Sector desarrollo:
        {{ solicitudProveedorSeleccionada.solicitud.ecb }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Franja horaria:
        {{ solicitudProveedorSeleccionada.solicitud.franjaHoraria }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Referencia:
        {{ solicitudProveedorSeleccionada.solicitud.referencia }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Observación:
        {{ solicitudProveedorSeleccionada.solicitud.observacion }}
      </h6>
      <div>
        <a
            href="javascript:void(0)"
            @click="abrirMapa(solicitudProveedorSeleccionada.solicitud)"
        ><i class="ti-map menu-icon" style="color: red; font-size: 30px"></i
        ></a>
      </div>
    </div>
  </div>
  <div>
    <div class="mt-4 ml-4">
      <h5 class="card-title">
        Análisis
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">
        Capadidad: {{ solicitudProveedorSeleccionada.solicitud.capacidadEeffMediana }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Nivel de riesgo:
        {{ solicitudProveedorSeleccionada.solicitud.prioridad }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Mediana últimos seis meses:
        {{ solicitudProveedorSeleccionada.solicitud.medianaUltimoTrimestre }}
      </h6>
    </div>
  </div>

</template>
<script>
export default {
  methods: {
    abrirMapa(solicitud) {
      const {latitud, longitud} = solicitud;
      window.open(`https://maps.google.com/maps?q=loc:${latitud},${longitud}`);
    },
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
          "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
          ? this.$store.getters[
              "solicitudesProveedor/solicitudProveedorSeleccionada"
              ]
          : [];
    }
  },
};
</script>
