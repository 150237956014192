<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Asignación de Técnicos</h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0">

              </p>
            </div>
            <div class="col-md-8">
              <button @click="agregar" class="btn btn-primary btn-general float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th>Id</th>
                  <th>Nombre</th>
                  <th>Usuario</th>
                  <th>Vehiculo</th>
                  <th>Rol</th>
                  <th>Bodega</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in usuarios" :key="item.id" @change="consultar">
                  <td>
                    <a href="javascript:void(0)" @click="eliminar(item)"
                      ><i class="ti-trash btn-icon-append"></i
                    ></a>
                  </td>
                  <td>{{ item?.idubt }}</td>
                  <td>{{ item?.nombreUsuario }}</td>
                  <td>{{ item?.usuario }}</td>
                  <td>{{ item?.vehiculo }}</td>
                  <td>{{ item?.rol }}</td>
                  <td>{{ item?.bodega }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          mensaje="¿Está seguro de continuar con la eliminación?"
          :method="procesarEliminar"
        />

        <ModalMessages :mensaje="mensajeProceso" titulo="Eliminar archivo" />

        <button
          type="button"
          v-show="false"
          id="exampleModalButton"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          data-backdrop="static"
          data-keyboard="false"
        >
          Modal confirmación
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "../../../../components/Modal.vue";
import ModalMessages from "../../../../components/ModalMessages.vue";
export default {
  name: "GestionUsuariosVehiculosTransporte",
  components: { Modal, ModalMessages },
  data() {
    return {
      mensajeProceso: []
    };
  },
  created() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("usuarios/consultarVehiculosTecnicos");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("usuarios/setUsuarioSeleccionado", null);
      this.$router.push({ name: "UsuarioVehiculoTransporteForm" });
    },
   async eliminar(usuario) {
      this.$store.commit("usuarios/setUsuarioSeleccionado", usuario);
      document.getElementById("exampleModalButton").click();
    },
    async procesarEliminar() {
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch(
          "usuarios/eliminarVehiculoTecnico",
          { id: this.usuarioSeleccionado?.idubt }
        );
        this.mensajeProceso = [resp];
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensajeProceso = ["No se pudo realizar la eliminación"];
        document.getElementById("modalButton").click();
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    usuarios() {
      return this.$store.getters["usuarios/usuarios"]
        ? this.$store.getters["usuarios/usuarios"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    usuarioSeleccionado() {
      return this.$store.getters["usuarios/usuarioSeleccionado"]
        ? this.$store.getters["usuarios/usuarioSeleccionado"]
        : null;
    },
  },
};
</script>
