<template>
  <OpcionesMenu />
</template>
<script>
import OpcionesMenu from "./components/OpcionesMenu.vue";
export default {
  name: "OpcionesMenu",
  components: { OpcionesMenu },
};
</script>
