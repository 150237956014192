<template>
  <div
    class="col-md-12 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Agregar requerimiento
            </h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                  @submit="submit"
                  :validation-schema="formValidation"
                  v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="codigo">Código del cliente *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="codigo"
                                id="codigo"
                                class="form-control"
                                placeholder="Código del cliente"
                                v-model.trim="formData.codigo"
                                @blur="consultarCliente"
                              />
                              <MensajeValidacion :mensaje="errors.codigo" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="identificacion"
                              >Identificación cliente *</label
                            >
                            <fieldset>
                              <Field
                                type="text"
                                name="identificacion"
                                class="form-control"
                                placeholder="Identificación cliente"
                                v-model.trim="formData.identificacion"
                              />
                              <MensajeValidacion
                                :mensaje="errors.identificacion"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="telefono">Teléfono *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="telefono"
                                class="form-control"
                                placeholder="Teléfono"
                                v-model.trim="formData.telefono"
                              />
                              <MensajeValidacion :mensaje="errors.telefono" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="solicitante">Solicitante *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="solicitante"
                                class="form-control"
                                placeholder="Solicitante"
                                v-model.trim="formData.solicitante"
                              />
                              <MensajeValidacion :mensaje="errors.solicitante" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="placa">Placa *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="placa"
                                class="form-control"
                                placeholder="Placa"
                                v-model.trim="formData.placa"
                              />
                              <MensajeValidacion :mensaje="errors.placa" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="tipo">Tipo *</label>
                            <fieldset>
                              <Field
                                name="tipo"
                                as="select"
                                class="form-control"
                                v-model="formData.tipo"
                              >
                                <option value="" disabled>
                                  Seleccione un tipo
                                </option>
                                <option value="RETIRO">RETIRO</option>
                                <option value="SOLICITUD">SOLICITUD</option>
                                <option value="MANTENIMIENTO">
                                  MANTENIMIENTO
                                </option>
                                <option value="PERDIDO">PERDIDO</option>
                                <option value="ENCONTRADO">ENCONTRADO</option>
                                <option value="CAMBIO">CAMBIO</option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipo" />
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="cantidad">Cantidad *</label>
                            <fieldset>
                              <Field
                                type="number"
                                name="cantidad"
                                class="form-control"
                                placeholder="Cantidad"
                                v-model.trim="formData.cantidad"
                              />
                              <MensajeValidacion :mensaje="errors.cantidad" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="capacidad">Capacidad *</label>
                            <fieldset>
                              <Field
                                type="number"
                                name="capacidad"
                                class="form-control"
                                placeholder="Capacidad"
                                v-model.trim="formData.capacidad"
                              />
                              <MensajeValidacion :mensaje="errors.capacidad" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="fecha">Fecha *</label>
                            <fieldset>
                              <Field
                                type="date"
                                name="fecha"
                                class="form-control"
                                placeholder="Fecha"
                                v-model.trim="formData.fecha"
                              />
                              <MensajeValidacion :mensaje="errors.fecha" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="tipoCaso">Tipo de caso *</label>
                            <fieldset>
                              <Field
                                name="tipoCaso"
                                as="select"
                                class="form-control"
                                v-model="formData.tipoCaso"
                              >
                                <option value="" disabled>
                                  Seleccione un tipo
                                </option>
                                <option value="CHATBOT">CHATBOT</option>
                                <option value="BEESCARE">BEESCARE</option>
                                <option value="COOL INVENTORY">
                                  COOL INVENTORY
                                </option>
                                <option value="COOL INVENTORY BOT">COOL INVENTORY BOT</option>
                                <option value="SANITIZACION">SANITIZACION</option>
                                <option value="PREDICTIVO">PREDICTIVO</option>
                                <option value="PREVENTIVO">PREVENTIVO</option>
                                <option value="QUEJA">QUEJA</option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipoCaso" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="ticket">Ticket</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="ticket"
                                class="form-control"
                                placeholder="Ticket"
                                v-model.trim="formData.ticket"
                              />
                              <MensajeValidacion :mensaje="errors.ticket" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="motivo">Motivo *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="motivo"
                                class="form-control"
                                placeholder="Motivo"
                                v-model.trim="formData.motivo"
                              />
                              <MensajeValidacion :mensaje="errors.motivo" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="franjaHoraria">Franja horaria</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="franjaHoraria"
                                class="form-control"
                                placeholder="Franja horaria"
                                v-model.trim="formData.franjaHoraria"
                              />
                              <MensajeValidacion :mensaje="errors.franjaHoraria" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="referencia">Referencia *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="referencia"
                                class="form-control"
                                placeholder="Referencia"
                                v-model.trim="formData.referencia"
                              />
                              <MensajeValidacion :mensaje="errors.referencia" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <label for="formFile" class="form-label"
                                >Cargar foto *</label
                              >
                          <div class="input-group mb-3">
                            <div class="custom-file">
                              <input
                                class="form-control custom-file-input"
                                type="file"
                                id="formFile"
                                ref="file"
                                @change="uploadFile"
                              />
                              <label class="custom-file-label" for="formFile">Archivo</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="archivosArray.length>0">
                          <div class="mb-3">
                            <label for="formFile" class="form-label"
                              >Lista de archivos</label
                            >
                            <ul>
                              <li
                                v-for="(archivo, $index) in archivosArray"
                                :key="$index"
                              >
                                {{ archivo?.name }}
                                <a
                                  href="javascript:void(0)"
                                  class="text-danger"
                                  @click="eliminarArchivo(archivo)"
                                  ><i class="ti-close"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error" />
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12">
                      <label for="exampleFormControlTextarea1">Observación</label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        v-model.trim="formData.observacion"
                      ></textarea>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="submit"
                        id="form-submit"
                        class="btn btn-primary btn-general mr-2"
                        :disabled="!archivosArray.length>0"
                      >
                      <i class="ti-save"></i> Guardar
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary btn-general-off"
                        @click="cerrar"
                      >
                      <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage.vue";

export default {
  name: "SolicitudForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      codigo: "required",
      identificacion: "required",
      telefono: "required",
      solicitante: "required",
      placa: "required",
      tipo: "required",
      cantidad: "required",
      motivo: "required",
      fecha: "required",
      tipoCaso: "required",
      capacidad: "required",
      franjaHoraria: "required",
      referencia: "required"
    };
    return {
      formData: {
        codigo: "",
        identificacion: "",
        telefono: "",
        solicitante: "",
        placa: "",
        tipo: "",
        cantidad: "",
        motivo: "",
        ticket: "",
        fecha: "",
        tipoCaso: "",
        observacion: "",
        franjaHoraria: "",
        referencia: ""
      },
      error: false,
      mensaje: "",
      formValidation,
      archivo: null,
      archivosArray: [],
      gerenciaGeneral: "",
      gerenciaVentas: ""
    };
  },
  mounted() {},
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarCliente() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("rutero/consultarRuteroXCliente", {
          codigoCliente: this.formData?.codigo,
        });
        this.formData.identificacion = this.cliente?.noIdentificacionFiscal;
        this.formData.telefono = this.cliente?.telefonoCliente;
        this.gerenciaGeneral = this.cliente?.gerenciaGeneral ?? "";
        this.gerenciaVentas = this.cliente?.gerenciaVentas ?? "";
      } catch (error) {
        this.formData.identificacion = "";
        this.formData.telefono = "";
      } finally {
        this.loader.hide();
      }
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
      this.archivosArray.push(this?.$refs?.file?.files[0]);
      //console.log(this.archivosArray);
    },
    async submit() {
      let fecha = this.formData.fecha;
      let fechaDividida = fecha.split("-");
      let fechaFormateada = `${fechaDividida[1]}/${
        fechaDividida[2]
      }/${fechaDividida[0].substring(2, 4)} 00:00`;
      this.formData.fecha = fechaFormateada;

      const data = new FormData();
      this.archivosArray?.map((item) => data.append("file", item));
      data.append("codigo", this.formData.codigo);
      data.append("identificacion", this.formData.identificacion);
      data.append("telefono", this.formData.telefono);
      data.append("solicitante", this.formData.solicitante);
      data.append("placa", this.formData.placa);
      data.append("tipo", this.formData.tipo);
      data.append("cantidad", this.formData.cantidad);
      data.append("capacidad", this.formData.capacidad);
      data.append("motivo", this.formData.motivo);
      data.append("ticket", this.formData.ticket);
      data.append("fecha", this.formData.fecha);
      data.append("tipoCaso", this.formData.tipoCaso);
      data.append("observacion", this.formData.observacion);
      data.append("gerenciaGeneral", this.gerenciaGeneral);
      data.append("gerenciaVentas", this.gerenciaVentas);
      data.append("franjaHoraria", this.formData.franjaHoraria);
      data.append("referencia", this.formData.referencia);

      this.loaderSpinner();
      try {
        await this.$store.dispatch("solicitudes/crearSolicitud", data);
        this.$router.push({ name: "Solicitudes" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
    eliminarArchivo(archivo){
      const archivosFiltrados = this.archivosArray?.filter(item => item !== archivo);
      this.archivosArray = archivosFiltrados;
    }
  },
  computed: {
    cliente() {
      return this.$store.getters["rutero/cliente"]
        ? this.$store.getters["rutero/cliente"]
        : null;
    },
  },
};
</script>
<style>
.radio-margin {
  margin-right: 15px;
}
</style>
