<template>
  <GestionSolicitudesCerradasTransporte />
</template>
<script>
import GestionSolicitudesCerradasTransporte from "./components/GestionSolicitudesCerradasTransporte.vue";
export default {
  name: "SolicitudesCerradasTransporte",
  components: { GestionSolicitudesCerradasTransporte }
};
</script>
