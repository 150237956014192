<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión Filtros</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-8">
              <button
                @click="agregar"
                class="btn btn-general btn-primary float-right"
              >
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-0">
                <label>Entidad *</label>
                <CustomVariableList
                  variable="ENTIDAD"
                  :selectVariable="selectEntity"
                />
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Comparador</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filtros" :key="item.id">
                  <td>
                    <a href="javascript:void(0)" @click="eliminar(item.id)"
                      ><i class="ti-trash btn-icon-append"></i
                    ></a>
                  </td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.tipo }}</td>
                  <td>{{ item.comparator }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomVariableList from '../../../../components/CustomVariableList.vue';

export default {
    name: "GestionFiltros",
    data() {
        return {
            entidad: "",
        };
    },
    created() {
        this.$store.commit("filtros/setFiltros", []);
    },
    methods: {
        loaderSpinner() {
            this.loader = this.$loading.show();
        },
        async searchByEntity() {
            this.loaderSpinner();
            try {
                if (this.entidad) {
                    await this.$store.dispatch("filtros/consultarSinDetalle", {
                        entidad: this.entidad,
                    });
                }
            }
            catch (error) {
                alert("No se pudo realizar la consulta");
            }
            finally {
                this.loader.hide();
            }
        },
        async agregar() {
            this.$router.push({ name: "FiltroForm" });
        },
        async editar() { },
        async eliminar(id) {
            this.$swal({
                text: "¿Está seguro de eliminar la información?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si",
                cancelButtonText: "No",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loaderSpinner();
                    try {
                        await this.$store.dispatch("filtros/eliminar", { id });
                        this.$swal({
                            text: "Registro eliminado con éxito.",
                            icon: "success",
                        });
                    }
                    catch (error) {
                        this.$swal("", error.message, "error");
                    }
                    finally {
                        this.loader.hide();
                    }
                }
            });
        },
        selectEntity(entity) {
            this.entidad = entity;
            this.searchByEntity();
        },
    },
    computed: {
        isLogged() {
            return this.$store.getters["auth/isLogged"]
                ? this.$store.getters["auth/isLogged"]
                : false;
        },
        filtros() {
            return this.$store.getters["filtros/filtros"]
                ? this.$store.getters["filtros/filtros"]
                : [];
        },
        variables() {
            return this.$store.getters["variables/variablesByVariable"]
                ? this.$store.getters["variables/variablesByVariable"]
                : [];
        },
    },
    components: { CustomVariableList }
};
</script>
