import axios from "axios";

const state = {
  resultadoEncuesta: [],
  detalleResultadoEncuesta: []
};

const mutations = {
  setResultadoEncuesta: (state, data) => {
    state.resultadoEncuesta = data;
  },
  setDetalleResultadoEncuesta: (state, data) => {
    state.detalleResultadoEncuesta = data;
  },
};

const actions = {
  async consultarResultadoEncuesta({ commit }, data) {
    try {
      const resp = await axios.get(
        `/v1/encuestas/fecha-inicio/${data.fechaInicio}/fecha-fin/${data.fechaFin}/encuesta`
      );
      commit("setResultadoEncuesta", resp?.data?.result);
      return resp;
    } catch (error) {
      console.log(error);
      commit("setResultadoEncuesta", []);
      return error;
    }
  },
  async consultarDetalleResultadoEncuesta({ commit }, data) {
    try {
      const resp = await axios.get(
        `/v1/encuestas/fecha-inicio/${data.fechaInicio}/fecha-fin/${data.fechaFin}/encuesta-detalle`
      );
      commit("setDetalleResultadoEncuesta", resp?.data?.result);
      return resp;
    } catch (error) {
      console.log(error);
      commit("setDetalleResultadoEncuesta", []);
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
