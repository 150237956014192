import axios from "axios";

const state = {
  baseObjetivos: [],
  bloqueos: [],
  mensaje: ""
};

const getters = {
  baseObjetivos: (state) => state.baseObjetivos,
  bloqueos: (state) => state.bloqueos,
};

const mutations = {
  setBaseObjetivos: (state, baseObjetivos) => {
    state.baseObjetivos = baseObjetivos;
  },
  setBloqueos: (state, bloqueos) => {
    state.bloqueos = bloqueos;
  },
  inicializar: (state) => {
    (state.baseObjetivos = []);
    (state.bloqueos = []);
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
};

const actions = {
  async consultarBaseObjetivos({ commit }) {
    try {
      let res = await axios.get(`/v1/base-objetivos`);
      commit("setBaseObjetivos", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async consultarBloqueos({ commit }) {
    try {
      let res = await axios.get(`/v1/base-bloqueados`);
      commit("setBloqueos", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async consultarBloqueoCliente({ commit }, data) {
    try {
      let res = await axios.get(`/v2/base-objetivos/${data?.codigoCliente}/cliente`);
      commit("setMensaje", res?.data?.result?.mensaje ?? "");
      return res?.data?.result?.mensaje ?? false;
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
