import axios from "axios";

const state = {
  perfil: null,
  foto: null,
  mensaje: "",
};

const getters = {
  perfil: (state) => state.perfil,
  foto: (state) => state.foto,
};

const mutations = {
  setPerfil: (state, perfil) => {
    state.perfil = perfil;
  },
  setFoto: (state, foto) => {
    state.foto = foto;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
};

const actions = {
  async consultarPerfil({ commit }) {
    try {
      const res = await axios.get(`/v1/perfiles/datos`);
      commit("setPerfil", res?.data?.result);
    } catch (error) {
      commit("setPerfil", null);
      return error;
    }
  },
  async consultarFoto({ commit }) {
    try {
      let res = await axios.get(`/v1/perfiles/foto`);
      commit("setFoto", res?.data?.result);
    } catch (error) {
      commit("setFoto", null);
      return error;
    }
  },
  async actualizarPerfil({ commit }, data) {
    try {
      let res = await axios.put(`/v1/perfiles`, data);
      let perfilActualizado = res?.data?.result;
      commit("setPerfil", perfilActualizado);
    } catch (error) {
      return error;
    }
  },
  async actualizarFoto({ commit }, data) {
    try {
        const headers = { 'Content-Type': 'multipart/form-data' };
        const resp = await axios.put(`/v1/perfiles/foto-blob`, data?.data, { headers });
        commit("setFoto", resp?.data?.result);
        commit("setMensaje", "Carga de foto exitosa");
        return "Se actualizó la foto con éxito";
    } catch (error) {
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
