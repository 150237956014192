<template>
    <div
      class="navbar-menu-wrapper pr-menu"
    >
      
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a
            class="nav-link dropdown-toggle br-circulo"
            href="#"
            data-toggle="dropdown"
            id="profileDropdown"
          >
            <i class="ti-user"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right navbar-dropdown second-menu"
            aria-labelledby="profileDropdown"
          >
            <a @click="irPerfil" class="dropdown-item">
              <i class="ti-user text-primary mr-3"></i>Mi Perfil
            </a>

            <a
              href="javascript:void(0)"
              @click="cambiarClave"
              class="dropdown-item"
              
            >
              <i class="ti-lock text-primary mr-3"></i>Cambiar clave
            </a>
            <a @click="logout" class="dropdown-item">
              <i class="ti-power-off text-primary mr-3"></i>Logout
            </a>
          </div>
        </li>
        
      </ul>
      <button
        @click="mobileNav"
        class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
      >
        <span class="icon-menu"></span>
      </button>
    </div>
</template>

<script>

export default {
  data() {
    return {
      codigo: "",
      mostrarMobileNav: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
    async cambiarClave() {
      this.$router.push({ name: "CambiarClaveForm" });
    },
    filtrarTabla(event) {
      this.codigo = event.target.value;
    },
    mobileNav() {
      if (!this.mostrarMobileNav) {
        this.openMobileNav();
      } else {
        this.closeMobileNav();
      }
    },
    openMobileNav() {
      var root = document.getElementById("sidebar"); // '0' to assign the first (and only `HTML` tag)
      root.className += " active";
      this.mostrarMobileNav = true;
    },
    closeMobileNav() {
      var root = document.getElementById("sidebar"); // '0' to assign the first (and only `HTML` tag)
      root.classList.remove("active");
      this.mostrarMobileNav = false;
    },
    async irPerfil() {
      this.$router.push({ name: "Perfil" });
    },
  },
};
</script>