import axios from "axios";

const state = {
  usuarios: null,
  usuarioSeleccionado: null,
  mensaje: ""
};

const getters = {
  usuarios: (state) => state.usuarios,
  usuarioSeleccionado: (state) => state.usuarioSeleccionado,
};

const mutations = {
  setUsuarios: (state, usuarios) => {
    state.usuarios = usuarios;
  },
  setUsuarioSeleccionado: (state, usuarioSeleccionado) => {
    state.usuarioSeleccionado = usuarioSeleccionado;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  }
};

const actions = {
  async consultar({ commit }) {
    try {
      const res = await axios.get(`/v1/usuarios`);
      commit("setUsuarios", res?.data?.result);
    } catch (error) {
      commit("setUsuarios", []);
      return error;
    }
  },
  async crear({ commit, state }, data) {
    try {
      let res = await axios.post(`/v1/usuarios`, data);
      let nuevo = res?.data?.result;
      let actualizados = [...state.usuarios, nuevo];
      commit("setUsuarios", actualizados);
    } catch (error) {
      return error;
    }
  },
  async editar({ commit }, data) {
    try {
      await axios.put(`/v1/usuarios/${data.id}`, data.data);
      
      commit("setMensaje", "Usuario editado con éxito");
    } catch (error) {
      return error;
    }
  },
  async eliminar({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/usuarios/${data.id}`);
      let copia = state.usuarios;
      const filtrados = copia.filter(
        (item) => item.id !== res?.data?.result?.id
      );
      commit("setUsuarios", filtrados);
    } catch (error) {
      return error;
    }
  },
  async crearUsuarioVehiculoTransporte({ commit }, data) {
    try {
      await axios.post(`/v1/usuario-bodega/crear`, data);
      commit("setMensaje", "Usuario creado con éxito");
    } catch (error) {
      return error;
    }
  },
  async cambiarClave({ commit }, data) {
    try {
      await axios.put(`/v2/usuarios/cambiar-clave`, data);
      commit("setMensaje", "Cambio de clave con éxito");
    } catch (error) {
      return "ERROR";
    }
  },
  async consultarVehiculosTecnicos({ commit }) {
    try {
      const res = await axios.get(`/v1/usuarios/bodega-vehiculos/vehiculos`);
      commit("setUsuarios", res?.data?.result);
    } catch (error) {
      commit("setUsuarios", []);
      return error;
    }
  },
  async eliminarVehiculoTecnico({ commit }, data) {
    try {
      await axios.delete(`v1/usuarios/bodega-vehiculos/${data.id}/vehiculos`);
      const res = await axios.get(`/v1/usuarios/bodega-vehiculos/vehiculos`);
      commit("setUsuarios", res?.data?.result);
      return "Eliminación exitosa";
    } catch (error) {
      return "No se pudo realizar la eliminación";
    }
  },
  async resetPassword({ commit }, data) {
    try {
      await axios.post(`/v2/usuarios/recuperar-clave`, data);
      commit("setMensaje", "Clave reseteada con éxito");
    } catch (error) {
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
