import axios from "axios";

const state = {
    solicitudesRechazadas: null,
    solicitudRechazadaSeleccionada: null,
    totalPaginas: null,
};

const getters = {
    solicitudesRechazadas: (state) => state.solicitudesRechazadas,
    solicitudRechazadaSeleccionada: (state) => state.solicitudRechazadaSeleccionada,
    totalPaginas: (state) => state.totalPaginas,
};

const mutations = {
    setSolicitudesRechazadas: (state, solicitudesRechazadas) => {
        state.solicitudesRechazadas = solicitudesRechazadas;
    },
    setSolicitudRechazadaSeleccionada: (state, solicitudRechazadaSeleccionada) => {
        state.solicitudRechazadaSeleccionada = solicitudRechazadaSeleccionada;
    },

    setTotalPaginas: (state, data) => {
        state.totalPaginas = data;
    },
};

const actions = {

    async consultarSolicitudesXPagina({commit}, data) {
        try {
            let res = await axios.get(`/v1/solicitudes-rechazadas/${data.pagina}/pagina`);
            commit("setSolicitudesRechazadas", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async consultarTotalPaginas({commit}) {
        try {
            const resp = await axios.get(`/v1/solicitudes-rechazadas/total`);
            commit("setTotalPaginas", resp?.data?.totalPaginas);
            return resp?.data?.totalPaginas || 0;
        } catch (error) {
            return error;
        }
    },
    async agregarExcepcion({ commit }, data) {
      try {
        await axios.post(`/v1/solicitudes-rechazadas/excepcion`, data);
        let res = await axios.get(`/v1/solicitudes-rechazadas/1/pagina`);
            commit("setSolicitudesRechazadas", res?.data?.result);
      } catch (error) {
        return error;
      }
    },
    async agregarExcepciones({ commit }, data) {
      try {
        await axios.post(`/v2/solicitudes-rechazadas/excepcion`, data);
        let res = await axios.get(`/v1/solicitudes-rechazadas/1/pagina`);
            commit("setSolicitudesRechazadas", res?.data?.result);
      } catch (error) {
        return error;
      }
    },
    async consultarSolicitudXTicket({ commit }, data) {
      try {
        let res = await axios.get(`/v1/solicitudes-rechazadas/${data.ticket}/ticket`);
        commit("setSolicitudesRechazadas", res?.data?.result ? [res?.data?.result] : []);
        commit("setTotalPaginas", 1);
      } catch (error) {
        return error;
      }
    },
    async consultarSolicitudXCliente({ commit }, data) {
      try {
        let res = await axios.get(`/v1/solicitudes-rechazadas/${data.codigoCliente}/cliente`);
        commit("setSolicitudesRechazadas", res?.data?.result ? [res?.data?.result] : []);
        commit("setTotalPaginas", 1);
      } catch (error) {
        return error;
      }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
