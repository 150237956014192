import axios from "axios";

const state = {
  reparaciones: null,
  reparacionSeleccionada: null,
};

const getters = {
  reparaciones: (state) => state.reparaciones,
  reparacionSeleccionada: (state) => state.reparacionSeleccionada,
};

const mutations = {
  setReparaciones: (state, reparaciones) => {
    state.reparaciones = reparaciones;
  },
  setReparacionSeleccionada: (state, reparacionSeleccionada) => {
    state.reparacionSeleccionada = reparacionSeleccionada;
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/reparaciones`);
      commit("setReparaciones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async crearReparacion({ commit }, data) {
    try {
      await axios.post(`/v1/reparaciones`, data);
      let res = await axios.get(`/v1/reparaciones`);
      commit("setReparaciones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
