<template>
  <GestionReparaciones />
</template>
<script>
import GestionReparaciones from "./components/GestionReparaciones";
export default {
  name: "Reparaciones",
  components: { GestionReparaciones }
};
</script>
