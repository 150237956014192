<template>
  <GestionEquipos/>
</template>
<script>
import GestionEquipos from "./components/GestionEquipos"

export default {
  components: {GestionEquipos},
  mounted() {
  }

};
</script>
