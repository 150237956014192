<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Análisis por cliente
            </h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <label for="codigoCliente mt-0">Código de cliente</label>
                <div class="input-group">
                  <input
                      type="text"
                      class="form-control"
                      v-model="codigoCliente"
                      placeholder="Código de cliente"
                      aria-label="Text input with segmented dropdown button"
                  />
                  <div class="input-group-append">
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-general btn-buscar mb-2 mb-md-0"
                        @click="calcular"
                        :disabled="!codigoCliente.length"
                    >
                      <span class="icon-check"></span> Consultar
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div class="white-content-table">
          <div class="clave-valor-container">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="container-dato-cliente">
                  <div class="container-detalle-cliente">
                    <div style="font-weight: bold">Nombre del Negocio:</div>
                    {{ analisis[0]?.ruteroDto?.nombrePoc }}
                  </div>
                  <div class="container-detalle-cliente">
                    <div style="font-weight: bold">Nombre del Cliente:</div>
                    {{ analisis[0]?.ruteroDto?.nombresCliente }}
                  </div>
                  <div class="container-detalle-cliente">
                    <div style="font-weight: bold">Gerencia General:</div>
                    {{ analisis[0]?.ruteroDto?.gerenciaGeneral }}
                  </div>
                  <div class="container-detalle-cliente">
                    <div style="font-weight: bold">Gerencia Ventas:</div>
                    {{ analisis[0]?.ruteroDto?.gerenciaVentas }}
                  </div>
                  <div class="container-detalle-cliente">
                    <div style="font-weight: bold">Sector Desarrollo:</div>
                    {{ analisis[0]?.ruteroDto?.sectorDesarrollo }}
                  </div>
                  <div class="container-detalle-cliente">
                    <div style="font-weight: bold">Canal:</div>
                    {{ analisis[0]?.ruteroDto?.canal }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">

                <div class="row fila-impar">
                  <div class="col-md-6 clave-valor-negrita">
                    Codigo Cliente:
                  </div>
                  <div class="col-md-6 clave-valor">
                    {{ analisis[0]?.ruteroDto?.clienteSap }}
                  </div>
                </div>
                <div class="row fila-par">
                  <div class="col-md-6 clave-valor-negrita">
                    Aplica:
                  </div>
                  <div class="col-md-6 clave-valor" :class="{ 'text-danger': aplica === 'NO' }">
                    {{ aplica }}
                  </div>
                </div>

                <div class="row fila-impar">
                  <div class="col-md-6 clave-valor-negrita"
                  >
                    Activo
                  </div>
                  <div class="col-md-6 clave-valor"
                       :class="{ 'text-danger': analisis[0]?.ruteroDto?.estadoCliente !== 'Activo' }"
                       style="display: flex; justify-content: space-between; align-items: center;">
                    <div>
                      {{ analisis[0]?.ruteroDto?.estadoCliente }}
                    </div>
                    <div v-if="analisis[0]?.ruteroDto?.estadoCliente !== 'Activo'">
                      <span class="icon-bell"></span> Debe ser Activo
                    </div>
                  </div>
                </div>
                <div class="row fila-par">
                  <div class="col-md-6 clave-valor-negrita">
                    Último meses compra
                  </div>
                  <div class="col-md-6 clave-valor"
                       :class="{ 'text-danger': analisis[0]?.calculoInstalacion?.ultimosMesesCompraAcida <6 }"
                       style="display: flex; justify-content: space-between; align-items: center;">
                    <div>
                      {{ analisis[0]?.calculoInstalacion?.ultimosMesesCompraAcida }}
                    </div>
                    <div v-if="analisis[0]?.calculoInstalacion?.ultimosMesesCompraAcida <6">
                      <span class="icon-bell"></span> Ultimos meses compra debe ser >= 6
                    </div>

                  </div>
                </div>

                <div class="row fila-impar">
                  <div class="col-md-6 clave-valor-negrita">
                    Promedio últimos tres meses:
                  </div>
                  <div class="col-md-6 clave-valor"
                       :class="{ 'text-danger': analisis[0]?.calculoInstalacion?.promedioUltimosTresMesesVolumen <1.2 }"
                       style="display: flex; justify-content: space-between; align-items: center;">
                    <div>
                      {{ analisis[0]?.calculoInstalacion?.promedioUltimosTresMesesVolumen }}
                    </div>
                    <div v-if="analisis[0]?.calculoInstalacion?.promedioUltimosTresMesesVolumen <1.2 ">
                      <span class="icon-bell"></span> Promedio debe ser >= 1.2
                    </div>

                  </div>
                </div>

                <div class="row fila-par">
                  <div class="col-md-6 clave-valor-negrita">
                    Cartera Vencida:
                  </div>
                  <div class="col-md-6 clave-valor"
                       :class="{ 'text-danger': analisis[0]?.cartera?.vencido > 0 }"
                       style="display: flex; justify-content: space-between; align-items: center;">
                    <div>
                      {{ analisis[0]?.cartera?.vencido }}
                    </div>
                    <div v-if="analisis[0]?.cartera?.vencido  > 0">
                      <span class="icon-bell"></span> Cartera vencida debe ser 0
                    </div>

                  </div>
                </div>

                <div class="row fila-impar">
                  <div class="col-md-6 clave-valor-negrita">
                    Dias Vencido:
                  </div>
                  <div class="col-md-6 clave-valor" :class="{ 'text-danger':analisis[0]?.cartera?.diasMora > 0 }"
                       style="display: flex; justify-content: space-between; align-items: center;">
                    <div>
                      {{ analisis[0]?.cartera?.diasMora }}
                    </div>
                    <div v-if="analisis[0]?.cartera?.diasMora > 0">
                      <span class="icon-bell"></span> Dias vencido debe ser 0
                    </div>
                  </div>
                </div>

                <div class="row fila-par">
                  <div class="col-md-6 clave-valor-negrita">
                    Últimos meses que ha cumplido desafíos de Mi Negocio
                  </div>
                  <div class="col-md-6 clave-valor"
                       :class="{ 'text-danger': analisis[0]?.calculoInstalacion?.miNegocioWinner < 3 }"
                       style="display: flex; justify-content: space-between; align-items: center;">
                    <div>
                      {{ analisis[0]?.calculoInstalacion?.miNegocioWinner }}
                    </div>
                    <div v-if="analisis[0]?.calculoInstalacion?.miNegocioWinner < 3 ">
                      <span class="icon-bell"></span> Desafíos Mi Negocio debe ser mayor a 2
                    </div>

                  </div>
                </div>

              </div>
              <div class="col-md-6 col-sm-12">
                <NotaAclaratorias/>
              </div>
            </div>
          </div>
          <br/>
          <div>
            <h4 :class="{ 'text-danger':this.equipos.length > 0 }">
              Equipos frios con CN: {{ this.equipos.length }}
            </h4>
            <div class="text-danger" v-if="this.equipos.length>0">
              <span class="icon-bell"></span> No debe tener equipos asignados
            </div>
            <EquiposFriosCliente :codigo-cliente="codigoCliente" :equipos="equipos"/>
          </div>
          <br/>
          <div>
            <h4>Detalle de compras con CN</h4>

            <CajasTable :data="analisis[0]?.cajas"/>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import EquiposFriosCliente from "./EquiposFriosCliente.vue"
import CajasTable from "@/views/analisis/components/CajasTable.vue";
import NotaAclaratorias from "@/views/analisis/components/NotaAclaratorias.vue";

export default {
  name: "GestionAnalisisMultiple",
  components: {CajasTable, EquiposFriosCliente, NotaAclaratorias},
  data() {
    return {
      codigoCliente: "",
      codigos: [],
      cajas: [],
      equipos: [],
      aplica: null
    };
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },

    async calcular() {
      try {
        this.codigos[0] = this.codigoCliente
        await this.consultar();
        await this.$store.dispatch("analisis/calcularAnalisis", {
          codigoCliente: this.codigos,
        });

        await this.presentarCajas(this.analisis[0].cajas)

        if (this.analisis[0]?.calculoInstalacion?.ultimosMesesCompraAcida >= 6 &&
            this.analisis[0]?.calculoInstalacion?.promedioUltimosTresMesesVolumen >= 1.2 &&
            this.analisis[0]?.cartera?.vencido <= 0 &&
            this.analisis[0]?.cartera?.diasMora <= 0 &&
            this.analisis[0]?.ruteroDto?.estadoCliente == "Activo" &&
            this.analisis[0]?.calculoInstalacion?.miNegocioWinner > 2 &&
            this.equipos.length < 1
        ) {
          this.aplica = "SI"
        } else {
          this.aplica = "NO"
        }
      } catch (error) {
        console.log(error);
      }
    },
    async consultar() {
      try {
        const data = {
          cliente: this.codigoCliente
        }
        this.equipos = await this.$store.dispatch("equipos/consultarEquipoXCliente", data)
      } catch (error) {
        alert("No se pudo realizar la consulta de equipos");
      }
    },

    presentarCajas(cajas) {
      let fechaActual = new Date();
      cajas.push({
        "codigoCliente": this.analisis[0]?.cajasCurso?.codigoCliente,
        "mes": this.analisis[0]?.cajasCurso?.mes,
        "volumen": this.analisis[0]?.cajasCurso?.volumen,
        "cajas": this.analisis[0]?.cajasCurso?.cajas,
        "anio": fechaActual.getFullYear()
      })
      cajas.sort((a, b) => {
        if (a.anio > b.anio) {
          return -1;
        } else if (a.anio < b.anio) {
          return 1;
        } else {
          return a.mes - b.mes;
        }
      });
      this.cajas = cajas;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    analisis() {
      return this.$store.getters["analisis/analisisMultiple"]
          ? this.$store.getters["analisis/analisisMultiple"]
          : {};
    },
  },
};
</script>
<style>
.clave-valor {
  padding: 10px;

}

.clave-valor-negrita {
  padding: 10px;
  font-weight: bold;
  border-right: 1px solid #ccc;
}

.clave-valor-container {

}

.fila-par {
  background-color: #f2f2f2;
}

.fila-impar {
  background-color: #ffffff;
}

.container-notas {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
}

.container-dato-cliente {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.container-dato-cliente > div {
  margin-right: 10px;
}

.container-detalle-cliente {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;
}
</style>
