import axios from "axios";

const state = {
  notificaciones: [],
  totalPaginas: null,
  totalRegistros: null,
  mensaje: ""
};

const getters = {
  notificaciones: (state) => state.notificaciones,
  totalPaginas: (state) => state.totalPaginas,
  totalRegistros: (state) => state.totalRegistros,
};

const mutations = {
  setNotificaciones: (state, data) => {
    state.notificaciones = data;
  },
  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
  setTotalRegistros: (state, data) => {
    state.totalRegistros = data;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
};

const actions = {
  async consultarNotificacionesXPagina({ commit }, data) {
    try {
      let res = await axios.get(`/v1/notificaciones/${data.pagina}/pagina`);
      commit("setNotificaciones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginas({ commit }) {
    try {
      const resp = await axios.get(`/v1/notificaciones/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      commit("setTotalRegistros", resp?.data?.totalRegistros);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async consultarNotificaciones({ commit }, data) {
    try {
      let res = await axios.post(`v2/notificaciones/encuesta-detalle`, data);
      commit("setNotificaciones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
