<template>
  <GestionArchivosSolicitudes />
</template>
<script>
import GestionArchivosSolicitudes from "./components/GestionArchivosSolicitudes";
export default {
  name: "ArchivosSolicitudes",
  components: { GestionArchivosSolicitudes }
};
</script>
