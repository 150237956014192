<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión Adjuntos</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-8">
              <button
                @click="agregar"
                class="btn btn-general btn-primary float-right"
              >
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-0">
                <label>Tipo de solicitud *</label>
                <fieldset>
                  <select
                    name="tipoSolicitud"
                    class="form-control"
                    v-model="tipoSolicitud"
                    @change="consultar"
                  >
                    <option value="" disabled>
                      Seleccione un tipo de solicitud
                    </option>
                    <option value="SOLICITUD">SOLICITUD</option>
                    <option value="MANTENIMIENTO">MANTENIMIENTO</option>
                    <option value="RETIRO">RETIRO</option>
                    <option value="CAMBIO">CAMBIO</option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th>Tipo</th>
                  <th>Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in atencionAdjuntos" :key="item.id">
                  <td>
                    <a href="javascript:void(0)" @click="eliminar(item.id)"
                      ><i class="ti-trash btn-icon-append"></i
                    ></a>
                  </td>
                  <td>{{ item.tipo }}</td>
                  <td>{{ item.descripcion }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionAdjuntos",
  data() {
    return {
      tipoSolicitud: "SOLICITUD",
    };
  },
  created() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      this.$store.commit("atencionAdjunto/setAtencionAdjuntos", []);
      try {
        await this.$store.dispatch("atencionAdjunto/consultar", {
          tipoSolicitud: this.tipoSolicitud,
        });
      } catch (error) {
        alert("No se pudo consultar los filtros");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      //await this.$store.commit("menu/setMenuSeleccionado", null);
      this.$router.push({ name: "AtencionAdjuntoForm" });
    },
    async editar() {
      
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("atencionAdjunto/eliminar", { id });
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
            //this.tipoSolicitud = "";
            //this.$store.commit("atencionAdjunto/setAtencionAdjuntos", []);
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    atencionAdjuntos() {
      return this.$store.getters["atencionAdjunto/atencionAdjuntos"]
        ? this.$store.getters["atencionAdjunto/atencionAdjuntos"]
        : [];
    },
  },
};
</script>
