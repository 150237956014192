<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Bodegas</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-0">
                <label>Proveedor *</label>
                <fieldset>
                  <select
                      name="proveedor"
                      class="form-control mb-2 mb-md-0"
                      v-model="proveedor"
                      @change="consultarXProveedor"
                  >
                    <option value="" disabled>Seleccione un proveedor</option>
                    <option
                        v-for="proveedor in proveedores"
                        :key="proveedor.id"
                        :value="proveedor.id"
                    >
                      {{ proveedor.nombreProveedor }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
            <div class="col-md-6">
              <button @click="agregar" class="btn btn-primary btn-general float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        

        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%"></th>
                <th>Nombre</th>
                <th>Proveedor</th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="item in bodegas" :key="item.id">
                <td>
                  <a
                      href="javascript:void(0)"
                      @click="editar(item)"
                  ><i class="ti-pencil btn-icon-append"></i></a
                  >&nbsp;
                  <a
                      href="javascript:void(0)"
                      @click="eliminar(item.id)"
                  ><i class="ti-trash btn-icon-append"></i
                  ></a>
                </td>
                <td>{{ item?.nombre }}</td>
                <td>{{ item?.proveedor?.nombreProveedor }}</td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "GestionBodegas",
  data() {
    return {
      proveedor: ""
    };
  },
  mounted() {
    if(this.proveedorSeleccionado){
      this.proveedor = this.proveedorSeleccionado?.id;
      this.consultarXProveedor();
    }else{
      this.consultarBodegas();
    }    
    this.consultarProveedores();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarProveedores() {
      try {
        await this.$store.dispatch("proveedores/consultar");
        if (this.proveedorSeleccionado) {
          this.proveedor = this.proveedorSeleccionado.id;
        }
      } catch (error) {
        alert("No se pudo realizar la consulta de provvedores");
      }
    },
    async consultarXProveedor() {
      this.loaderSpinner();
      try {
        if (this.proveedor) {
          await this.$store.dispatch("bodegas/consultarXProveedor", {proveedor: this.proveedor});
          const proveedorSeleccionado = this.proveedores.find(item => item.id === this.proveedor);
          await this.$store.commit("proveedores/setProveedorSeleccionado", proveedorSeleccionado);
        }

      } catch (error) {
        alert("No se pudo realizar la consulta de bodegas x proveedor");
      } finally {
        this.loader.hide();
      }
    },
    async consultarBodegas() {
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      }
    },
    async agregar() {
      await this.$store.commit("bodegas/setBodegaSeleccionada", null);
      this.$router.push({name: "BodegaForm"});
    },
    async editar(bodega) {
      await this.$store.commit("bodegas/setBodegaSeleccionada", bodega);
      this.$router.push({name: "BodegaForm"});
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        //icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("bodegas/eliminar", {id: id});
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
          ? this.$store.getters["proveedores/proveedores"]
          : [];
    },
    proveedorSeleccionado() {
      return this.$store.getters["proveedores/proveedorSeleccionado"]
          ? this.$store.getters["proveedores/proveedorSeleccionado"]
          : null;
    },
  },
};
</script>
<style></style>
