<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Agregar Filtro</h3>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                  @submit="submit"
                  :validation-schema="formValidation"
                  v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="comparator">Entidad *</label>
                            <CustomVariableList
                                variable="ENTIDAD"
                                :selectVariable="selectEntidad"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="name">Nombre *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="nombre"
                                id="nombre"
                                class="form-control"
                                placeholder="Nombre"
                                v-model.trim="formData.nombre"
                              />
                              <MensajeValidacion :mensaje="errors.nombre" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="path">Nombre Base *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="nombreBase"
                                id="nombreBase"
                                class="form-control"
                                placeholder="Nombre Base"
                                v-model.trim="formData.nombreBase"
                              />
                              <MensajeValidacion :mensaje="errors.nombreBase" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="telefono">Orden *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="orden"
                                id="orden"
                                class="form-control"
                                placeholder="Orden"
                                v-model.trim="formData.orden"
                              />
                              <MensajeValidacion :mensaje="errors.orden" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="tipo">Tipo *</label>
                            <CustomVariableList
                              variable="TIPO_COMPONENTE"
                              :selectVariable="selectTipo"
                            />
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="comparator">Comparator *</label>
                            <CustomVariableList
                              variable="COMPARATOR"
                              :selectVariable="selectComparator"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error" />
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="submit"
                        id="form-submit"
                        class="btn btn-primary btn-general mr-2"
                      >
                        <i class="ti-save"></i> Guardar
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary btn-general-off"
                        @click="cerrar"
                      >
                        <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import CustomVariableList from "../../../../components/CustomVariableList.vue";

export default {
  name: "FiltroForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    CustomVariableList,
  },
  data() {
    const formValidation = {
      nombre: "required",
      nombreBase: "required",
      //tipo: "required",
      //comparator: "required",
      //entidad: "required",
      orden: "required",
    };
    return {
      formData: {
        nombre: "",
        nombreBase: "",
        tipo: "",
        comparator: "",
        entidad: "",
        orden: "",
      },
      error: false,
      mensaje: "",
      formValidation,
    };
  },
  mounted() {},
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    selectTipo(tipo){
      this.formData.tipo = tipo;
    },
    selectComparator(comparator){
      this.formData.comparator = comparator;
    },
selectEntidad(entidad){
      this.formData.entidad = entidad;
    },
    async submit() {
      if(!this.formData.comparator.length || !this.formData.tipo.length) return;
      this.loaderSpinner();
      try {
        await this.$store.dispatch("filtros/crear", this.formData);
        this.$router.push({ name: "Filtros" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {},
};
</script>
