import axios from "axios";
import store from "../index";

const state = {
  listaNegra: []
};

const getters = {
  listaNegra: (state) => state.listaNegra,
};

const mutations = {
  setListaNegra: (state, listaNegra) => {
    state.listaNegra = listaNegra;
  },
};

const actions = {
  async crear(_, data) {
    try {
      await axios.post(`/v1/lista-negra`, data);
    } catch (error) {
      return error;
    }
  },
  async consultarListaNegraPaginado({ commit }, data) {
    try {
      let res = await axios.get(`/v1/lista-negra/pagina/${data.pagina}`);
      commit("setListaNegra", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarListaNegraTotalPaginas() {
    try {
      const resp = await axios.get(`/v1/lista-negra/total`);
      store.dispatch("pagination/updateTotales", {
        totalPaginas: resp?.data?.totalPaginas,
        totalRegistros: resp?.data?.totalRegistros
      })
    } catch (error) {
      return error;
    }
  },
  async eliminar({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/lista-negra/${data.id}`);
      let copia = state.listaNegra;
      const filtrados = copia.filter(
        (item) => item.id !== res?.data?.result?.id
      );
      commit("setListaNegra", filtrados);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
