<template>
  <div class="col-md-12 stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Procesar requerimiento</h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>
        <div class="white-content-general mb-4">
          <form name="f1" id="formElement">
            <CabeceraRequerimiento />
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Datos Equipos</h5>
                <p class="text-muted" v-if="!equipos.length">
                  No se recuperaron equipos fríos en el cliente, por favor póngase
                  en contacto con el Administrador
                </p>
                <BusquedaXPlaca :seleccionarEquipo="seleccionarEquipo" />
                <div class="form-check" v-for="item in equipos" :key="item.id">
                  <input
                    class="form-check-input ml-1"
                    type="checkbox"
                    :id="item.id"
                    :value="item.id"
                    v-model="idEquipos"
                  />
                  <label class="form-check-label" :for="item.id">
                    <strong>Placa:</strong>
                    {{ item.placa }}
                    <strong>Serie:</strong>
                    {{ item.serie }}
                    <strong>Capacidad (cajas):</strong>
                    {{ item.capacidadCajas }}
                    <strong>Capacidad (pies):</strong>
                    {{ item.capacidadPies }}
                  </label>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h5 class="card-title txt-celeste">Atender requerimiento</h5>

                <div class="form-check">
                  <input
                    class="form-check-input ml-1"
                    type="radio"
                    id="ejecutado"
                    value="EJECUTADO"
                    v-model="requerimiento"
                    @click="seleccionarRequerimiento"
                  />
                  <label class="form-check-label" for="ejecutado">
                    EJECUTADO
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input ml-1"
                    type="radio"
                    id="fallido"
                    value="FALLIDO"
                    v-model="requerimiento"
                    @click="seleccionarRequerimiento"
                  />
                  <label class="form-check-label" for="fallido"> FALLIDO </label>
                </div>
                <div class="form-group" v-if="mostrarMotivo">
                  <label>Motivo</label>
                  <select class="form-control" v-model="motivo">
                    <option value="" disabled>Seleccione un motivo</option>
                    <option value="DIRECCIÓN NO ENCONTRADA">
                      DIRECCIÓN NO ENCONTRADA
                    </option>
                    <option value="EQUIPO NO ENCONTRADO">
                      EQUIPO NO ENCONTRADO
                    </option>
                    <option value="OTRO MOTIVO">OTRO MOTIVO</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Observación</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="observacion"
                  ></textarea>
                </div>
                <div class="mb-3">
                  <label for="formFile" class="form-label">Cargar archivo</label>
                  <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    ref="file"
                    @change="uploadFile"
                  />
                </div>

                <button
                  type="button"
                  class="btn btn-primary btn-block mt-3"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  @click="confirmar"
                >
                <i class="ti-check-box"></i> Atender requerimiento
                </button>
              </div>
            </div>
          </form>
          <Modal :mensaje="mensaje" :method="procesar" />
          <ModalMessages
            :mensaje="mensajeProceso"
            :titulo="titulo"
            :method="cerrar"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import Modal from "../../../components/Modal.vue";
import CabeceraRequerimiento from "./CabeceraRequerimiento.vue";
import BusquedaXPlaca from "../../../components/BusquedaXPlaca.vue";

export default {
  components: { ModalMessages, CabeceraRequerimiento, Modal, BusquedaXPlaca },
  data() {
    return {
      observacion: "",
      idEquipos: [],
      requerimiento: "EJECUTADO",
      mostrarMotivo: false,
      motivo: "",
      archivo: null,
      mensaje: "",
      mensajeProceso: null,
      titulo: "",
      ejecutarCerrar: false,
    };
  },
  created() {
    this.$store.commit("equipos/setEquipos", null);
  },
  mounted() {
    this.consultarEquiposCliente();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarEquiposCliente() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("equipos/consultarEquipoXCliente", {
          cliente:
            this.rutaSeleccionada?.solicitudProveedor?.solicitud?.codigoCliente,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarRequerimiento(e) {
      if (e.target.value === "FALLIDO") {
        this.mostrarMotivo = true;
      } else {
        this.mostrarMotivo = false;
      }
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    confirmar() {
      this.mensaje = "¿Desea continuar con el proceso de retiro?";
    },
    async procesar() {
      if (
        this.requerimiento === "" ||
        !this.rutaSeleccionada.id ||
        (this.requerimiento === "EJECUTADO" && this.idEquipos.length === 0) ||
        (this.requerimiento === "FALLIDO" && this.motivo.length === 0) || 
        this.observacion.length === 0
      ) {
        this.mensajeProceso = [
          "Por favor ingrese toda la información requerida antes de continuar con el proceso de retiro",
        ];
        this.titulo = "Advertencia";
        this.ejecutarCerrar = false;
        document.getElementById("modalButton").click();
        return;
      }

      const formData = new FormData();
      formData.append("file", this.archivo);
      formData.append("estadoSolicitud", this.requerimiento);
      formData.append(
        "motivo",
        this.requerimiento === "EJECUTADO" ? "" : this.motivo
      );
      formData.append(
        "idSolicitudProveedor",
        this.rutaSeleccionada.solicitudProveedor.id
      );
      formData.append(
        "idEquipoFrio",
        this.requerimiento === "EJECUTADO" ? this.idEquipos : 0
      );
      formData.append("observacion", this.observacion);
      formData.append("idRuta", this.rutaSeleccionada?.id);

      try {
        this.loaderSpinner();
        await this.$store.dispatch("ruta/procesarRetiro", {
          data: formData,
          idBodegaVehiculo: this.usuarioBodega.id,
        });

        //this.mensajeProceso = resp.contenido;
        this.mensajeProceso = ["Proceso de retiro exitoso"];
        this.titulo = "Retiro";
        this.ejecutarCerrar = true;
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensaje = error;
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      if (this.ejecutarCerrar) {
        this.$router.go(-1);
      }
    },
    seleccionarEquipo(datos) {
      if (datos.tipo === "ADD") {
        this.idEquipos.push(datos.valor);
      }
      if (datos.tipo === "DELETE") {
        const idEquiposFiltrados = this.idEquipos.filter(
          (item) => item !== datos.valor
        );
        this.idEquipos = idEquiposFiltrados;
      }
    },
  },
  computed: {
    rutaSeleccionada() {
      return this.$store.getters["ruta/rutaSeleccionada"]
        ? this.$store.getters["ruta/rutaSeleccionada"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
        ? this.$store.getters["equipos/equipos"]
        : {};
    },
    usuarioBodega() {
      return this.$store.getters["usuariosBodega/usuarioBodega"]
        ? this.$store.getters["usuariosBodega/usuarioBodega"]
        : {};
    },
  },
};
</script>
