<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Bajas Contable</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
              <button
                @click="abrirModalArchivo"
                class="btn btn-primary float-right mb-1"
              >
                <span class="icon-upload"></span> Cargar archivo
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Placa</th>
                  <th>Soc</th>
                  <th>Activo fijo</th>
                  <th>Subn</th>
                  <th>Nombre Archivo</th> 
                  <th>Cento costo</th>
                  <th>Fecha Capitalizacion</th>
                  <th>Costo Adquisición Ajustado</th>
                  <th>Depreciación Acumulada</th>
                  <th>Costo Contable</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in bajas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.placa }}</td>
                  <td>{{ item.soc }}</td>
                  <td>{{ item.activoFijo }}</td>
                  <td>{{ item.subn }}</td>
                  <td>{{ item.nombreArchivo }}</td>
                  <td>{{ item.centoCosto }}</td>
                  <td>{{ item.fechaCapitalizacion }}</td>
                  <td>{{ item.costoAdquisicionAjustado }}</td>
                  <td>{{ item.depreciacionAcumulada }}</td>
                  <td>{{ item.costoContable }}</td>
                </tr>
              </tbody>
            </table>
            <Paginacion :updatePage="updatePage"/>
          </div>
        </div>
        
        <div class="row">
          <div
            class="modal"
            tabindex="-1"
            :style="displayModal ? 'display: block;' : 'display: none;'"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title txt-celeste">Cargar archivo</h5>
                </div>
                <div class="modal-body">
                  <p>Seleccione el archivo</p>
                  <div class="input-group mb-3">
                    <input type="file" class="custom-file-input form-control" id="formFile" ref="file"
                      @change="uploadFile">
                    <label class="custom-file-label" for="inputGroupFile01">Archivo</label>
                  </div>
                  <div class="alert alert-danger" role="alert" v-if="error">
                    No se pudo cargar el archivo
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-general-off btn-secondary"
                    data-bs-dismiss="modal"
                    @click="cerrarModalArchivo"
                  >
                  <i class="ti-close"></i> Cerrar
                  </button>
                  <button
                    type="button"
                    class="btn btn-general btn-primary"
                    @click="cargarArchivo"
                    :disabled="archivo ? false : true"
                  >
                  <span class="icon-upload"></span> Cargar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Paginacion from '../../../components/Paginacion.vue';

export default {
  name: "GestionBajas",
  components: { Paginacion },
  data() {
    return {
      mensaje: "",
      mensajeProceso: [],
      archivo: null,
      displayModal: false,
      error: false,
      pagina: 1,
    };
  },
  mounted() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bajas/consultarBajasContablesPaginado", data);
        await this.$store.dispatch(
          "bajas/consultarBajasContablesTotalPaginas"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    abrirModalArchivo() {
      this.displayModal = true;
      this.error = false;
    },
    cerrarModalArchivo() {
      this.displayModal = false;
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    async cargarArchivo() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        const resp = await this.$store.dispatch(
          "bajas/cargarArchivo",
          formData
        );
        if (resp === "error") {
          this.error = true;
          this.loader.hide();
          return;
        }
        this.displayModal = false;
        this.loader.hide();
        this.titulo = "Cargar archivo";
        this.mensajeProceso = ["Se ha cargado el archivo con éxito"];
        document.getElementById("modalButton").click();
        this.consultar();
      } catch (error) {
        this.error = true;
        this.loader.hide();
      } finally {
        //this.loader.hide();
      }
    },
    updatePage(page){
      this.pagina = page;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    bajas() {
      return this.$store.getters["bajas/bajas"]
        ? this.$store.getters["bajas/bajas"]
        : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
